import {
    type AutomationRootNode,
    type ConditionalActionNode,
    type FlowActionNode,
    type PrimitiveActionNode,
    ActionNodeKind,
} from "@glide/app-description";
import { makeNativeTableRowIDColumn } from "@glide/common-core/dist/js/table-columns";
import {
    type SchemaInspector,
    type ColumnType,
    type TableColumn,
    type TableGlideType,
    isBigTableOrExternal,
    makeArrayType,
    getTableName,
    makePrimitiveType,
    decomposeRelationType,
    isSingleRelationType,
    getTableColumn,
    makeTableColumn,
} from "@glide/type-schema";
import type { ActionNodeInScope, AppDescriptionContext } from "@glide/function-utils";
import { handlerForActionKind } from "@glide/generator/dist/js/actions";
import { Result } from "@glide/plugins";
import { assert, assertNever } from "@glideapps/ts-necessities";
import { getHandlerForLoop } from "./loops";
import { makeActionOutputColumnName, makeInternalTableName, makeLoopScopeID } from "./names";
import type { PreprocessedAction } from "@glide/generator/dist/js/actions/automation-types";
import type { TypeForActionNodeOutputGetter } from "@glide/generator/dist/js/static-context";
import { makePriorStepsFromPreviousNodeKeys } from "./prior-steps";
import { isActionNodeEnabled } from "./is-node-enabled";
import type { LoadedGroundValue } from "@glide/computation-model-types";
import { isRow, isTable } from "@glide/common-core/dist/js/computation-model/data";
import { makeFilterReferenceFormulaUnsafe, SyntheticColumnKind } from "@glide/formula-specifications";
import { getIDColumnForTable, getIDForRow } from "./row-id";
import type { MakeUpdatesFromOutputs, StaticScope } from "@glide/wire";

const rootScopeID = "root";

function makeInternalTableType(scopeID: string, columns: readonly TableColumn[]): TableGlideType {
    const rowIDColumn = makeNativeTableRowIDColumn(false);
    assert(getTableColumn(columns, rowIDColumn.name) === undefined);

    return {
        name: makeInternalTableName(scopeID),
        columns: [rowIDColumn, ...columns],
        rowIDColumn: rowIDColumn.name,
        emailOwnersColumn: undefined,
    };
}

export function makeTypeForActionNodeOutputGetterFromNodeForKey(
    schema: SchemaInspector,
    nodeForKey: ReadonlyMap<string, ActionNodeInScope>
): TypeForActionNodeOutputGetter {
    return (nodeKey: string, outputName: string, columnInRow: string | undefined): ColumnType | string => {
        const node = nodeForKey.get(nodeKey);
        if (node === undefined) return "The computation refers to a step that doesn't exist";

        const output = node.outputs.find(o => o.name === outputName);
        if (output === undefined) return `The computation refers to the output "${outputName} which doesn't exist`;

        let type = output.type;

        if (columnInRow !== undefined) {
            if (!isSingleRelationType(type)) return "The step's output is not a row";
            const table = schema.findTable(type);
            if (table === undefined) return "The table for the output of the step doesn't exist";

            const column = getTableColumn(table, columnInRow);
            if (column === undefined) return "The computation refers to a column that doesn't exist";
            type = column.type;
        }

        return type;
    };
}

// Exported only for testing
export function makeColumnsForNodeOutputs(
    schema: SchemaInspector,
    node: ActionNodeInScope
): Result<[readonly TableColumn[], MakeUpdatesFromOutputs]> {
    // This creates the table columns in the internal rows for storing the
    // outputs of nodes.  For outputs with types that we can easily persist
    // this is a trivial affair: each output gets a column of that type.
    // Unfortunately we can't easily persist relation values, because they're
    // really pointers to rows in other tables, and weird things (*) would
    // happen if we persisted those as plain objects.  What we do instead is
    // create two columns: a persisted one, which holds the row ID(s) of the
    // target rows, and a computed relation column which will produce the
    // output for the node, i.e. the relation.
    //
    // In addition to the columns, this also creates a
    // `MakeUpdatesFromOutputs` function which translates the outputs to the
    // updates to the persisted columns.  The outputs can contain rows and
    // tables, but this function will transform those into strings and arrays
    // of strings, on which the relation column will operate.
    //
    // (*) The weird things would be a consequence of that row being loaded
    // back as an independent object, vs as the row in the table it belongs
    // to.  In particular it wouldn't get any computed columns, because the
    // computation model wouldn't know about it.  Similarly, it could have
    // data that's different from the "real" row, because it wouldn't be
    // updated.

    const key = node.node.key;

    const tableColumns: TableColumn[] = [];
    const partialMakeUpdates: MakeUpdatesFromOutputs[] = [];

    for (const output of node.outputs) {
        const columnName = makeActionOutputColumnName(key, output.name);

        const relationType = decomposeRelationType(output.type);
        if (relationType !== undefined) {
            const targetTable = schema.findTable(relationType.tableRef);
            if (targetTable === undefined) {
                return Result.FailPermanent(`Relation table in output ${output.name} for node ${key} not found`);
            }
            const idColumn = getIDColumnForTable(targetTable);
            if (!idColumn.ok) return idColumn;

            const relationSourceColumnName = `relation-source-${columnName}`;
            const relationFormula = makeFilterReferenceFormulaUnsafe(
                {
                    kind: SyntheticColumnKind.FilterReference,
                    hostColumn: relationSourceColumnName,
                    targetTable: getTableName(targetTable),
                    targetColumn: idColumn.result.name,
                    multiple: relationType.isMulti,
                    omitSort: true,
                },
                true,
                true
            );

            if (!relationType.isMulti) {
                tableColumns.push(
                    makeTableColumn(relationSourceColumnName, "string"),
                    makeTableColumn(columnName, output.type, {
                        formula: relationFormula,
                    })
                );

                partialMakeUpdates.push(o => {
                    const v = o[output.name];
                    if (v === undefined) return Result.Ok({});
                    if (!isRow(v)) {
                        return Result.FailPermanent(
                            `Expected a row in output ${output.name} for node ${key} but got ${v.constructor.name}`
                        );
                    }
                    const id = getIDForRow(v, idColumn.result);
                    if (!id.ok) return id;

                    return Result.Ok({ [relationSourceColumnName]: id.result });
                });

                continue;
            } else if (!isBigTableOrExternal(targetTable)) {
                // We only special-case multi-relations to non-queryable
                // tables, because in queryable tables they manifest as
                // queries, which we serialize.
                tableColumns.push(
                    makeTableColumn(relationSourceColumnName, makeArrayType(makePrimitiveType("string"))),
                    makeTableColumn(columnName, output.type, {
                        formula: relationFormula,
                    })
                );

                partialMakeUpdates.push(o => {
                    const v = o[output.name];
                    if (v === undefined) return Result.Ok({});
                    if (!isTable(v)) {
                        return Result.FailPermanent(
                            `Expected a table in output ${output.name} for node ${key} but got ${v.constructor.name}`
                        );
                    }
                    const ids: string[] = [];
                    for (const row of v.values()) {
                        const id = getIDForRow(row, idColumn.result);
                        if (!id.ok) return id;
                        ids.push(id.result);
                    }
                    return Result.Ok({ [relationSourceColumnName]: ids });
                });

                continue;
            }

            // If neither of the above are true, then we call through here.
        }

        // This is the base case, for values that serialize without any
        // additional ceremony.
        const column = makeTableColumn(columnName, output.type, {
            displayName: output.displayName,
            displayFormula: output.displayFormula,
        });
        tableColumns.push(column);

        partialMakeUpdates.push(o => Result.Ok({ [columnName]: o[output.name] }));
    }

    const makeUpdates: MakeUpdatesFromOutputs = o => {
        const results: Record<string, LoadedGroundValue> = {};
        for (const partialMakeUpdate of partialMakeUpdates) {
            const r = partialMakeUpdate(o);
            if (!r.ok) return r;
            Object.assign(results, r.result);
        }
        return Result.Ok(results);
    };

    return Result.Ok([tableColumns, makeUpdates]);
}

type ColumnsFromOutputs = [
    columns: readonly TableColumn[],
    makeUpdatesForPrimitiveNodeKey: ReadonlyMap<string, MakeUpdatesFromOutputs>
];

/**
 * This class is just a nice container for the state.  It can only preprocess
 * once.
 */
class Preprocessor {
    private readonly rootActionNodeInScope: ActionNodeInScope;

    private readonly nodeForKey: Map<string, ActionNodeInScope>;
    private readonly staticScopes: StaticScope[] = [];

    constructor(
        private readonly rootNode: AutomationRootNode,
        private readonly adc: AppDescriptionContext,
        private readonly useDefaultOnSourceError: boolean
    ) {
        this.rootActionNodeInScope = {
            node: rootNode,
            scopeID: rootScopeID,
            outputs: [],
            previousNodeKeys: [],
        };

        this.nodeForKey = new Map([[rootNode.key, this.rootActionNodeInScope]]);
    }

    private makeInternalColumnsForScope(localRootNode: ActionNodeInScope): Result<ColumnsFromOutputs> {
        const tableColumns: TableColumn[] = [];
        const makeUpdatesForPrimitiveNodeKey = new Map<string, MakeUpdatesFromOutputs>();

        // We're gathering the internal columns for the outputs of all the actions
        // in the scope that we just processed.  This is a bit roundabout because
        // we have to iterate over all the nodes, vs returning the relevant nodes
        // from `processFlow`, but meh.
        for (const node of this.nodeForKey.values()) {
            // We don't want to re-process the `actionNodeInScope` we just added,
            // because that would re-add the node outputs as regular columns, vs
            // whatever `makeInternalTableColumns` produced.
            if (node.scopeID !== localRootNode.scopeID || node === localRootNode) continue;

            const makeColumnsResult = makeColumnsForNodeOutputs(this.adc, node);
            if (!makeColumnsResult.ok) return makeColumnsResult;
            const [newColumns, makeUpdates] = makeColumnsResult.result;

            tableColumns.push(...newColumns);
            makeUpdatesForPrimitiveNodeKey.set(node.node.key, makeUpdates);
        }

        return Result.Ok([tableColumns, makeUpdatesForPrimitiveNodeKey]);
    }

    // Returns whether the node is enabled
    private processConditional(
        node: ConditionalActionNode,
        currentScopeID: string,
        previousNodeKeys: readonly string[]
    ): Result<boolean> {
        if (!isActionNodeEnabled(node)) return Result.Ok(false);

        this.nodeForKey.set(node.key, {
            node,
            scopeID: currentScopeID,
            outputs: [],
            previousNodeKeys: Array.from(previousNodeKeys),
        });

        for (const flow of node.conditionalFlows) {
            this.nodeForKey.set(flow.key, {
                node: flow,
                scopeID: currentScopeID,
                outputs: [],
                previousNodeKeys: [...previousNodeKeys, node.key],
            });
            const r = this.processFlow(flow.flow, currentScopeID, [...previousNodeKeys, node.key, flow.key]);
            if (!r.ok) return r;
        }
        if (node.elseFlow !== undefined) {
            const r = this.processFlow(node.elseFlow, currentScopeID, [...previousNodeKeys, node.key]);
            if (!r.ok) return r;
        }
        return Result.Ok(true);
    }

    // Returns whether the node is enabled
    private processFlow(
        flow: FlowActionNode,
        currentScopeID: string,
        previousNodeKeys: readonly string[]
    ): Result<boolean> {
        if (!isActionNodeEnabled(flow)) return Result.Ok(false);

        this.nodeForKey.set(flow.key, {
            node: flow,
            scopeID: currentScopeID,
            outputs: [],
            // We need to copy the array because the call might be mutating
            // it.
            previousNodeKeys: Array.from(previousNodeKeys),
        });

        const nodeKeys = [...previousNodeKeys, flow.key];

        for (const action of flow.actions) {
            if (action.kind === ActionNodeKind.Conditional) {
                const r = this.processConditional(action, currentScopeID, nodeKeys);
                if (!r.ok) return r;
                if (!r.result) continue;
            } else if (action.kind === ActionNodeKind.Primitive) {
                const r = this.processPrimitive(action, currentScopeID, nodeKeys);
                if (!r.ok) return r;
                if (!r.result) continue;
                // In a flow, we only add primitive nodes to the ongoing
                // "previous node keys", and not conditional or loop nodes.
                // Right now condition nodes don't produce any values, and
                // loop nodes produce values that should only be visible
                // inside the loop.
                nodeKeys.push(action.key);
            } else if (action.kind === ActionNodeKind.Loop) {
                const scopeID = makeLoopScopeID(action.key);
                const handler = getHandlerForLoop(action, this.useDefaultOnSourceError);

                const currentActionNodesInScope = Array.from(this.nodeForKey.values());
                const outputs = handler.getActionOutputs(this.adc, currentActionNodesInScope);
                if (!outputs.ok) return outputs;

                const actionNodeScope: ActionNodeInScope = {
                    node: action,
                    scopeID,
                    outputs: outputs.result,
                    // We need to copy the array because the loop is mutating
                    // it.
                    previousNodeKeys: Array.from(nodeKeys),
                };
                // We need add the `nodeForKey` first because the flow needs
                // to know its output types.
                this.nodeForKey.set(action.key, actionNodeScope);

                const r = this.processFlow(action.flow, scopeID, [...nodeKeys, action.key]);
                if (!r.ok) return r;
                if (!r.result) continue;

                const maybeInternalTableColumns = handler.makeInternalTableColumns(this.adc, currentActionNodesInScope);
                if (!maybeInternalTableColumns.ok) return maybeInternalTableColumns;

                const internalTableColumns = Array.from(maybeInternalTableColumns.result);

                const bodyTableColumnsResult = this.makeInternalColumnsForScope(actionNodeScope);
                if (!bodyTableColumnsResult.ok) return bodyTableColumnsResult;
                const [bodyTableColumns, makeUpdatesForPrimitiveNodeKey] = bodyTableColumnsResult.result;

                internalTableColumns.push(...bodyTableColumns);

                const staticScope: StaticScope = {
                    scopeID,
                    internalTableType: makeInternalTableType(scopeID, internalTableColumns),
                    makeUpdatesForPrimitiveNodeKey,
                };
                this.staticScopes.push(staticScope);
            } else {
                return assertNever(action);
            }
        }
        return Result.Ok(true);
    }

    // Returns whether the node is enabled
    private processPrimitive(
        action: PrimitiveActionNode,
        currentScopeID: string,
        previousNodeKeys: readonly string[]
    ): Result<boolean> {
        if (!isActionNodeEnabled(action)) return Result.Ok(false);

        const handler = handlerForActionKind(action.actionDescription.kind);

        // We need to copy this array because it will be mutated later.
        previousNodeKeys = Array.from(previousNodeKeys);

        const descr = handler.getDescriptor(action.actionDescription, {
            context: this.adc,
            tables: undefined,
            mutatingScreenKind: undefined,
            isAutomation: true,
            priorSteps: makePriorStepsFromPreviousNodeKeys(this.nodeForKey, this.adc, previousNodeKeys),
        });
        if (descr === undefined) {
            return Result.FailPermanent(
                `Could not get descriptor for action node ${action.key} kind ${action.actionDescription.kind}`
            );
        }

        this.nodeForKey.set(action.key, {
            node: action,
            scopeID: currentScopeID,
            outputs: descr.outputs ?? [],
            previousNodeKeys,
        });

        return Result.Ok(true);
    }

    public preprocess(): Result<PreprocessedAction> {
        const { rootActionNodeInScope, rootNode } = this;

        // This should never happen (unless at some point we allow the user to do
        // that, in which case we should treat it not as an error).
        if (!isActionNodeEnabled(rootNode)) return Result.FailPermanent("Root node is disabled");

        // We need to record the root node so that its inputs/outputs are
        // known.
        this.nodeForKey.set(rootNode.key, {
            node: rootNode,
            scopeID: rootActionNodeInScope.scopeID,
            outputs: rootNode.inputs,
            previousNodeKeys: [],
        });
        const r = this.processFlow(rootNode.flow, rootActionNodeInScope.scopeID, [rootNode.key]);
        if (!r.ok) return r;

        const internalColumnsResult = this.makeInternalColumnsForScope(rootActionNodeInScope);
        if (!internalColumnsResult.ok) return internalColumnsResult;
        const [internalTableColumns, makeUpdatesForPrimitiveNodeKey] = internalColumnsResult.result;

        const rootStaticScope: StaticScope = {
            scopeID: rootActionNodeInScope.scopeID,
            internalTableType: makeInternalTableType(rootActionNodeInScope.scopeID, internalTableColumns),
            makeUpdatesForPrimitiveNodeKey,
        };
        this.staticScopes.unshift(rootStaticScope);

        return Result.Ok({ nodeForKey: this.nodeForKey, staticScopes: this.staticScopes });
    }
}

/**
 * Preprocesses the given automation action.
 *
 * @param {AutomationRootNode} rootNode - The root node of the automation.
 * @param {AppDescriptionContext} adc - The application description context.
 * @param {boolean} [useDefaultOnSourceError=false] - If true, loop handlers will default to empty outputs
 *   instead of returning an error when their source is missing. This is used to allow the UI to display
 *   errors properly without prematurely failing the automation.
 * @returns {Result<PreprocessedAction>} The result of preprocessing the action.
 */
export function preprocessAction(
    rootNode: AutomationRootNode,
    adc: AppDescriptionContext,
    useDefaultOnSourceError = false
): Result<PreprocessedAction> {
    const preprocessor = new Preprocessor(rootNode, adc, useDefaultOnSourceError);
    return preprocessor.preprocess();
}
