import type { DataPlotRowData, WireChartData } from "@glide/fluent-components/dist/js/base-components";
import { CHART_X_AXIS_DATA_KEY, getChartDisplayDataKey } from "@glide/fluent-components/dist/js/base-components";
import { isUndefinedish } from "@glide/support";

export interface TooltipPayload {
    value: number;
    color: string;
    name: string;
    payload: WireChartData & { points: DataPlotRowData["points"] };
    dataKey: string;
}

export const parseDataKey = (dataKey: string | undefined): string | undefined => {
    if (dataKey === undefined) {
        return undefined;
    }
    const parts = dataKey.split(".");
    return parts.length >= 2 ? parts[1] : parts[0];
};

interface CustomTooltipProps {
    active?: boolean;
    payload?: TooltipPayload[] | null; // this can happen in unknown situations.
    label?: string | number;
    getCaptionFromDataKey: (dataKey: string) => string;
    valueGetter?: (payload: TooltipPayload) => number | string | undefined;
    getColor?: (i: number, value?: number, columnKey?: string) => string;
}

export const CustomTooltip: React.VFC<CustomTooltipProps> = p => {
    const { active, payload, label, getCaptionFromDataKey, valueGetter, getColor } = p;

    if (active === undefined || isUndefinedish(payload) || label === undefined) {
        return null;
    }

    const xLabel = `${payload[0]?.payload[CHART_X_AXIS_DATA_KEY] ?? label}`;

    return (
        <div tw="[min-width:140px] bg-bg-middle rounded-md px-2 pt-1.5 pb-2 ring-1 ring-border-xpale shadow-md-soft">
            <div tw="text-sm text-n900 font-medium mb-1.5">{xLabel}</div>

            {payload.map((x, i) => {
                const dk = parseDataKey(x.dataKey);
                return (
                    <div key={`${x.value}-${i}`} tw="flex gap-1 justify-between items-baseline w-full text-xs">
                        <div tw="flex gap-x-1 items-baseline">
                            <div
                                tw="w-3 h-3 rounded translate-y-[1.2px]"
                                aria-hidden
                                style={{
                                    backgroundColor: getColor?.(i, x.value, dk) ?? x.color,
                                }}
                            />
                            <div tw="text-xs font-medium truncate max-w-[130px] text-n600A">
                                {getCaptionFromDataKey(x.dataKey)}
                            </div>
                        </div>
                        <div tw="ml-auto text-xs font-medium text-n900">
                            {`${valueGetter ? valueGetter(x) : x.payload[getChartDisplayDataKey(x.name)]}`}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
