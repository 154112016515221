import type { SourceColumn, TableColumn, TableGlideType, SpecialValueDescription } from "@glide/type-schema";
import { isComputedColumn, makeSourceColumn, makeTableColumn } from "@glide/type-schema";
import { assert, assertNever, defined } from "@glideapps/ts-necessities";
import type { ColumnOrValueSpecification } from "./formula-specifications";
import {
    SyntheticColumnKind,
    ColumnOrValueKind,
    decomposeTextTemplateFormula,
    makeTextTemplateFormula,
} from "./formula-specifications";
import type { InlineComputation } from "@glide/app-description";
import { checkString, isDefined, tokenizeTemplateString } from "@glide/support";
import type { BasePrimitiveValue } from "@glide/data-types";

interface InlineTemplateTextNode {
    kind: "text";
    value: string;
}

interface InlineTemplateColumnNode {
    kind: "column";
    value: SourceColumn;
}

interface InlineTemplateSpecialValueNode {
    kind: "specialValue";
    value: SpecialValueDescription;
}

export type InlineTemplateNode = InlineTemplateTextNode | InlineTemplateColumnNode | InlineTemplateSpecialValueNode;

export interface InlineTemplateSpec {
    nodes: InlineTemplateNode[];
}

// ##inlineTemplatesAsComputations
// Representing inline templates as inline computations
//
// We can't just represent the template trivially as:
//
// template: `I'm @Name and I am @Age years old.`
// replacements: @Name -> Name column
//                @Age -> Age column
//
// Because we could clash users' text with our internal representation.
//
// Instead we'll represent them as
// template: `|0||1||2||3||4|`
// replacements: |0| -> "I'm "
//               |1| -> Name column
//               |2| -> " and I am "
//               |3| -> Age column
//               |4| -> " years old."

export function makeInlineTemplateSpecFromInlineComputation(computation: InlineComputation): InlineTemplateSpec {
    const allColumns = computation.computationTable.columns;
    const templateColumn = defined(allColumns[allColumns.length - 1]);

    assert(isComputedColumn(templateColumn));
    const templateSpec = decomposeTextTemplateFormula(templateColumn.formula);
    assert(templateSpec?.template.kind === ColumnOrValueKind.Constant);

    const rawTemplate = templateSpec.template.value;

    const tokens = tokenizeTemplateString(
        rawTemplate,
        templateSpec.params.map(([pattern]) => pattern)
    );

    const nodes: InlineTemplateNode[] = [];

    for (const token of tokens) {
        assert(!token.isText);

        const tokenValue = token.value; // |0| or |1| or ...

        const param = defined(templateSpec.params.find(([t]) => t === tokenValue))[1];
        const { kind } = param;
        assert(
            kind === ColumnOrValueKind.Column ||
                kind === ColumnOrValueKind.Constant ||
                kind === ColumnOrValueKind.SpecialValue
        );

        switch (kind) {
            case ColumnOrValueKind.Constant: {
                const node: InlineTemplateTextNode = {
                    kind: "text",
                    value: checkString(param.value),
                };

                nodes.push(node);
                break;
            }

            case ColumnOrValueKind.SpecialValue: {
                const node: InlineTemplateSpecialValueNode = {
                    kind: "specialValue",
                    value: param.specialValue,
                };

                nodes.push(node);
                break;
            }

            case ColumnOrValueKind.Column: {
                const binding = computation.bindings.find(([columnName]) => param.column.name === columnName);
                assert(isDefined(binding));
                const sourceColumn = binding[1];

                const node: InlineTemplateColumnNode = {
                    kind: "column",
                    value: sourceColumn,
                };

                nodes.push(node);
                break;
            }

            default:
                assertNever(kind);
        }
    }

    return { nodes };
}

export function makeInlineComputationFromInlineTemplateSpec(template: InlineTemplateSpec): InlineComputation {
    const columns: TableColumn[] = [];
    const bindings: [columnName: string, sourceColumn: SourceColumn][] = [];
    const params: [string, ColumnOrValueSpecification<BasePrimitiveValue>][] = [];
    const templateParts: string[] = []; // |0|, |1|, etc

    for (let i = 0; i < template.nodes.length; i++) {
        const node = template.nodes[i];
        const nodeIdentifier = `|${i}|`;

        switch (node.kind) {
            case "column": {
                // Only strings for now, this is probably not correct?
                columns.push(makeTableColumn(nodeIdentifier, "string"));
                bindings.push([nodeIdentifier, node.value]);

                params.push([
                    nodeIdentifier,
                    {
                        kind: ColumnOrValueKind.Column,
                        column: makeSourceColumn(nodeIdentifier),
                    },
                ]);
                break;
            }

            case "text": {
                params.push([
                    nodeIdentifier,
                    {
                        kind: ColumnOrValueKind.Constant,
                        value: node.value,
                    },
                ]);
                break;
            }

            case "specialValue": {
                params.push([
                    nodeIdentifier,
                    {
                        kind: ColumnOrValueKind.SpecialValue,
                        specialValue: node.value,
                    },
                ]);
                break;
            }
        }

        templateParts.push(nodeIdentifier);
    }

    columns.push(
        makeTableColumn("result", "string", {
            formula: makeTextTemplateFormula({
                kind: SyntheticColumnKind.TextTemplate,
                template: {
                    kind: ColumnOrValueKind.Constant,
                    value: templateParts.join(""),
                },
                params,
            }),
        })
    );

    const computationTable: TableGlideType = {
        name: "inline-template-table",
        emailOwnersColumn: undefined,
        columns,
    };

    const computation: InlineComputation = {
        computationTable,
        bindings,
    };

    return computation;
}
