import type { CalendarListMode } from "@glide/common-core/dist/js/components/calendar-list-mode";
import type { CalendarListOrder } from "@glide/common-core/dist/js/components/calendar-list-order";
import type { ConfirmModalStyle } from "@glide/common-core/dist/js/components/confirm-modal";
import type {
    Corners,
    ImageAspectRatio,
    ImageFitStyle,
    ImageGravity,
    OverlayTextAlignment,
    Sizing,
    TextPosition,
    TilesPadding,
} from "@glide/common-core/dist/js/components/image-types";
import { getLocalizedString, makeLocalizedNumberOfItems } from "@glide/localization";
import type { MenuItemStyle } from "@glide/common-core/dist/js/components/menu-item-style";
import type { PastAndOrFuture } from "@glide/common-core/dist/js/components/past-future";
import type { SeparatorSpacing } from "@glide/common-core/dist/js/components/separator-spacing";
import {
    type IconImage,
    type ArrayScreenFormat,
    type PropertyDescription,
    getSourceColumnProperty,
} from "@glide/app-description";
import { getSourceMetadataForTable } from "@glide/common-core/dist/js/components/SerializedApp";
import type {
    TextBoxJustify,
    TextBoxLineLimit,
    TextBoxStyle,
} from "@glide/common-core/dist/js/components/text-box-types";
import type { TitleAppearance } from "@glide/common-core/dist/js/components/title-appearance";
import type { BasicUserProfile, DisplayContext, MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import type { PaymentInformation } from "@glide/common-core/dist/js/Database";
import {
    type TableColumn,
    type TableGlideType,
    SourceColumnKind,
    getNonHiddenColumns,
    getPrimitiveColumns,
    getSourceColumnPath,
    getTableColumn,
    getTablesWithPrimitiveColumns,
    isColumnWritable,
    isComputedColumn,
    type Description,
    type SourceColumn,
    type SchemaInspector,
    isBigTableOrExternal,
} from "@glide/type-schema";
import type { ContainerAlignment, ContainerLayout } from "@glide/common-core/dist/js/description";
import type {
    Appearance,
    BarChartData,
    BuyScreenTransactionItem,
    CardFloatStyle,
    ChartDatumWithTitle,
    ChoiceStyle,
    CodeScannerStandards,
    CommentData,
    ImagePickerSourceHint,
    ListItemAccessoryPosition,
    ListItemFlags,
    MapListMode,
    MapVisualType,
    Mood,
    ProgressType,
    SimpleTableRowStyle,
    TextEntrySize,
    TextSize,
    TextStyle,
    SuperTableBooleanCell,
    SuperTableButtonCell,
    SuperTableColumn,
    SuperTableImageCell,
    SuperTableLinkCell,
    SuperTableTextCell,
    NewDataGridNumberCell,
    SuperTableRowHeaderCell,
    SuperTableExtraActionsCell,
    SuperTableChoiceCell,
    SuperTableTagsCell,
    NewDataGridImageCell,
    NewDataGridLinkCell,
    NewDataGridColumn,
    NewDataGridTextCell,
    NewDataGridBooleanCell,
    NewDataGridButtonCell,
    NewDataGridExtraActionsCell,
    NewDataGridBlankCell,
    NewDataGridRowHeaderCell,
    NewDataGridChoiceCell,
    NewDataGridTagsCell,
    TagOrChoiceItem,
} from "@glide/component-utils";
import type { GlideDateTime } from "@glide/data-types";
import type { AppKind } from "@glide/location-common";
import { replaceArrayItem, type MapImageSource } from "@glide/support";
import type { Unbound, QuerySort } from "@glide/computation-model-types";
import type {
    WireAlwaysEditableValue,
    WireMultipleDynamicFilters,
    WireComponentKind,
    BarChartType,
    BarChartWeights,
    ChartType as PagesChartType,
    RadialChartWeights,
    UIBackgroundStyle,
    UIButtonAppearance,
    UISize,
    UITitleStyle,
    WireAction,
    WireComponent,
    WireEditableValue,
    WirePagedGroup,
    DataPlotType,
} from "@glide/wire";
import { assert, hasOwnProperty } from "@glideapps/ts-necessities";
import isNumber from "lodash/isNumber";
import type { WireListComponentGeneric } from "./fluent-components-spec";
import type { InputOutputTables } from "@glide/common-core";
import { resolveSourceColumn } from "@glide/function-utils";
import { decomposeFilterReferenceFormula } from "@glide/formula-specifications";
import type {
    ChartingColorScheme,
    ChartingGradientMapping,
    DataPlotRollupKind,
    TimeSeriesRelativeDuration,
} from "./fluent-components";

export interface WireFieldComponentBase extends WireComponent {
    readonly placeholder: string;
    readonly title: string;
    readonly isRequired: boolean;
}

export interface WireTextFieldComponent extends WireFieldComponentBase {
    readonly kind: WireComponentKind.TextField;
    readonly value: WireEditableValue<string>;
    readonly minChars?: number | null;
    readonly maxChars?: number | null;
    readonly size?: TextEntrySize;
    readonly autofocus?: boolean;
    readonly voiceTranscription?: boolean;
}

export interface WireNumberFieldComponent extends WireFieldComponentBase {
    readonly kind: WireComponentKind.NumberField;
    readonly value: WireEditableValue<number | undefined>;
    readonly minValue?: number | null;
    readonly maxValue?: number | null;
    readonly prefix?: string;
    readonly suffix?: string;
    readonly autofocus?: boolean;
}

export interface WireEmailFieldComponent extends WireFieldComponentBase {
    readonly kind: WireComponentKind.EmailField;
    readonly value: WireEditableValue<string>;
    readonly autofocus?: boolean;
}

export interface WirePhoneFieldComponent extends WireFieldComponentBase {
    readonly kind: WireComponentKind.PhoneField;
    readonly value: WireEditableValue<string>;
}

export interface WireDateTimeFieldComponent extends WireFieldComponentBase {
    readonly kind: WireComponentKind.DateTimeField;
    readonly value: WireEditableValue<GlideDateTime | "" | undefined>;
    readonly pastOrFuture: PastAndOrFuture;
    readonly withTime: boolean;
}

export interface WireMultiPickerValue {
    readonly isMultiple: true;
    readonly value: WireEditableValue<readonly string[]>;
}

export interface WireSinglePickerValue {
    readonly isMultiple: false;
    readonly value: WireEditableValue<string>;
}

export interface WireFilePickerComponentBase extends WireComponent {
    readonly title?: string | null;
    readonly isBusy: WireEditableValue<boolean>;
    readonly isRequired: boolean;
    readonly includeFilename: boolean;
    readonly editableValue: WireMultiPickerValue | WireSinglePickerValue;
}

export interface WireFilePickerComponent extends WireFilePickerComponentBase {
    readonly kind: WireComponentKind.FilePicker;
}

export interface WireImagePickerComponent extends WireFilePickerComponentBase {
    readonly kind: WireComponentKind.ImagePicker;
    readonly sourceHint: ImagePickerSourceHint;
}

export enum WireToggleKind {
    Switch = "switch",
    Checkbox = "checkbox",
    Favorite = "favorite",
}

export interface WireToggleComponent extends WireComponent {
    readonly kind: WireComponentKind.Toggle;
    readonly toggleKind: WireToggleKind;
    readonly title?: string | null;
    readonly description?: string | null;
    readonly value: WireEditableValue<boolean>;
    readonly size?: "default" | "small" | "xsmall";
    // ##onFavoriteToggle:
    // If `onToggle` is set, use that instead of `value` to toggle.  Don't use
    // both!  Pages doesn't currently use this.
    readonly onToggle: WireAction | undefined;
    readonly allowWrapping: boolean;
    readonly isRequired: boolean;
}

export interface WireMultipleFilterComponent extends WireComponent {
    readonly kind: WireComponentKind.MultipleFilters;
    readonly multipleFilters: WireMultipleDynamicFilters;
}

export interface WireActionWithTitle {
    readonly title?: string | Unbound;
    readonly action?: WireAction | Unbound;
    readonly icon?: string;
    // Defaults to left
    readonly iconPlacement?: "left" | "right";
    readonly appearanceOverride?: UIButtonAppearance;
}

type KanbanIndex = string;

export interface WireKanbanTag {
    readonly name: string;
    readonly action?: WireAction;
    readonly isSelected: boolean;
}

export interface WireKanbanItem {
    readonly key: string;
    readonly title: WireEditableValue<string> | Unbound;
    readonly subtitle: WireEditableValue<string> | Unbound;
    readonly image?: string | Unbound;
    readonly tags: readonly WireKanbanTag[] | Unbound;
    readonly onTap: WireAction | undefined;
    readonly onDrag: WireAction | undefined;
    readonly menuActions: readonly WireActionWithTitle[];
    // For setting the index, i.e. moving the item up/down
    readonly indexToken?: string;
    // For setting the category, i.e. moving to a different column
    readonly categoryToken?: string;
    // For inserting before this item
    readonly insertBeforeIndex: KanbanIndex;
    // This will be set for the last added card
    readonly autoFocus: boolean;
    // This will be set if the item was added in this session
    readonly deleteAction: WireAction | undefined;
    // This will be set if the item was just added for inline editing and should be called after editing is complete
    readonly commitAction: WireAction | undefined;
}

export interface WireKanbanColumn {
    readonly title: string;
    // This is for setting an item's column via the `categoryToken`
    readonly category: string;
    readonly items: readonly WireKanbanItem[];
    readonly addItemAction: WireAction | undefined;
}

export interface WireKanbanComponent extends WireListComponentGeneric<ArrayScreenFormat.Kanban> {
    readonly title: string | Unbound;
    readonly columns: readonly WireKanbanColumn[];
    // For inserting at the bottom
    readonly newLastIndex: KanbanIndex;
    readonly titleStyle: UITitleStyle;
    readonly titleImage?: string | Unbound;
    readonly titleActions: readonly WireActionWithTitle[];
}

export interface WireListCalendarCollectionItem {
    readonly title: string | Unbound;
    readonly startDate: WireEditableValue<GlideDateTime>;
    readonly endDate: WireEditableValue<GlideDateTime | undefined> | Unbound;
    readonly action: WireAction | undefined;
    readonly allowEdit: boolean;
}

export interface WireListCalendarCollectionItemGroup {
    readonly title: string;
    readonly items: readonly WireListCalendarCollectionItem[];
}

export type WireListCalendarCollectionViewMode = "month" | "week" | "day";

export interface WireListCalendarCollection extends WireListComponentGeneric<ArrayScreenFormat.CalendarCollection> {
    readonly componentTitle: string | Unbound;
    readonly groups: readonly WireListCalendarCollectionItemGroup[];
    readonly titleStyle: UITitleStyle;
    readonly titleImage?: string | Unbound;
    readonly titleActions: readonly WireActionWithTitle[];
    readonly newItemStart: WireAlwaysEditableValue<GlideDateTime | undefined> | undefined;
    readonly newItemEnd: WireAlwaysEditableValue<GlideDateTime | undefined> | undefined;
    readonly newItemTitle: WireAlwaysEditableValue<string | undefined> | undefined;
    readonly submitNewItem: WireAction | undefined;
    readonly defaultGroup: string;
    readonly viewDate: WireAlwaysEditableValue<GlideDateTime>;
    readonly viewMode: WireAlwaysEditableValue<WireListCalendarCollectionViewMode>;
    readonly allowAdd: boolean;
}

export type WireDataGridColumnKind = "string" | "number" | "boolean" | "image-uri";

export type WireDataGridCellValue = string | number | boolean;

export interface WireDataGridColumn {
    readonly title: string;
    readonly kind: WireDataGridColumnKind;
    readonly isEditable: boolean;
}

export interface WireDataGridRow {
    readonly cells: readonly WireEditableValue<WireDataGridCellValue>[];
    readonly deleteAction: WireAction | undefined;
}

export interface WireDataGridListComponent extends WireListComponentGeneric<ArrayScreenFormat.DataGrid> {
    readonly columns: readonly WireDataGridColumn[];

    readonly rows: readonly WireDataGridRow[];
    readonly rowMarkers: boolean;

    readonly title: string | Unbound;
    readonly titleActions: readonly WireActionWithTitle[];
    readonly titleStyle: UITitleStyle;
    readonly titleImage?: string | Unbound;
}

export interface WireNewDataGridListComponent extends WireListComponentGeneric<ArrayScreenFormat.NewDataGrid> {
    readonly columns: readonly WireNewDataGridColumn[];

    readonly rows: readonly WireNewDataGridRow[];

    readonly title: string | Unbound;
    readonly titleActions: readonly WireActionWithTitle[];
    readonly titleImage?: string | Unbound;
    readonly hasNoMoreRows: boolean;
    readonly viewWindow: WireAlwaysEditableValue<SuperTableViewWindow>;
}

/**
 * Glide types that replace callbacks with editable values and actions
 */

export interface WireSuperTableBooleanCell extends Omit<SuperTableBooleanCell, "value" | "onValueChange"> {
    readonly editableValue: WireEditableValue<boolean>;
}

export interface WireSuperTableButtonCell extends Omit<SuperTableButtonCell, "onClick"> {
    readonly action: WireAction | undefined;
}

export interface WireSuperTableRowHeaderCell extends Omit<SuperTableRowHeaderCell, "onClick"> {
    readonly action: WireAction | undefined;
}

export interface WireSuperTableExtraActionsCell extends Omit<SuperTableExtraActionsCell, "onClick"> {
    readonly actions: readonly WireActionWithTitle[];
}

export interface WireTagOrChoiceItem extends Omit<TagOrChoiceItem, "onSelect"> {
    readonly onSelect: WireAction | undefined;
}

export interface WireSuperTableChoiceCell extends Omit<SuperTableChoiceCell, "value" | "options"> {
    readonly value: string[];
    readonly options: WireTagOrChoiceItem[];
}

export interface WireSuperTableTagsCell extends Omit<SuperTableTagsCell, "value" | "options"> {
    readonly value: string[];
    readonly options: WireTagOrChoiceItem[];
    readonly tagColorKind: "Auto" | "Manual";
}

export type WireSuperTableCell =
    | WireSuperTableBooleanCell
    | WireSuperTableButtonCell
    | WireSuperTableRowHeaderCell
    | WireSuperTableExtraActionsCell
    | WireSuperTableChoiceCell
    | WireSuperTableTagsCell
    // The following don't need Wire translation
    | SuperTableTextCell
    | SuperTableImageCell
    | SuperTableLinkCell;

export type WireSuperTableRow = WireSuperTableCell[];

// For the new data grid
export interface WireNewDataGridTextCell extends Omit<NewDataGridTextCell, "value" | "onValueChange" | "displayValue"> {
    readonly editableValue: WireEditableValue<string>;
}

export interface WireNewDataGridNumberCell
    extends Omit<NewDataGridNumberCell, "value" | "onValueChange" | "displayValue"> {
    readonly editableValue: WireEditableValue<number>;
}

export interface WireNewDataGridBooleanCell extends Omit<NewDataGridBooleanCell, "value" | "onValueChange"> {
    readonly editableValue: WireEditableValue<boolean>;
}

export interface WireNewDataGridButtonCell extends Omit<NewDataGridButtonCell, "onClick"> {
    readonly action: WireAction | undefined;
}

export interface WireNewDataGridRowHeaderCell
    extends Omit<NewDataGridRowHeaderCell, "onClick" | "value" | "onValueChange" | "displayValue"> {
    readonly action: WireAction | undefined;
    readonly editableValue: WireEditableValue<string>;
}

export interface WireNewDataGridExtraActionsCell extends Omit<NewDataGridExtraActionsCell, "onClick"> {
    readonly actions: readonly WireActionWithTitle[];
}

export interface WireNewDataGridChoiceCell extends Omit<NewDataGridChoiceCell, "value" | "options"> {
    readonly value: string[];
    readonly options: WireTagOrChoiceItem[];
}

export interface WireNewDataGridTagsCell extends Omit<NewDataGridTagsCell, "value" | "options"> {
    readonly value: string[];
    readonly options: WireTagOrChoiceItem[];
    readonly tagColorKind: "Auto" | "Manual";
}

export type WireNewDataGridCell =
    | WireNewDataGridTextCell
    | WireNewDataGridNumberCell
    | WireNewDataGridBooleanCell
    | WireNewDataGridButtonCell
    | WireNewDataGridRowHeaderCell
    | WireNewDataGridExtraActionsCell
    | WireNewDataGridChoiceCell
    | WireNewDataGridTagsCell
    // The following don't need Wire translation
    | NewDataGridImageCell
    | NewDataGridLinkCell
    | NewDataGridBlankCell;

export type WireNewDataGridRow = {
    cells: WireNewDataGridCell[];
    deleteAction: WireAction | undefined;
};

export interface WireNewDataGridColumn extends Omit<NewDataGridColumn, "headerAction" | "choiceOptions"> {
    readonly headerAction: WireAction | undefined;
}

// TODO: Remove all of this if we don't get back to a GDG based implementation
export interface SuperTableViewWindow {
    readonly start: number;
    readonly end: number;
    // This is hacky. I want to keep track of what's the max index requested so I can pass the right limit to the query
    // Maybe I can add this in preHydrate and run it as a followUp?
    // but the current impl doesn't even have that step, so this is it for now.
    readonly maxRequested: number;
}

// I don't want to deal with object identity
export function getSuperTableViewWindowKey(start: number, end: number): string {
    return `${start}-${end}`;
}

export function isSuperTableViewWindow(v: unknown): v is SuperTableViewWindow {
    const hasValidStart = hasOwnProperty(v, "start") && isNumber(v.start);
    const hasValidEnd = hasOwnProperty(v, "end") && isNumber(v.end);
    const hasValidMaxRequested = hasOwnProperty(v, "maxRequested") && isNumber(v.maxRequested);

    return hasValidStart && hasValidEnd && hasValidMaxRequested;
}

export interface WireSuperTableColumn extends Omit<SuperTableColumn, "headerAction" | "choiceOptions"> {
    readonly headerAction: WireAction | undefined;
}

export interface WireSuperTableListComponent extends WireListComponentGeneric<ArrayScreenFormat.SuperTable> {
    readonly title: string | Unbound;
    readonly columns: WireSuperTableColumn[];
    readonly groups: WirePagedGroup<WireSuperTableRow>[];
    readonly titleActions: readonly WireActionWithTitle[];
    readonly activeSort: QuerySort | undefined;
    readonly style: "minimal" | "striped";
}

export interface WireCommentItem {
    readonly comment: string | Unbound;
    readonly timestamp: GlideDateTime | undefined | Unbound;
    readonly userName: string | Unbound;
    readonly userPhoto: string | Unbound;
    readonly userEmail: string | Unbound;
    readonly itemActions: readonly WireActionWithTitle[];
    readonly isSelfComment: boolean;
}

export enum CommentsStyle {
    Comments = "Comments",
    Chat = "Chat",
}

export interface WireCommentsComponent extends WireListComponentGeneric<ArrayScreenFormat.Comments> {
    readonly title: string | Unbound;
    readonly componentStyle: CommentsStyle;
    readonly emptyMessage: string | Unbound;
    readonly buttonText: string | Unbound;

    readonly items: readonly WireCommentItem[];

    // For new comments
    readonly newComment: WireAlwaysEditableValue<string>;
    readonly allowAdd: boolean;
    readonly postNewComment: WireAction;
    readonly loggedUser:
        | {
              userPhoto: string | undefined;
              userEmail: string | undefined;
              userName: string | undefined;
          }
        | undefined;
    readonly showMoreComments: WireAction | undefined;
}

/**
 * This type is crap because of recharts.
 * A chart can have multiple Y values per X.
 *
 * We need an object like
 *
 *  {
 *      X_AXIS_LABEL: "label for X axis at this point"
 *      yDataKey1: yValue1,
 *      yDataKey2: yValue2,
 *      yDataKey3: yValue3,
 *      GLIDE_INTERNAL_DISPLAY_yDataKey1: formattedYValue1,
 *      GLIDE_INTERNAL_DISPLAY_yDataKey2: formattedYValue2,
 *      GLIDE_INTERNAL_DISPLAY_yDataKey3: formattedYValue3,
 *  }
 *
 * It's pretty hard to type correctly, so we'll go with the simple approach
 */
export type WireChartData = Record<string, string | number | Date>;

export type DataPlotRowData = {
    name: string;
    points: {
        [key: string]: {
            value: number;
            color?: string;
        };
    };
    timestamp?: number;
};

export const CHART_X_AXIS_DATA_KEY = "X_AXIS_LABEL";

const DISPLAY_DATA_PREFIX = "GLIDE_INTERNAL_DISPLAY_";

export function getChartDisplayDataKey(valueDataKey: string): string {
    return `${DISPLAY_DATA_PREFIX}${valueDataKey}`;
}

function isDisplayDataKey(dataKey: string): boolean {
    return dataKey.startsWith(DISPLAY_DATA_PREFIX);
}

export function getValueDataKeys(chartDataPoint: WireChartData): string[] {
    return Object.keys(chartDataPoint).filter(k => k !== CHART_X_AXIS_DATA_KEY && !isDisplayDataKey(k));
}

export interface WireChartsComponent extends WireListComponentGeneric<ArrayScreenFormat.Charts> {
    readonly chartData: WireChartData[];
    readonly captions: Record<string, string>;
    readonly graphTitle: string | Unbound;
    readonly chartType?: PagesChartType;
    readonly graphName?: string;
    readonly barChartType?: BarChartType;
    readonly barChartWeight?: BarChartWeights;
    readonly showXLabels?: boolean;
    readonly showYLabels?: boolean;
    readonly showGridLines?: boolean;
    readonly showLegend?: boolean;
}

export type WireDataPlotDataPoint = {
    label: string | undefined;
    color: string | undefined;
    dataKey: string | undefined;
    type: DataPlotType;
    // This is an optional display field that gets used in the chart.
    rollupKind?: DataPlotRollupKind | undefined;
};

export interface WireDataPlotComponent extends WireListComponentGeneric<ArrayScreenFormat.DataPlot> {
    readonly data: DataPlotRowData[];
    readonly columnsToDisplay: WireDataPlotDataPoint[];
    readonly graphTitle: string | Unbound;
    readonly showYAxisLabels?: boolean;
    readonly showLegend?: boolean;
    readonly colorScheme: ChartingColorScheme;
    readonly gradientMapping?: ChartingGradientMapping;
    readonly gradientColors: string[];
    readonly timeSeriesRelativeDurationState?: WireAlwaysEditableValue<"All" | TimeSeriesRelativeDuration>;
    readonly enableTimeFilters: boolean;
}

export interface WireRadialChartData {
    label: string;
    value: number;
    display: string;
    color: string | undefined;
}

export interface WireRadialChartComponent extends WireListComponentGeneric<ArrayScreenFormat.RadialChart> {
    readonly chartData: WireRadialChartData[];
    readonly graphTitle: string | Unbound;
    readonly showLegend: boolean;
    readonly showValuesInLegend: boolean;
    readonly lineWeight: RadialChartWeights;
    readonly colorScheme: Omit<ChartingColorScheme, "Gradient">;
}

export interface ChoiceItem {
    readonly displayAs: string;
    readonly image?: string;
}

// FIXME: To make this work well for backend NCM we'll have to get rid of the
// `onChange` action and instead make `isSelected` into an editable value.
// That way the display layer can react right away when a state is changed and
// there won't be jank.  Well, except if it's a single-select Choice, vs a
// multi-select.  In that case the ideal representation would be just the
// selected index, like in `WireAppPivotBar`.  I'm pretty sure we don't want
// to represent the selection as an array of selected values, because then
// we'd have to send updates as "complex mutations".  Though, on the other
// hand, if we already have the infrastructure for that, maybe editable values
// should accept complex mutations?  Then we could for example do proper
// atomic increment end-to-end.
export interface WireChoiceItem extends ChoiceItem {
    readonly isSelected: boolean;
    readonly onChange: WireAction | undefined;
}

export interface LabelAndImageForChosenItems {
    readonly label: string | undefined;
    readonly image?: string;
}

export function getLabelAndImageForChosenItems(
    numItems: number,
    // `items` will only be used if `numItems` is `1`.  Even then, it's
    // optional.
    items: readonly ChoiceItem[] | undefined,
    noSelectionLabel: string | undefined,
    appKind: AppKind
): LabelAndImageForChosenItems {
    let label: string | undefined;
    let image: string | undefined;
    if (numItems === 0) {
        label = noSelectionLabel;
    } else if (numItems === 1) {
        const item = items?.[0];
        if (item !== undefined) {
            label = item.displayAs;
            image = item.image;
        } else {
            label = getLocalizedString("oneItem", appKind);
        }
    } else if (numItems >= 2) {
        label = makeLocalizedNumberOfItems(numItems, appKind);
    }
    return { label, image };
}

export function getAllowedChoiceColumns(choiceTable: TableGlideType): ReadonlyArray<TableColumn> {
    const columns = getPrimitiveColumns(choiceTable);
    return getNonHiddenColumns(columns);
}

export function getAllowedChoiceTables(_desc: Description, schema: SchemaInspector): ReadonlyArray<TableGlideType> {
    return getTablesWithPrimitiveColumns(schema.schema.tables, false, false).filter(
        choiceTable => getAllowedChoiceColumns(choiceTable).length > 0
    );
}

interface LinkTargetType {
    readonly targetTable: TableGlideType;
    readonly isMulti: boolean;
}

interface LinkTarget extends LinkTargetType {
    readonly hostColumn: TableColumn;
    readonly targetColumn: TableColumn;
}

interface LinkTargetWithSourceColumn extends LinkTarget {
    readonly linkColumn: TableColumn;
    readonly hostSourceColumn: SourceColumn;
    readonly isInScreenContext: boolean;
}

export function getTargetForLink(
    hostTable: TableGlideType,
    linkColumn: TableColumn,
    schema: SchemaInspector,
    // Do we also allow single-relation columns configured as regular computed
    // columns?  Those have a host column that's not hidden.
    withUserConfiguredLink: boolean
): LinkTarget | undefined {
    if (!isComputedColumn(linkColumn)) return undefined;
    const spec = decomposeFilterReferenceFormula(linkColumn.formula);
    if (spec === undefined) return undefined;

    const hostColumn = getTableColumn(hostTable, spec.hostColumn);
    if (hostColumn === undefined) return undefined;
    if (!withUserConfiguredLink && hostColumn.hidden !== true) return undefined;
    if (
        !isColumnWritable(hostColumn, hostTable, false, { allowProtected: false, allowArrays: true, allowHidden: true })
    ) {
        return undefined;
    }

    if (spec.multiple && hostColumn.type.kind !== "array") return undefined;

    if (hostColumn.type.kind === "array") {
        // We support writing to array columns only in Airtable for now.
        const metadata = getSourceMetadataForTable(schema.sourceMetadata ?? [], hostTable);
        if (metadata?.type !== "Native table") return undefined;
        if (metadata.externalSource?.type !== "airtable") return undefined;
    }

    const targetTable = schema.findTable(spec.targetTable);
    if (targetTable === undefined) return undefined;
    if (isBigTableOrExternal(targetTable)) return undefined;

    const targetColumn = getTableColumn(targetTable, spec.targetColumn);
    if (targetColumn === undefined) return undefined;

    return { hostColumn, targetTable, targetColumn, isMulti: spec.multiple };
}

export function getColumnLinkTargetFromValueProperty(
    schema: SchemaInspector,
    tables: InputOutputTables | undefined,
    valueProperty: PropertyDescription | undefined
): LinkTargetWithSourceColumn | undefined {
    if (tables === undefined) return undefined;

    const sourceColumn = getSourceColumnProperty(valueProperty);
    if (sourceColumn === undefined) return undefined;
    const path = getSourceColumnPath(sourceColumn);
    assert(path.length > 0);

    const resolved = resolveSourceColumn(schema, sourceColumn, tables.output, undefined, undefined);
    if (resolved?.tableAndColumn === undefined) return undefined;

    const { table, column } = resolved.tableAndColumn;

    const linkTarget = getTargetForLink(table, column, schema, true);
    if (linkTarget === undefined) return undefined;

    const hostSourceColumn: SourceColumn = {
        kind: sourceColumn.kind,
        name: replaceArrayItem(path, path.length - 1, linkTarget.hostColumn.name),
    };

    return {
        ...linkTarget,
        linkColumn: column,
        hostSourceColumn,
        isInScreenContext: sourceColumn.kind === SourceColumnKind.DefaultContext && path.length === 1,
    };
}

export interface WireChoiceComponent extends WireListComponentGeneric<ArrayScreenFormat.Choice> {
    readonly title: string;
    readonly style: ChoiceStyle;

    readonly isMulti: boolean;
    readonly maxChoices: number | undefined;
    readonly isRequired: boolean;

    // These can be `undefined` if the component hasn't been opened yet.  The
    // `onTap` action will initiate the loading in that case.  We will only do
    // this for styles where it makes sense, i.e. only the `Dropdown` style
    // right now.
    readonly items: readonly WireChoiceItem[] | undefined;
    readonly labelAndImageForChosenItems: LabelAndImageForChosenItems;
    readonly hasImages: boolean;

    // Used in Apps for the dropdown style, as well as in Pages for on-demand
    // loading of the items.  If the action is busy, then the UI should show a
    // loading indicator.
    readonly onTap: WireAction | undefined;

    readonly pagesSearch: WireAlwaysEditableValue<string> | undefined;
}

export interface WireForEachContainerItem {
    readonly components: readonly (WireComponent | null)[];
    readonly action: WireAction | undefined;
}

export interface WireForEachContainer extends WireListComponentGeneric<ArrayScreenFormat.ForEachContainer> {
    readonly layout: ContainerLayout;
    readonly containerStyle: UIBackgroundStyle.None | UIBackgroundStyle.Card;
    readonly rowIDs: readonly string[];
    readonly itemForRowID: Record<string, WireForEachContainerItem>;
    readonly alignment: ContainerAlignment;
    readonly title: string | Unbound;
    readonly titleStyle: UITitleStyle;
    readonly titleImage?: string | Unbound;
    readonly titleActions: readonly WireActionWithTitle[];
    readonly gridSize: UISize | undefined;
}

export interface WireActivitySpinnerComponent extends WireComponent {
    readonly kind: WireComponentKind.ActivitySpinner;
    // By default this component floats. Not sure why.
    // For compatibility reasons, that's still the default behavior.
    // Right now we only need this for loading indicators in subsidiaries.
    // In particular, only for multiple filters.
    readonly isInline?: boolean;
}

export interface WireListItem {
    readonly title: string | null;
    readonly subtitle: string | null;
    readonly action: WireAction | undefined;
}

interface WireListItemWithKey extends WireListItem {
    readonly key: string;
}

export interface WireAppIconAccessoryComponent extends WireComponent {
    readonly kind: WireComponentKind.AppIconAccessory;
    readonly icon: string;
    readonly action: WireAction | undefined;
}

export interface WireAppCircleButtonAccessoryComponent extends WireComponent {
    readonly kind: WireComponentKind.AppCircleButtonAccessory;
    readonly icon: string;
    readonly action: WireAction | undefined;
}

export interface WireListListItemAccessory {
    readonly component: WireAppIconAccessoryComponent | WireAppCircleButtonAccessoryComponent;
    readonly position: ListItemAccessoryPosition;
}

export interface WireListListItem extends WireListItemWithKey {
    readonly image: WireEditableValue<string> | Unbound;
    readonly caption: string | null;
    readonly icon: string | null;
    readonly accessory?: WireListListItemAccessory;
}

export interface WireAppListComponent<TFormat extends ArrayScreenFormat> extends WireComponent {
    readonly kind: WireComponentKind.List;
    readonly format: TFormat;
    readonly title: string;
    readonly emptyMessage: string;
}

export interface WireAppListGroup<TItem> {
    readonly title: string;
    readonly items: readonly TItem[];
    readonly seeAllAction: WireAction | undefined;
}

interface WireAppGroupedListComponent<TFormat extends ArrayScreenFormat, TItem extends WireListItemWithKey>
    extends WireAppListComponent<TFormat> {
    readonly groups: readonly WireAppListGroup<TItem>[];
}

export enum WireImageFallback {
    None = "none",
    Initials = "initials",
    Title = "title",
}

export interface WireAppListListComponent<T extends ArrayScreenFormat.List | ArrayScreenFormat.SmallList>
    extends WireAppGroupedListComponent<T, WireListListItem> {
    readonly allowWrapping: boolean;
    readonly imageFallback: WireImageFallback;
    readonly flags: ListItemFlags;
}

export interface WireAppTagOverlayComponent extends WireComponent {
    readonly kind: WireComponentKind.AppTagOverlay;
    readonly text: string;
    readonly corners: Corners;
}

export interface WireAppAvatarOverlayComponent extends WireComponent {
    readonly kind: WireComponentKind.AppAvatarOverlay;
    // Do we need to know whether these two are bound or not?
    readonly image?: string;
    readonly caption: string;
    readonly textSize: TextSize;
}

export interface WireAppToggleIconOverlayComponent extends WireComponent {
    readonly kind: WireComponentKind.AppToggleIconOverlay;
    readonly value: WireEditableValue<boolean>;
    // This is also an ##onFavoriteToggle.
    readonly onToggle: WireAction | undefined;
}

export interface WireAppOverlays {
    readonly topLeft?: WireComponent;
    readonly topRight?: WireComponent;
    readonly bottomLeft?: WireComponent;
    readonly bottomRight?: WireComponent;
}

export function getWireImageSourceToken(imageSource: WireImageSource | undefined | null): string | undefined {
    if (imageSource === undefined || imageSource === null || hasOwnProperty(imageSource, "kind")) return undefined;
    return imageSource.onChangeToken;
}

export type WireImageSource = MapImageSource | WireEditableValue<string>;

export interface WireTilesListItem extends WireListItemWithKey {
    readonly image: WireImageSource | Unbound | undefined;
    readonly overlays: WireAppOverlays;
}

export interface WireAppTilesBaseListComponent<TFormat extends ArrayScreenFormat, TItem extends WireTilesListItem>
    extends WireAppGroupedListComponent<TFormat, TItem> {
    readonly size: ImageAspectRatio;
    readonly numColumns: number;
    readonly corners: Corners;
    readonly textSize: TextSize;
    readonly textStyle: TextStyle;
    readonly horizontal: boolean;
    readonly imageFallback: WireImageFallback;
}

export interface WireAppTilesListComponent
    extends WireAppTilesBaseListComponent<ArrayScreenFormat.Tiles, WireTilesListItem> {
    readonly allowWrapping: boolean;
    readonly gravity: ImageGravity;
    readonly textPosition: TextPosition;
    readonly padding: TilesPadding;
    readonly overlayTextAlignment: OverlayTextAlignment;
}

export interface WireCardsListItem extends WireTilesListItem {
    readonly header: string | null;
}

export interface WireAppCardsListComponent
    extends WireAppTilesBaseListComponent<ArrayScreenFormat.Cards, WireCardsListItem> {
    readonly numTextLines: number;
    readonly floatStyle: CardFloatStyle;
}

export interface WireAppTinderCard {
    readonly components: readonly (WireComponent | null)[];
    readonly key: string;
}

export interface WireAppTinderComponent extends WireComponent {
    readonly kind: WireComponentKind.AppTinder;

    // If `top` is undefined then `bottom` will also be undefined, and the
    // `emptyMessage` must be shown.
    readonly top?: WireAppTinderCard;
    readonly bottom?: WireAppTinderCard;

    readonly swipeLeftAction?: WireAction;
    readonly swipeRightAction?: WireAction;

    readonly emptyMessage: string;
}

export interface WireCalendarListItem extends WireListItemWithKey {
    readonly start: GlideDateTime;
    readonly end?: GlideDateTime | null;
}

export type WireCalendarListGroup = WireAppListGroup<WireCalendarListItem>;

export interface WireAppCalendarListComponent
    extends WireAppGroupedListComponent<ArrayScreenFormat.CalendarList, WireCalendarListItem> {
    readonly defaultMode: CalendarListMode;
    readonly order: CalendarListOrder;
    readonly insideInlineList: boolean;
    readonly showTime: boolean;
    readonly stateSaveKey: string | undefined;
}

export interface WireMapListItem extends WireListItemWithKey {
    readonly image: WireEditableValue<string> | Unbound;
    readonly location: string;
    readonly caption: string | Unbound;
}

export interface WireAppMapListComponent extends WireAppListComponent<ArrayScreenFormat.Map> {
    readonly defaultMode: MapListMode;
    readonly visualType: MapVisualType;
    readonly displayContext: DisplayContext;
    readonly allowUserLocation: boolean;
    readonly searchIsActive: boolean;
    readonly insideInlineList: boolean;
    // FIXME: Geocoding should happen on the backend when we do backend NCM.
    readonly quotaKey: string;
    // If this is missing, don't save the state.
    readonly stateSaveKey: string | undefined;
    readonly items: readonly WireMapListItem[];
}

export interface WireAppCheckListItem extends WireListItemWithKey {
    readonly image: WireEditableValue<string> | Unbound;
    readonly checked: WireEditableValue<boolean>;
}

export interface WireAppCheckListComponent
    extends WireAppGroupedListComponent<ArrayScreenFormat.CheckList, WireAppCheckListItem> {
    readonly allowWrapping: boolean;
}

export interface WireAppChartComponent extends WireComponent {
    readonly kind:
        | WireComponentKind.AppPieChart
        | WireComponentKind.AppDonutChart
        | WireComponentKind.AppStackedBarChart
        | WireComponentKind.AppBarChart;

    readonly showLegendByDefault: boolean;
    readonly title: string;
    readonly caption: string;
    readonly color: string; // ColorTheme
}

export interface WireAppPieChartComponent extends WireAppChartComponent {
    readonly kind:
        | WireComponentKind.AppPieChart
        | WireComponentKind.AppDonutChart
        | WireComponentKind.AppStackedBarChart;

    readonly data: readonly ChartDatumWithTitle[];
}

export interface WireAppDonutChartComponent extends WireAppPieChartComponent {
    readonly kind: WireComponentKind.AppDonutChart;

    readonly total?: string;
    readonly units: string;
}

export interface WireAppBarChartComponent extends WireAppChartComponent {
    readonly kind: WireComponentKind.AppBarChart;
    readonly showLabelsOnBars: boolean;

    readonly data: readonly BarChartData[];
    // If this is present, then it has as many items as there `values` in each
    // `data` item.
    readonly columnLabels: readonly string[] | undefined;
}

export interface WireBreadcrumb {
    readonly title?: string | Unbound;
    readonly action?: WireAction;
}

export interface WireBreadcrumbsComponent extends WireComponent {
    readonly kind: WireComponentKind.Breadcrumbs;
    readonly currentPageTitle?: string | null;
    readonly crumbs: readonly WireBreadcrumb[];
    readonly crumbsOmitted: boolean;
    readonly backAction?: WireAction;
}

export interface WireInlineScannerComponent extends WireComponent {
    readonly kind: WireComponentKind.InlineScanner;
    readonly editableScan: WireAlwaysEditableValue<string>;
}

export interface WirePageSignatureFieldComponent extends WireComponent {
    readonly kind: WireComponentKind.SignatureField;
    readonly title: string | Unbound;
    readonly signature: string | Unbound;
    readonly triggerSignaturePadAction: WireAction | undefined;
    readonly clearSignatureAction: WireAction | undefined;
    readonly isRequired: boolean;
}

export interface WireSignInComponent extends WireComponent {
    readonly kind: WireComponentKind.SignIn;
    readonly onSuccess: WireAction;
    readonly onFailure: WireAction;
    readonly isSignUp: boolean;
    readonly withUserName: boolean;
    readonly iconImage?: IconImage;
}

export interface WireAppLabelComponent {
    readonly kind: WireComponentKind.AppLabel;
    readonly text: string;
}

export interface WireAppButton {
    readonly title: string;
    readonly mood: Mood;
    readonly icon?: string;
    readonly style: Appearance;
    readonly onTap: WireAction;
}

export interface WireAppButtonComponent extends WireComponent, WireAppButton {
    readonly kind: WireComponentKind.AppButton;
    readonly confirm?: {
        readonly title: string;
        readonly description: string;
        readonly modalStyle: ConfirmModalStyle;
        readonly accept: string;
        readonly cancel: string;
    };
}

export interface WireAppButtonBarComponent extends WireComponent {
    readonly kind: WireComponentKind.AppButtonBar;
    // At least one of these will be defined
    readonly left?: WireAppButton;
    readonly right?: WireAppButton;
}

export interface WireAppTitleComponent extends WireComponent {
    readonly kind: WireComponentKind.AppTitle;
    readonly title?: string;
    readonly subtitle?: string;
    readonly image?: WireImageSource | Unbound;
    readonly appearance: TitleAppearance;
    readonly aspect?: ImageAspectRatio;
    readonly onTap?: WireAction;
}

export interface WireAppUserProfileComponent extends WireComponent {
    readonly kind: WireComponentKind.AppUserProfile;
    readonly name: string | Unbound;
    readonly email: string | Unbound;
    readonly image: WireEditableValue<string> | Unbound;
}

export interface WireAppAdaptiveListItemComponent extends WireComponent {
    readonly kind: WireComponentKind.AppAdaptiveListItem;
    readonly title: string | Unbound;
    readonly subtitle: string | Unbound;
    readonly caption: string | Unbound;
    readonly image: WireEditableValue<string> | Unbound;
    readonly icon?: string;
    readonly initials?: string;
    readonly onTap?: WireAction;
    readonly flags: ListItemFlags;
}

export interface WireAppImageComponent extends WireComponent {
    readonly kind: WireComponentKind.AppImage;
    // This can be an editable value even if `showUploadButton` is not set. In
    // the builder, direct image upload requires it to be. Note that if an
    // array is passed, it can have a single element.  We won't hydrate an
    // empty array.
    readonly image: WireEditableValue<string> | readonly string[];
    // This will only be defined if `image` is an editable value.
    readonly showUploadButton: boolean;
    // This will be defined iff `showUploadButton` is set
    readonly isBusy: WireAlwaysEditableValue<boolean> | undefined;
    readonly size: ImageAspectRatio;
    readonly sizing: Sizing;
    readonly gravity: ImageGravity;
    readonly style: ImageFitStyle;
    readonly withEnlarge: boolean;
    readonly overlays?: WireAppOverlays;
    readonly onTap?: WireAction;
}

export interface WireAppSeparatorComponent extends WireComponent {
    readonly kind: WireComponentKind.AppSeparator;
    readonly spacing: SeparatorSpacing;
    readonly showLine: boolean;
}

export interface WireAppTextBoxComponent extends WireComponent {
    readonly kind: WireComponentKind.AppTextBox;
    readonly text: string;
    readonly style: TextBoxStyle;
    readonly justify: TextBoxJustify;
    readonly lineLimit: TextBoxLineLimit;
    readonly allowWrapping: boolean;
    readonly allCaps: boolean;
}

export interface WireAppPhoneNumberComponent extends WireComponent {
    readonly kind: WireComponentKind.AppPhoneNumber;
    readonly caption: string;
    readonly number: string;
    readonly onPhoneCall?: WireAction;
    readonly onTextMessage?: WireAction;
}

export interface WireAppSimpleTableRow {
    readonly label: string;
    readonly value: string;
    readonly style: SimpleTableRowStyle;
    readonly action?: WireAction;
}

export interface WireAppSimpleTableComponent extends WireComponent {
    readonly kind: WireComponentKind.AppSimpleTable;
    readonly caption?: string;
    readonly footer?: string;
    readonly allowsWrapping: boolean;
    readonly rows: readonly WireAppSimpleTableRow[];
}

export interface WireAppHintComponent extends WireComponent {
    readonly kind: WireComponentKind.AppHint;
    readonly title: string;
    readonly text: string;
    readonly mood: Mood;
    readonly justify: TextBoxJustify;
}

export interface WireAppMarkdownComponent extends WireComponent {
    readonly kind: WireComponentKind.AppMarkdown;
    readonly text: string;
    readonly onTap?: WireAction;
}

export interface WireAppAudioPlayerComponent extends WireComponent {
    readonly kind: WireComponentKind.AppAudioPlayer;
    readonly url: string;
}

export interface WireAppVideoPlayerComponent extends WireComponent {
    readonly kind: WireComponentKind.AppVideoPlayer;
    readonly url: string;
}

export interface WireAppProgressComponent extends WireComponent {
    readonly kind: WireComponentKind.AppProgress;
    readonly style: ProgressType;
    // TODO: Do we want a loading state when `value` is not loaded yet?
    readonly value: number;
    readonly formatted: string;
    readonly minimum: number;
    readonly maximum: number;
    readonly title: string;
    readonly caption: string;
    // Only valid for `Circle`
    readonly showTotal: boolean;
}

export interface WireAppLikeComponent extends WireComponent {
    readonly kind: WireComponentKind.AppLike;
    readonly caption: string;
    readonly value: WireEditableValue<number>;
}

export interface WireAppRatingComponent extends WireComponent {
    readonly kind: WireComponentKind.AppRating;
    readonly caption: string;
    readonly value: WireEditableValue<number>;
    readonly maxRating: number;
    readonly unit: string;
}

export interface WireAppStopwatchComponent extends WireComponent {
    readonly kind: WireComponentKind.AppStopwatch;
    // start button sets startTime, pause clears that and sets duration, stop clears both
    readonly startTime?: WireEditableValue<GlideDateTime | "">;
    readonly duration?: WireEditableValue<number | "">;
}

export interface WireAppEventPickerComponent extends WireComponent {
    readonly kind: WireComponentKind.AppEventPicker;
    readonly title: string;
    readonly placeholder: string;
    readonly isRequired: boolean;
    readonly eventTitle: string; // the date
    readonly eventDescription: string; // the time range
    readonly editStart: WireEditableValue<GlideDateTime | "">;
    readonly editEnd: WireEditableValue<GlideDateTime | "">;
    readonly onClick: WireAction;
}

export interface WireAppEventPickerItem {
    readonly key: string;
    readonly start: GlideDateTime;
    readonly end: GlideDateTime | undefined;
    readonly title: string | Unbound;
    readonly description: string | Unbound;
}

export interface WireAppEventPickerScreenComponent extends WireComponent {
    readonly kind: WireComponentKind.AppEventPickerScreen;
    readonly items: readonly WireAppEventPickerItem[];
    readonly editStart: WireEditableValue<GlideDateTime | "">;
    readonly editEnd: WireEditableValue<GlideDateTime | "">;
}

interface Reaction {
    readonly emoji: string;
    readonly value: string;
}

export interface WireAppReactionComponent extends WireComponent {
    readonly kind: WireComponentKind.AppReaction;
    readonly caption: string;
    readonly value: WireEditableValue<string>;
    readonly reactions: readonly Reaction[];
}

export interface WireAppWebViewComponent extends WireComponent {
    readonly kind: WireComponentKind.AppWebView;
    readonly link: string;
    readonly aspectRatio: ImageAspectRatio;
    readonly title?: string;
    readonly allowScrolling: boolean;
    readonly explicitHeight?: number;
}

export interface WireAICustomComponent extends WireComponent {
    readonly kind: WireComponentKind.AICustomComponent;
    readonly prompt: string;
    readonly fields: { name: string; value: string }[];
}

export interface WireAppMapComponent extends WireComponent {
    readonly kind: WireComponentKind.AppMap;
    readonly address: string;
    readonly zoomLevel: number;
    readonly onTap?: WireAction;
}

export interface WireAppNotesComponent extends WireComponent {
    readonly kind: WireComponentKind.AppNotes;
    readonly title: string | Unbound;
    readonly value: string;
    readonly isRequired: boolean;
    readonly onTap: WireAction | undefined;
}

export interface WireAppSignatureFieldComponent extends WireComponent {
    readonly kind: WireComponentKind.AppSignatureField;
    readonly title: string;
    readonly placeholder: string;
    readonly image: WireEditableValue<string>;
    readonly onTap?: WireAction;
}

export interface WireAppSignaturePadComponent extends WireComponent {
    readonly kind: WireComponentKind.AppSignaturePad;
    readonly message: string;
    readonly image: WireEditableValue<string>;
    // This must be set by the frontend to indicate whether there's at least
    // one path.
    readonly hasPaths: WireAlwaysEditableValue<boolean>;
    readonly onWriteComplete?: WireAction; // when set to a value, the frontend should upload and then call this
}

export interface WirePageSignaturePadComponent extends WireComponent {
    readonly kind: WireComponentKind.SignaturePad;
    readonly image: WireEditableValue<string>;
    readonly isWriting: boolean;
    // This must be set by the frontend to indicate whether there's at least
    // one path.
    readonly hasPaths: WireAlwaysEditableValue<boolean>;
    readonly onWriteComplete?: WireAction; // when set to a value, the frontend should upload and then call this
}

export interface WirePageVoiceEntryComponent extends WireComponent {
    readonly kind: WireComponentKind.VoiceEntry;
    readonly transcript: WireAlwaysEditableValue<string>;
    readonly transcriptionState: WireAlwaysEditableValue<"idle" | "recording" | "processing" | "error">;
    readonly autoRecord: boolean;
    readonly textareaPlaceholder: string;
}

export interface WireAppLocationComponent extends WireComponent {
    readonly kind: WireComponentKind.AppLocation;
    readonly isRequired: boolean;
    readonly switchedOn: WireAlwaysEditableValue<boolean>;
    // frontend writes location here when switched on, and sets to "" when switched off
    readonly value: WireEditableValue<string>;
}

export interface WireAppOverlayEditorComponent extends WireComponent {
    readonly kind: WireComponentKind.AppOverlayEditor;
    readonly placeholder: string | Unbound;
    readonly value: WireEditableValue<string>;
}

interface WireComment extends CommentData {
    readonly onDelete: WireAction;
}

export interface WireAppCommentsComponent extends WireComponent {
    readonly kind: WireComponentKind.AppComments | WireComponentKind.AppChat;
    // Used by the frontend to subscribe/unsubscribe from push notifications.
    readonly topic: string;
    readonly comments: readonly WireComment[];
    readonly users: readonly BasicUserProfile[];
    readonly onNewComment: WireAction | undefined;
}

export interface BuyScreenTransactionItemWithFlags extends BuyScreenTransactionItem {
    readonly withShipping: boolean;
    readonly showStripeLogo: boolean;
}

export interface WireAppBuyScreenComponent extends WireComponent {
    readonly kind: WireComponentKind.AppBuyScreen;
    readonly currencyCode: string;
    readonly paymentInformation: PaymentInformation | undefined;
    readonly items: readonly BuyScreenTransactionItemWithFlags[];
    readonly onSuccess: WireAction;
}

export interface WireAppCodeScannerScreenComponent extends WireComponent {
    readonly kind: WireComponentKind.AppCodeScannerScreen;
    // If `standards` is missing, use the old linear live scanner.
    readonly standards?: CodeScannerStandards;
    readonly acceptOnFirst: boolean;
    readonly value: WireAlwaysEditableValue<string>;
    readonly onSuccess: WireAction;
}

export interface WireAppShareScreenComponent extends WireComponent {
    readonly kind: WireComponentKind.AppShareScreen;
    readonly title: string;
    readonly author: string;
    readonly displayQRCode: boolean;
    readonly onFeedback: WireAction | undefined;
    readonly appIcon: { url: string } | { emoji: string } | { name: string };
}

export interface WireAppMenuItem extends WireComponent {
    readonly kind: WireComponentKind.AppMenuItem;
    readonly title: string;
    readonly icon?: string;
    // The item is disabled if there's no action
    readonly action?: WireAction;
    readonly style?: MenuItemStyle;
    readonly purpose?: MenuItemPurpose;
}

export interface WireAppSearchBar {
    readonly kind: WireComponentKind.AppSearchBar;
    readonly value: WireEditableValue<string>;
    readonly placeholder: string;
}

export interface WireAppPivotBar {
    readonly kind: WireComponentKind.AppPivotBar;
    readonly titles: readonly string[];
    readonly selectedIndex: WireEditableValue<number>;
}
