import React from "react";
import type { DataPlotRowData, WireDataPlotDataPoint } from "@glide/fluent-components/dist/js/base-components";
import { ChartingColorScheme, ChartingGradientMapping } from "@glide/fluent-components/dist/js/fluent-components";
import {
    getGenerativeBrightColor,
    getGenerativeColor,
    getGenerativeGradientColor,
    getGenerativeMutedColor,
    interpolateColor,
} from "@glide/common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { isDarkAppTheme } from "../../utils/is-dark-theme";
import { isEmptyOrUndefinedish } from "@glide/support";
import { filterUndefined } from "@glideapps/ts-necessities";

interface UseColorizationProps {
    data: DataPlotRowData[];
    columnsToDisplay: WireDataPlotDataPoint[];
    colorScheme?: ChartingColorScheme;
    gradientColors?: string[];
    gradientMapping?: ChartingGradientMapping;
}

export interface UseColorizationResult {
    getColor: (i: number, value?: number, columnKey?: string) => string;
    getDataPointColor: (entry: DataPlotRowData, column: WireDataPlotDataPoint) => string;
}

export function useColorization({
    data,
    columnsToDisplay,
    colorScheme,
    gradientColors,
    gradientMapping,
}: UseColorizationProps): UseColorizationResult {
    const theme = useWireAppTheme();
    const isDark = isDarkAppTheme(theme);

    // Calculate global min and max values across all columns for consistent gradient mapping
    const dataRange = React.useMemo(() => {
        let globalMin = Number.MAX_VALUE;
        let globalMax = Number.MIN_VALUE;

        for (const entry of data) {
            for (const column of columnsToDisplay) {
                if (!isEmptyOrUndefinedish(column.dataKey)) {
                    const value = entry.points[column.dataKey ?? ""]?.value;
                    if (value !== undefined) {
                        globalMin = Math.min(globalMin, value);
                        globalMax = Math.max(globalMax, value);
                    }
                }
            }
        }

        // Handle edge case where there's no data or all values are the same
        if (globalMin === Number.MAX_VALUE || globalMin === globalMax) {
            globalMin = 0;
            globalMax = globalMax === Number.MIN_VALUE ? 100 : globalMax;
        }

        return { min: globalMin, max: globalMax };
    }, [data, columnsToDisplay]);

    const getColor = React.useCallback(
        (i: number, value?: number, columnKey?: string): string => {
            // if the column key has a color, use it
            if (columnKey !== undefined) {
                const column = columnsToDisplay.find(c => c.dataKey === columnKey);
                if (column !== undefined && column.color !== undefined) {
                    return column.color;
                }
            }

            if (colorScheme === ChartingColorScheme.Bright) {
                return getGenerativeBrightColor(theme.accent, i, columnsToDisplay.length, isDark);
            }
            if (colorScheme === ChartingColorScheme.Muted) {
                return getGenerativeMutedColor(theme.accent, i, columnsToDisplay.length, isDark);
            }

            if (colorScheme === ChartingColorScheme.Gradient) {
                const definedGradientColors = filterUndefined(gradientColors ?? []);
                if (
                    gradientMapping === ChartingGradientMapping.Data &&
                    value !== undefined &&
                    dataRange !== undefined &&
                    definedGradientColors.length >= 2
                ) {
                    return interpolateColor(value, dataRange.min, dataRange.max, definedGradientColors);
                }
                // For Index mapping or fallback, use the index with the gradient colors
                return getGenerativeGradientColor(i, columnsToDisplay.length, definedGradientColors);
            }

            return getGenerativeColor(theme.accent, i, isDark);
        },
        [colorScheme, theme.accent, isDark, columnsToDisplay, gradientColors, gradientMapping, dataRange]
    );

    const getDataPointColor = React.useCallback(
        (entry: DataPlotRowData, column: WireDataPlotDataPoint): string => {
            // First check for explicit color on the column
            if (!isEmptyOrUndefinedish(column.color)) {
                return column.color;
            }

            // Then check for explicit color on the data point
            if (!isEmptyOrUndefinedish(column.dataKey) && column.dataKey in entry.points) {
                const point = entry.points[column.dataKey];
                if (!isEmptyOrUndefinedish(point.color)) {
                    return point.color;
                }
            }
            // If no explicit color, generate one based on the scheme
            const value =
                !isEmptyOrUndefinedish(column.dataKey) && column.dataKey in entry.points
                    ? entry.points[column.dataKey]?.value
                    : undefined;

            // Find the column index for this data point
            const columnIndex = columnsToDisplay.findIndex(col => col.dataKey === column.dataKey);
            const generatedColor = getColor(columnIndex, value);
            return generatedColor;
        },
        [getColor, columnsToDisplay]
    );

    return {
        getColor,
        getDataPointColor,
    };
}
