import type { WireDataPlotComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WireRenderer } from "../wire-renderer";
import type { ComponentProps } from "react";
import React from "react";
import { ComposedChart as RechartsComposedChart } from "../charts/composed-loader";
import {
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
    WireListContainer,
} from "../wire-list-container/wire-list-container";
import type { ResponsiveSizeClass } from "@glide/wire";
import { APP_MODAL_ROOT, UIStyleVariant, ValueChangeSource } from "@glide/wire";
import "twin.macro";
import { useWireValue } from "../../wire-lib";
import { TimeSeriesRelativeDuration } from "@glide/fluent-components/dist/js/fluent-components";
import { definedMap } from "@glideapps/ts-necessities";
import * as WireDropdown from "../../renderers/wire-dropdown-menu/wire-dropdown-menu";
import { GlideIcon } from "@glide/common";
import { WireContainer } from "../wire-container/wire-container";
import { useResizeDetector } from "react-resize-detector";
import { useResponsiveSizeClass } from "@glide/common-components";
import { parseDataKey } from "../charts/custom-tooltip";
import { useColorization } from "../charts/use-colorization";

function getChartHeight(size: ResponsiveSizeClass | undefined, width: number) {
    if (width < 200) {
        return 220;
    }

    if (size === undefined) {
        return 240;
    }

    if (size === "sm") {
        return 280;
    }

    return 360;
}

function useComposedChartHeight(): { height: number; ref: React.MutableRefObject<HTMLDivElement> } {
    const size = useResponsiveSizeClass();

    // We also need the size cause in _very_ small containers the size class is not granular enough.
    const { ref, width: maybeWidth } = useResizeDetector();

    const width = maybeWidth ?? 0;
    const height = getChartHeight(size, width);

    return { height, ref };
}

type WireDataPlotRenderer = WireRenderer<Omit<WireDataPlotComponent, "format">, { isFirstComponent: boolean }>;

export const WireDataPlot: WireDataPlotRenderer = React.memo(p => {
    const {
        data,
        columnsToDisplay,
        graphTitle,
        gradientColors,
        gradientMapping,
        colorScheme,
        showYAxisLabels,
        showLegend,
        backend,
        searchBar,
        timeSeriesRelativeDurationState,
        enableTimeFilters,
    } = p;

    const { getColor, getDataPointColor } = useColorization({
        data,
        columnsToDisplay,
        colorScheme,
        gradientColors,
        gradientMapping,
    });

    const getCaptionFromDataKey = React.useCallback(
        (k: string): string => {
            const dk = parseDataKey(k);
            const column = columnsToDisplay.find(c => c.dataKey === dk);
            if (column?.rollupKind) {
                return column.label + " (" + column.rollupKind + ")";
            }
            return column?.label ?? "";
        },
        [columnsToDisplay]
    );

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);
    const { height, ref } = useComposedChartHeight();

    return (
        <div tw="px-4 pb-4 rounded-xl border border-border-base bg-bg-front gp-sm:px-5 gp-md:px-6">
            <WireContainer isInMultipleColumnLayout={true} tw="bg-bg-front">
                <WireListContainer
                    ref={ref}
                    appKind={backend.appKind}
                    title={graphTitle ?? ""}
                    titleActions={[]}
                    {...filterArgs}
                    multipleFilterProps={multipleFilterProps}
                    styleVariant={UIStyleVariant.Default}
                    searchValue={searchValue}
                    onSearchChange={onSearchChange}
                    isFirstComponent={false}
                    TimeSeriesFilterComponent={
                        enableTimeFilters ? (
                            <TimeSeriesSelector
                                timeSeriesRelativeDurationState={timeSeriesRelativeDurationState}
                                backend={backend}
                            />
                        ) : null
                    }>
                    <div tw="[span]:(text-xs text-text-xpale) [tspan]:(text-xs text-text-xpale)">
                        <RechartsComposedChart
                            data={data}
                            height={height}
                            columnsToDisplay={columnsToDisplay}
                            showYAxisLabels={showYAxisLabels}
                            getColor={getColor}
                            getDataPointColor={getDataPointColor}
                            getCaptionFromDataKey={getCaptionFromDataKey}
                        />
                    </div>
                    {showLegend && (
                        <div tw="flex flex-wrap gap-y-2 gap-x-4 justify-center mt-4 w-full">
                            {columnsToDisplay.map((col, idx) => {
                                return (
                                    <div key={`${col.label}-${idx}`} tw="flex items-baseline">
                                        <div
                                            style={{ backgroundColor: getColor(idx, 0, col.dataKey) }}
                                            tw="mr-1 w-3 h-3 rounded translate-y-px"
                                        />
                                        <span tw="text-xs text-text-pale">
                                            {getCaptionFromDataKey(col.dataKey ?? "")}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </WireListContainer>
            </WireContainer>
        </div>
    );
});

const TimeSeriesSelector: React.FC<{
    timeSeriesRelativeDurationState: ComponentProps<WireDataPlotRenderer>["timeSeriesRelativeDurationState"];
    backend: ComponentProps<WireDataPlotRenderer>["backend"];
}> = p => {
    const { backend, timeSeriesRelativeDurationState } = p;
    const timeSeriesState = definedMap(timeSeriesRelativeDurationState, state =>
        // This is not a hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useWireValue(state, backend)
    );

    const onChangeTimeSeries = React.useCallback(
        (newTimeSerie: TimeSeriesRelativeDuration) => {
            const timeSerieToSet = newTimeSerie === timeSeriesState?.[0] ? "All" : newTimeSerie;
            timeSeriesState?.[1]?.(timeSerieToSet, ValueChangeSource.User);
        },
        [timeSeriesState]
    );
    const checkIfSelected = React.useCallback(
        (state: TimeSeriesRelativeDuration) => {
            return state === timeSeriesState?.[0];
        },
        [timeSeriesState]
    );
    const timeSerieItems = React.useMemo(
        () => [
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last24Hours),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last24Hours),
                value: TimeSeriesRelativeDuration.Last24Hours,
                displayValue: "Past 24 hours",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last7Days),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last7Days),
                value: TimeSeriesRelativeDuration.Last7Days,
                displayValue: "Past 7 days",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last30Days),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last30Days),
                value: TimeSeriesRelativeDuration.Last30Days,
                displayValue: "Past 30 days",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last90Days),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last90Days),
                value: TimeSeriesRelativeDuration.Last90Days,
                displayValue: "Past 90 days",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last12Months),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last12Months),
                value: TimeSeriesRelativeDuration.Last12Months,
                displayValue: "Past 12 months",
            },
        ],
        [checkIfSelected, onChangeTimeSeries]
    );
    const selected = React.useMemo(() => timeSerieItems.filter(item => item.isSelected)[0], [timeSerieItems]);
    const selectedShortTitle = selected?.displayValue.replace("Past", "").trim() ?? "All";
    return (
        <WireDropdown.Root>
            <WireDropdown.Trigger
                tw="relative bg-n200A! border border-transparent flex shrink-0 grow-0 text-base text-text-contextual-xpale 
                items-center justify-between h-9 p-2 transition duration-75 rounded-lg first:ml-0 not-last:mr-2 gp-md:px-3
                page-hover:(ring-1 ring-border-dark border-border-dark) focus-within:(ring-1 border-text-contextual-accent ring-text-contextual-accent)"
                title={selectedShortTitle}
                onClick={e => e.stopPropagation()}>
                <div data-icon tw="transition-colors mr-1.5">
                    <GlideIcon tw="-mb-px shrink-0" kind="stroke" icon="st-calendar" iconSize={16} />
                </div>
                <div tw="overflow-hidden flex-1 text-left whitespace-nowrap text-ellipsis">{selectedShortTitle}</div>
                <div data-icon tw="transition-colors ml-2">
                    <GlideIcon tw="-mb-px shrink-0" kind="stroke" icon="st-caret" iconSize={14} />
                </div>
            </WireDropdown.Trigger>
            <WireDropdown.Portal container={document.getElementById(APP_MODAL_ROOT)}>
                <WireDropdown.Content align="start" side="bottom" sideOffset={2} tw="min-w-[200px]">
                    {timeSerieItems?.map(o => {
                        const isSelected = o.isSelected;
                        return (
                            <WireDropdown.CheckboxItem
                                key={o.value}
                                checked={isSelected}
                                onClick={e => e.stopPropagation()}
                                tw="p-1 w-52"
                                onSelect={o.onSelect}>
                                {o?.displayValue ?? "None"}
                            </WireDropdown.CheckboxItem>
                        );
                    })}
                </WireDropdown.Content>
            </WireDropdown.Portal>
        </WireDropdown.Root>
    );
};
