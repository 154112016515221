import { nativeTableRowIDColumnName, type TableColumn, makeTableColumn } from "@glide/type-schema";
import { defaultRowIDColumnName } from "./google-sheets";

export function makeNativeTableRowIDColumn(hidden: boolean): TableColumn {
    return makeTableColumn(nativeTableRowIDColumnName, "string", {
        displayName: defaultRowIDColumnName,
        hidden,
        isUserSpecific: false,
        isProtected: false,
    });
}
