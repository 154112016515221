import { DeviceFormFactor, appIsInstalled, browserMightBeOniOS } from "@glide/common";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireTitleComponent } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireContainerComponent } from "@glide/fluent-components/dist/js/fluent-components-containers";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import {
    WireComponentKind,
    UIBackgroundStyle,
    UIButtonAppearance,
    UIImageFill,
    UIImageSize,
    UIWireTitleStyle,
    isAccentBackgroundStyle,
    isDarkBackgroundStyle,
    type WireComponent,
} from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";
import { useIsInsideOverlay } from "../../chrome/content/lib/use-is-inside-overlay";
import { nullifyUnnecesaryMobileBreadcrumb } from "../../chrome/content/lib/use-layer-compoents";
import { Img } from "../../components/img/img";
import { Text } from "../../components/text/text";
import { WireButton } from "../wire-button/wire-button";
import { WireContainer, useSectionStyle } from "../wire-container/wire-container";
import { WireMenuButton } from "../wire-menu-button/wire-menu-button";
import {
    type ExtractedAction,
    type ExtractedActions,
    breakoutActions,
    makeActionSpreadProps,
    useTitleBreadcrumbsPortalId,
} from "../../wire-lib";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

type WireHeroComponent = Omit<WireTitleComponent, "backgroundImage" | "withBlur">;

interface BaseTitleProps extends Omit<TitleProps, "titleStyle" | "isInMultipleColumnLayout" | "selectModeInfo"> {
    appID: string;
}

interface UseTitleHelpersArgs {
    imageSize: UIImageSize;
    actions: ExtractedActions;
}

interface UseTitleHelpers {
    (args: UseTitleHelpersArgs): {
        primary: ExtractedAction | undefined;
        secondary: ExtractedAction | undefined;
        menuActions: ExtractedAction[];
        isMobile: boolean;
        hasActions: boolean;
        isCompact: boolean;
        buttonSize: "mini" | "md";
    };
}

const useTitleHelpers: UseTitleHelpers = ({ actions, imageSize }) => {
    const [primary, secondary, ...menuActions] = breakoutActions(actions, 2);
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    const hasMenu = menuActions.length > 0;
    const hasActions = isDefined(primary) || isDefined(secondary) || hasMenu;
    const isCompact = imageSize === UIImageSize.Compact;
    const buttonSize: "mini" | "md" = isMobile ? "mini" : "md";

    return {
        primary,
        secondary,
        menuActions,
        isMobile,
        hasActions,
        isCompact,
        buttonSize,
    };
};

const useThemeShouldFloatBehindStatusBar = () => {
    const theme = useWireAppTheme();
    return theme.pageTheme !== "Highlight";
};

const useItShouldFloatBehindStatusBar = (isFirstComponent: boolean): boolean => {
    const themeShouldFloat = useThemeShouldFloatBehindStatusBar();
    const isInsideOverlay = useIsInsideOverlay();
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    return (
        isFirstComponent && themeShouldFloat && browserMightBeOniOS && appIsInstalled() && !isInsideOverlay && isMobile
    );
};

const useBuilderItShouldFloatBehindStatusBar = (isFirstComponent: boolean): boolean => {
    const themeShouldFloat = useThemeShouldFloatBehindStatusBar();
    const isInsideOverlay = useIsInsideOverlay();
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    return isFirstComponent && themeShouldFloat && !isPlayer() && !isInsideOverlay && isMobile;
};

const shouldFloatBehindStatusBarClassName = "should-float-behind-status-bar";
const builderShouldFloatBehindStatusBarClassName = "builder-should-float-behind-status-bar";

const SimpleTitle: React.VFC<BaseTitleProps> = p => {
    const { actions, image, subtitle, title, titleEmphasized, imageSize, imageFill, appID } = p;

    const {
        isMobile,
        menuActions: maybeMenuActions,
        primary,
        secondary,
        hasActions,
        isCompact,
        buttonSize,
    } = useTitleHelpers({ actions, imageSize });

    let menuActions = maybeMenuActions;
    if (isMobile && isDefined(secondary)) {
        menuActions = [secondary, ...menuActions];
    }

    const singleImage = getFirstElementFromArrayOrSingleElement(image);

    const hasImage = !isEmptyOrUndefinedish(singleImage);
    const smallTitle = isMobile && isCompact && hasActions;

    const rowView = isCompact || !hasImage;

    return (
        <div>
            <TitleBreadcrumbs />
            <div tw="flex gap-x-4">
                {hasImage && (
                    <Img
                        className={classNames(imageSize, imageFill)}
                        css={css`
                            &.${UIImageSize.Compact} {
                                ${tw`[--image-size: 72px] gp-md:([--image-size: 100px])`}
                            }

                            &.${UIImageFill.Contain} {
                                ${tw`object-contain`}
                            }
                        `}
                        tw="[--image-size: 114px] gp-md:([--image-size: 120px]) w-[var(--image-size)] h-[var(--image-size)] rounded-xl bg-icon-disabled object-cover"
                        src={massageImageUrl(
                            singleImage,
                            {
                                thumbnail: false,
                            },
                            appID
                        )}
                    />
                )}
                <div
                    tw="flex flex-col flex-wrap gap-2 justify-between gp-md:(flex-row flex-nowrap items-center justify-between) flex-1 min-w-0"
                    css={[
                        rowView && tw`flex-row justify-between`,
                        !hasActions && rowView && tw`items-center`,
                        !hasActions && !rowView && tw`justify-center`,
                    ]}>
                    <TextContent
                        css={[rowView && tw`self-center`]}
                        titleVariant={smallTitle ? TextComponentStyle.headlineSmall : TextComponentStyle.headlineMedium}
                        subtitle={subtitle}
                        title={title}
                        titleEmphasized={titleEmphasized}
                    />
                    <ActionButtons
                        tw="flex gap-x-1.5 gp-md:self-center"
                        css={[rowView && tw`self-center`]}
                        primary={primary}
                        secondary={menuActions.length > maybeMenuActions.length ? undefined : secondary}
                        menuActions={menuActions}
                        buttonSize={buttonSize}
                        titleStyle={UIWireTitleStyle.Simple}
                    />
                </div>
            </div>
        </div>
    );
};

const ImageTitle: React.VFC<BaseTitleProps> = p => {
    const { actions, image, subtitle, title, titleEmphasized, imageSize, isFirstComponent, imageFill, appID } = p;
    const { isMobile, menuActions, primary, secondary, hasActions, isCompact } = useTitleHelpers({
        actions,
        imageSize,
    });

    const smallTitle = isMobile && isCompact;
    const titleVariant = smallTitle ? TextComponentStyle.headlineMedium : TextComponentStyle.headlineLarge;

    const itShouldFloatBehindStatusBar = useItShouldFloatBehindStatusBar(isFirstComponent);
    const builderItShouldFloatBehindStatusBar = useBuilderItShouldFloatBehindStatusBar(isFirstComponent);

    const singleImage = getFirstElementFromArrayOrSingleElement(image);

    return (
        <div>
            <TitleBreadcrumbs />
            <div tw="flex relative flex-col gap-x-4 w-full gp-md:flex-row">
                {(itShouldFloatBehindStatusBar || builderItShouldFloatBehindStatusBar) && <ShadowHeader />}
                <Img
                    alt={title ?? undefined}
                    className={classNames(imageSize, imageFill)}
                    css={css`
                        &.${UIImageSize.Compact} {
                            ${tw`h-[200px] gp-md:(w-[280px])`}
                        }

                        .${builderShouldFloatBehindStatusBarClassName} &,
                        .${shouldFloatBehindStatusBarClassName} & {
                            ${tw`rounded-t-none`}
                        }

                        &.${UIImageFill.Contain} {
                            ${tw`object-contain bg-w30A! backdrop-blur page-md:(bg-transparent! backdrop-blur-[unset])`}
                        }
                    `}
                    tw="col-start-1 row-start-1 w-full object-cover h-[280px] gp-md:(w-[392px]) rounded-t-xl gp-md:(hidden rounded-xl) bg-bg-back gp-md:(bg-icon-disabled)"
                    src={massageImageUrl(
                        singleImage ?? "",
                        {
                            thumbnail: false,
                        },
                        appID
                    )}
                />

                <div
                    css={[hasActions ? tw`justify-between` : tw`justify-center`]}
                    tw="flex flex-col flex-1 gap-y-4 p-4 min-w-0 gp-md:py-0">
                    <TextContent
                        titleVariant={titleVariant}
                        subtitle={subtitle}
                        title={title}
                        titleEmphasized={titleEmphasized}
                    />
                    <ActionButtons
                        growButtons={isMobile}
                        tw="flex gap-x-1.5"
                        primary={primary}
                        secondary={secondary}
                        menuActions={menuActions}
                        buttonSize="md"
                        titleStyle={UIWireTitleStyle.Image}
                    />
                </div>
            </div>
        </div>
    );
};

interface CoverTitleProps extends Omit<BaseTitleProps, "textEmphasized"> {
    readonly isInMultipleColumnLayout: WireHeroExtraProps["isInMultipleColumnLayout"];
    readonly isFirstComponent: WireHeroExtraProps["isFirstComponent"];
}

const CoverTitle: React.VFC<CoverTitleProps> = p => {
    const {
        actions,
        image,
        subtitle,
        title,
        imageSize,
        customCssClassName,
        isInMultipleColumnLayout,
        headerImage,
        isFirstComponent,
        imageFill,
        appID,
    } = p;
    const { menuActions, primary, secondary, isCompact, buttonSize, isMobile } = useTitleHelpers({
        actions,
        imageSize,
    });
    const smallTitle = isCompact;
    const titleVariant = smallTitle ? TextComponentStyle.headlineSmall : TextComponentStyle.headlineMedium;

    const sectionStyle = useSectionStyle();
    const isDark = isDarkBackgroundStyle(sectionStyle);
    const isAccent = isAccentBackgroundStyle(sectionStyle);

    let background = UIBackgroundStyle.Highlight;

    const firstHeaderImage = getFirstElementFromArrayOrSingleElement(headerImage);
    const firstImage = getFirstElementFromArrayOrSingleElement(image);

    const doesNothaveHeaderImage = !isDefined(firstHeaderImage) || firstHeaderImage.length === 0;

    if (doesNothaveHeaderImage) {
        background = UIBackgroundStyle.Accent;
    }
    const itShouldFloatBehindStatusBar = useItShouldFloatBehindStatusBar(isFirstComponent);
    const builderItShouldFloatBehindStatusBar = useBuilderItShouldFloatBehindStatusBar(isFirstComponent);

    const isInsideOverlay = useIsInsideOverlay();

    return (
        <div
            data-testid={`title-cover${customCssClassName ? `-${customCssClassName}` : ""}`}
            className={classNames(
                isFirstComponent && !isInsideOverlay && "first-component",
                imageSize,
                itShouldFloatBehindStatusBar && shouldFloatBehindStatusBarClassName,
                builderItShouldFloatBehindStatusBar && builderShouldFloatBehindStatusBarClassName
            )}
            tw="relative flex flex-col w-full [--cover-img-size: 150px] gp-md:([--cover-img-size: 200px]) pb-2"
            css={css`
                &.${UIImageSize.Compact} {
                    ${tw`[--cover-img-size: 110px] gp-md:([--cover-img-size: 150px])`}
                }

                &.first-component {
                    ${titleAsFirstCompoentnStyle}
                }

                .${DeviceFormFactor.Phone}
                    &.${builderShouldFloatBehindStatusBarClassName},
                    &.${shouldFloatBehindStatusBarClassName} {
                    ${itShouldFloatBehindStatusBarStyle}
                    ${tw`[--cover-img-size: calc(150px + var(--safe-area-inset-top))]`}
                }

                .${DeviceFormFactor.Phone}
                    &.${UIImageSize.Compact}.${builderShouldFloatBehindStatusBarClassName},
                    &.${UIImageSize.Compact}.${shouldFloatBehindStatusBarClassName} {
                    ${tw`[--cover-img-size: calc(110px + var(--safe-area-inset-top))]`}
                }
            `}>
            {(itShouldFloatBehindStatusBar || builderItShouldFloatBehindStatusBar) && <ShadowHeader />}
            <CoverImage
                isFirstComponent={isFirstComponent}
                image={firstHeaderImage ?? ""}
                tw="bg-accent"
                alt={title ?? subtitle ?? undefined}
                appID={appID}
            />
            <WireContainer
                className={classNames(isFirstComponent && !isInsideOverlay && "first-component")}
                tw="mt-0"
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={customCssClassName}>
                <WireContainer
                    isInMultipleColumnLayout
                    background={background}
                    tw="hidden px-0 mt-0 bg-transparent page-md:block">
                    <TitleBreadcrumbs tw="absolute -top-[var(--cover-img-size)] pt-3" />
                </WireContainer>
                <div tw="min-w-0 flex gap-4 pt-4 [--image-size: 100px] gp-md:(px-0 [--image-size: 120px])">
                    {!isEmptyOrUndefinedish(firstImage) && (
                        <div tw="relative h-[calc(var(--image-size) / 2)] gp-md:h-0">
                            <Img
                                tw="w-[var(--image-size)] h-[var(--image-size)] gp-md:([transform: translateY(-55%)] ) 
                                    inset-0 rounded-xl ring-bg-front ring-2 -translate-y-1/2 object-cover backdrop-blur-2xl"
                                css={[
                                    isDark && tw`ring-n800`,
                                    isAccent && tw`ring-accent`,
                                    css`
                                        &.${UIImageFill.Contain} {
                                            ${tw`object-contain`}
                                        }
                                        &.${UIImageFill.Contain}.dark-bg {
                                            ${tw`bg-n800!`}
                                        }
                                        &.${UIImageFill.Contain}.accent-bg {
                                            ${tw`bg-accent!`}
                                        }
                                    `,
                                ]}
                                src={massageImageUrl(
                                    firstImage ?? "",
                                    {
                                        thumbnail: false,
                                    },
                                    appID
                                )}
                                alt={title ?? subtitle ?? undefined}
                                alternate={
                                    <div tw="w-[var(--image-size)] h-[var(--image-size)] rounded-xl bg-icon-disabled ring-bg-front ring-2 object-cover" />
                                }
                                className={classNames(
                                    imageFill,
                                    isDarkBackgroundStyle(sectionStyle) && "dark-bg",
                                    isAccentBackgroundStyle(sectionStyle) && "accent-bg"
                                )}
                            />
                        </div>
                    )}
                    <div tw="flex flex-1 justify-start flex-col gap-4 min-w-0 gp-md:(flex-row items-center)">
                        <TextContent tw="flex-1" titleVariant={titleVariant} subtitle={subtitle} title={title} />
                        <ActionButtons
                            tw="flex self-start gp-md:self-center gap-x-1.5 mr-1 max-w-full"
                            growButtons={isMobile}
                            primary={primary}
                            secondary={secondary}
                            menuActions={menuActions}
                            buttonSize={buttonSize}
                            titleStyle={UIWireTitleStyle.Cover}
                        />
                    </div>
                </div>
            </WireContainer>
        </div>
    );
};

interface EmptyCoverProps {
    isFirstComponent: WireHeroExtraProps["isFirstComponent"];
}

const EmptyCover: React.VFC<EmptyCoverProps> = ({ isFirstComponent }) => {
    const theme = useWireAppTheme();
    const sectionStyle = useSectionStyle();
    const pageTheme = theme.pageTheme;

    const backgroundAndPageThemeAreHighlight = UIBackgroundStyle.None === sectionStyle && pageTheme === "Highlight";

    const isInsideOverlay = useIsInsideOverlay();

    return (
        <div
            css={[isFirstComponent && !backgroundAndPageThemeAreHighlight && !isInsideOverlay && tw`mt-6 gp-md:mt-0`]}
            tw="h-[var(--profile-size)]"
        />
    );
};

const ProfileTitle: React.VFC<CoverTitleProps> = p => {
    const {
        actions,
        image,
        subtitle,
        title,
        imageSize,
        customCssClassName,
        isInMultipleColumnLayout,
        headerImage,
        isFirstComponent,
        imageFill,
        appID,
    } = p;
    const { menuActions, primary, secondary, isCompact, buttonSize, isMobile } = useTitleHelpers({
        actions,
        imageSize,
    });
    const smallTitle = isCompact;
    const titleVariant = smallTitle ? TextComponentStyle.headlineSmall : TextComponentStyle.headlineMedium;
    const firstHeaderImage = getFirstElementFromArrayOrSingleElement(headerImage);
    const noCover = isEmptyOrUndefinedish(firstHeaderImage);
    const firstImage = getFirstElementFromArrayOrSingleElement(image);
    const theme = useWireAppTheme();
    const color = React.useMemo(
        () => chroma(theme.textContextualAccent).alpha(0.3).css(),
        [theme.textContextualAccent]
    );
    let background = UIBackgroundStyle.Highlight;
    const itShouldFloatBehindStatusBar = useItShouldFloatBehindStatusBar(isFirstComponent);
    const builderItShouldFloatBehindStatusBar = useBuilderItShouldFloatBehindStatusBar(isFirstComponent);
    const sectionStyle = useSectionStyle();
    if (noCover) {
        background = UIBackgroundStyle.None; // Let the Parent WireContainer handle the background
    }

    const isInsideOverlay = useIsInsideOverlay();

    return (
        <div
            data-testid={`title-profile${customCssClassName ? `-${customCssClassName}` : ""}`}
            className={classNames(
                imageSize,
                isFirstComponent && !isInsideOverlay && "first-component",
                itShouldFloatBehindStatusBar && shouldFloatBehindStatusBarClassName,
                builderItShouldFloatBehindStatusBar && builderShouldFloatBehindStatusBarClassName
            )}
            css={css`
                &.${UIImageSize.Compact} {
                    ${tw`[--cover-img-size: 110px]  [--profile-size: 80px] gp-md:([--cover-img-size: 150px] [--profile-size: 100px])`}
                }

                &.first-component {
                    ${titleAsFirstCompoentnStyle}
                }

                .${DeviceFormFactor.Phone}
                    &.${builderShouldFloatBehindStatusBarClassName},
                    &.${shouldFloatBehindStatusBarClassName} {
                    ${itShouldFloatBehindStatusBarStyle}
                    ${tw`[--cover-img-size: calc(150px + var(--safe-area-inset-top))]`}
                }

                .${DeviceFormFactor.Phone}
                    &.${UIImageSize.Compact}.${builderShouldFloatBehindStatusBarClassName},
                    &.${UIImageSize.Compact}.${shouldFloatBehindStatusBarClassName} {
                    ${tw`[--cover-img-size: calc(110px + var(--safe-area-inset-top))]`}
                }
            `}
            tw="relative flex flex-col w-full [--cover-img-size: 150px] [--profile-size: 100px] gp-md:([--profile-size: 120px] [--cover-img-size: 200px]) pb-2">
            {(itShouldFloatBehindStatusBar || builderItShouldFloatBehindStatusBar) && <ShadowHeader />}
            {noCover && isEmptyOrUndefinedish(firstImage) ? (
                <div css={[tw`h-12`]}></div>
            ) : noCover ? (
                <EmptyCover isFirstComponent={isFirstComponent} />
            ) : (
                <CoverImage
                    isFirstComponent={isFirstComponent}
                    image={firstHeaderImage}
                    css={[isEmptyOrUndefinedish(firstImage) && tw`mb-4 gp-md:mb-6`]}
                    appID={appID}
                />
            )}
            <WireContainer
                tw="mt-0"
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={customCssClassName}>
                <WireContainer
                    isInMultipleColumnLayout
                    background={background}
                    tw="hidden px-0 mt-0 bg-transparent page-md:block">
                    <TitleBreadcrumbs
                        tw="absolute pt-3"
                        css={[
                            tw`-top-[var(--cover-img-size)]`,
                            noCover && tw`-top-[var(--profile-size)]`,
                            noCover && isEmptyOrUndefinedish(firstImage) && tw`-top-12`,
                        ]}
                    />
                </WireContainer>
                <div tw="self-center flex flex-col px-4 items-center gap-y-4 gp-md:(px-0 gap-y-6) max-w-full">
                    {!isEmptyOrUndefinedish(firstImage) && (
                        <Img
                            className={classNames(
                                noCover && "no-cover",
                                imageFill,
                                isDarkBackgroundStyle(sectionStyle) && "dark-bg",
                                isAccentBackgroundStyle(sectionStyle) && "accent-bg"
                            )}
                            tw="-mt-[calc(var(--profile-size)/2)] w-[var(--profile-size)] h-[var(--profile-size)] rounded-full bg-w100A! ring-n0 ring-2 object-cover"
                            css={[
                                css`
                                    &.${UIImageFill.Contain} {
                                        ${tw`object-contain`}
                                    }
                                    &.${UIImageFill.Contain}.dark-bg {
                                        ${tw`bg-n800!`}
                                    }
                                    &.${UIImageFill.Contain}.accent-bg {
                                        ${tw`bg-accent!`}
                                    }

                                    --ring-color: ${color};
                                `,
                                noCover && tw`ring-4 ring-[var(--ring-color)]`,
                            ]}
                            src={massageImageUrl(
                                firstImage ?? "",
                                {
                                    thumbnail: false,
                                },
                                appID
                            )}
                            alt={title ?? subtitle ?? undefined}
                            alternate={
                                <div tw="w-[var(--profile-size)] h-[var(--profile-size)] bg-icon-disabled rounded-full ring-n0 ring-2" />
                            }
                        />
                    )}
                    <TextContent tw="text-center" titleVariant={titleVariant} subtitle={subtitle} title={title} />
                    <ActionButtons
                        tw="flex self-center gap-x-1.5 mr-1 max-w-full"
                        growButtons={isMobile}
                        primary={primary}
                        secondary={secondary}
                        menuActions={menuActions}
                        buttonSize={buttonSize}
                        titleStyle={UIWireTitleStyle.Profile}
                    />
                </div>
            </WireContainer>
        </div>
    );
};

interface CoverImageProps {
    image: string;
    alt?: string;
    className?: string;
    isFirstComponent: boolean;
    appID: string;
}

const CoverImage: React.VFC<CoverImageProps> = p => {
    const { image, alt, className, isFirstComponent, appID } = p;

    const isInsideOverlay = useIsInsideOverlay();
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const width = isMobile ? 1024 : 1600;

    return (
        <Img
            css={css`
                &.first-component {
                    ${tw`rounded-t-xl gp-md:rounded-t-none`}
                }
                .${builderShouldFloatBehindStatusBarClassName} &,
                .${shouldFloatBehindStatusBarClassName} & {
                    ${tw`rounded-t-none`}
                }
            `}
            tw="w-full object-cover h-[var(--cover-img-size)] gp-md:(h-[var(--cover-img-size)])"
            className={classNames(className, isFirstComponent && !isInsideOverlay && "first-component")}
            src={massageImageUrl(
                image,
                {
                    thumbnail: false,
                    width,
                },
                appID
            )}
            alt={alt}
        />
    );
};

interface ActionButtonsProps {
    primary: ExtractedAction | undefined;
    secondary: ExtractedAction | undefined;
    menuActions: ExtractedActions;
    buttonSize: "mini" | "md";
    className?: string;
    growButtons?: boolean;
    titleStyle: UIWireTitleStyle;
}

const ActionButtons: React.VFC<ActionButtonsProps> = p => {
    const { menuActions, primary, secondary, buttonSize, className, growButtons, titleStyle } = p;
    const hasMenu = menuActions.length > 0;
    const hasActions = isDefined(primary) || isDefined(secondary) || hasMenu;
    return hasActions ? (
        <div tw="min-w-0 shrink-0" className={className}>
            {primary !== undefined && (
                <WireButton
                    css={[
                        growButtons && tw`grow`,
                        growButtons && titleStyle === UIWireTitleStyle.Image && tw`shrink basis-0`,
                    ]}
                    size={buttonSize}
                    {...makeActionSpreadProps(primary)}
                    appearance={UIButtonAppearance.Filled}
                />
            )}
            {secondary !== undefined && (
                <WireButton
                    css={[
                        growButtons && tw`grow`,
                        growButtons && titleStyle === UIWireTitleStyle.Image && tw`shrink basis-0`,
                    ]}
                    size={buttonSize}
                    appearance={UIButtonAppearance.Bordered}
                    {...makeActionSpreadProps(secondary)}
                />
            )}
            {hasMenu && (
                <WireMenuButton size={buttonSize} menuItems={menuActions} appearance={UIButtonAppearance.Bordered} />
            )}
        </div>
    ) : null;
};

interface TextContentProps extends Pick<BaseTitleProps, "title" | "subtitle" | "titleEmphasized"> {
    titleVariant: TextComponentStyle;
    className?: string;
}

const TextContent: React.VFC<TextContentProps> = p => {
    const { subtitle, title, titleEmphasized, titleVariant, className } = p;
    return (
        <div className={className} tw="min-w-0 max-w-full break-words">
            {!isEmptyOrUndefinedish(titleEmphasized) && (
                <Text tw="mb-1" element="h3" variant={TextComponentStyle.metaText}>
                    {titleEmphasized}
                </Text>
            )}
            {!isEmptyOrUndefinedish(title) && (
                <Text tw="mb-1 gp-md:mb-1.5" element="h2" variant={titleVariant}>
                    {title}
                </Text>
            )}
            {!isEmptyOrUndefinedish(subtitle) && (
                <Text tw="whitespace-pre-wrap" element="p" variant={TextComponentStyle.regular}>
                    {subtitle}
                </Text>
            )}
        </div>
    );
};

export interface WireHeroExtraProps extends WireHeroComponent {
    readonly backend: WireBackendInterface;
    readonly isInMultipleColumnLayout: boolean | undefined;
    readonly isFirstComponent: boolean;
}

interface TitleProps
    extends Pick<
            WireHeroComponent,
            | "title"
            | "subtitle"
            | "image"
            | "titleEmphasized"
            | "imageSize"
            | "titleStyle"
            | "headerImage"
            | "imageFill"
        >,
        Pick<WireHeroExtraProps, "isInMultipleColumnLayout" | "customCssClassName" | "isFirstComponent"> {
    actions: ExtractedActions;
    appID: string;
}

interface TitleBreadcrumbsProps {
    readonly className?: string;
}
const TitleBreadcrumbs: React.VFC<TitleBreadcrumbsProps> = p => {
    const { className } = p;
    const portalId = useTitleBreadcrumbsPortalId();

    return <div tw="hidden page-md:(block empty:(py-0)) pb-3" id={portalId} className={className} />;
};

const titleAsFirstCompoentnStyle = tw`-mt-12 gp-md:mt-0`;

const itShouldFloatBehindStatusBarStyle = css`
    ${tw`mt-[calc(-48px - var(--safe-area-inset-top))] gp-md:mt-0`}
`;

export const Title: React.VFC<TitleProps> = p => {
    const {
        imageSize,
        titleStyle,
        image,
        subtitle,
        title,
        titleEmphasized,
        actions,
        isInMultipleColumnLayout,
        customCssClassName,
        headerImage,
        isFirstComponent,
        imageFill,
        appID,
    } = p;
    const itShouldFloatBehindStatusBar = useItShouldFloatBehindStatusBar(isFirstComponent);
    const builderItShouldFloatBehindStatusBar = useBuilderItShouldFloatBehindStatusBar(isFirstComponent);
    const isInsideOverlay = useIsInsideOverlay();
    if (titleStyle === UIWireTitleStyle.Image) {
        return (
            <WireContainer
                css={css`
                    &.first-component {
                        ${tw`-mt-12 gp-md:mt-6`}
                    }

                    .${DeviceFormFactor.Phone}
                        &.${builderShouldFloatBehindStatusBarClassName},
                        &.${shouldFloatBehindStatusBarClassName} {
                        ${itShouldFloatBehindStatusBarStyle}
                    }
                `}
                tw="px-0"
                className={classNames(
                    isFirstComponent && !isInsideOverlay && "first-component",
                    itShouldFloatBehindStatusBar && shouldFloatBehindStatusBarClassName,
                    builderItShouldFloatBehindStatusBar && builderShouldFloatBehindStatusBarClassName
                )}
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={customCssClassName}>
                <div tw="p-0 -mx-4 gp-sm:-mx-5 gp-md:mx-0">
                    <ImageTitle
                        actions={actions}
                        imageSize={imageSize}
                        image={image}
                        subtitle={subtitle}
                        title={title}
                        titleEmphasized={titleEmphasized}
                        isFirstComponent={isFirstComponent}
                        imageFill={imageFill}
                        appID={appID}
                    />
                </div>
            </WireContainer>
        );
    }

    if (titleStyle === UIWireTitleStyle.Cover) {
        return (
            <CoverTitle
                actions={actions}
                imageSize={imageSize}
                image={image}
                subtitle={subtitle}
                title={title}
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={customCssClassName}
                headerImage={headerImage}
                isFirstComponent={isFirstComponent}
                imageFill={imageFill}
                appID={appID}
            />
        );
    }

    if (titleStyle === UIWireTitleStyle.Profile) {
        return (
            <ProfileTitle
                actions={actions}
                imageSize={imageSize}
                image={image}
                subtitle={subtitle}
                title={title}
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={customCssClassName}
                headerImage={headerImage}
                isFirstComponent={isFirstComponent}
                imageFill={imageFill}
                appID={appID}
            />
        );
    }

    return (
        <WireContainer
            tw="px-0"
            isInMultipleColumnLayout={isInMultipleColumnLayout}
            customCssClassName={customCssClassName}>
            <SimpleTitle
                title={title}
                subtitle={subtitle}
                titleEmphasized={titleEmphasized}
                image={image}
                actions={actions}
                imageSize={imageSize}
                isFirstComponent={isFirstComponent}
                imageFill={imageFill}
                appID={appID}
            />
        </WireContainer>
    );
};

export const isSimpleTitle = (untypedComponent: WireComponent | null | undefined, isMobile: boolean): boolean => {
    if (untypedComponent?.kind === WireComponentKind.Hero) {
        const component = untypedComponent as WireTitleComponent;
        return component.titleStyle === UIWireTitleStyle.Simple;
    }

    if (untypedComponent?.kind === WireComponentKind.Container) {
        const containerComponents = nullifyUnnecesaryMobileBreadcrumb(
            (untypedComponent as WireContainerComponent).components,
            isMobile
        );
        return isSimpleTitle(containerComponents[0], isMobile);
    }

    return false;
};

const ShadowHeader: React.VFC = () => {
    return (
        <div tw="absolute inset-0 w-full h-[100px] bg-[linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0))] gp-md:hidden" />
    );
};
