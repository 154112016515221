import "twin.macro";

import { getGenerativeBrightColor, getGenerativeColor, getGenerativeMutedColor } from "@glide/common";
import type { WireRadialChartComponent, WireRadialChartData } from "@glide/fluent-components/dist/js/base-components";
import { useResponsiveSizeClass } from "@glide/common-components";
import type { ResponsiveSizeClass } from "@glide/wire";
import { UIStyleVariant } from "@glide/wire";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import { WireContainer } from "../wire-container/wire-container";
import {
    WireListContainer,
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
} from "../wire-list-container/wire-list-container";
import type { WireRenderer } from "../wire-renderer";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { isDarkAppTheme } from "../../utils/is-dark-theme";
import { ChartingColorScheme } from "@glide/fluent-components/dist/js/fluent-components";

const RadialChartLazy = React.lazy(() => import("./radial-chart-lazy"));

function getRadialChartHeight(size: ResponsiveSizeClass | undefined, width: number) {
    if (width < 200) {
        return 220;
    }

    if (size === undefined) {
        return 240;
    }

    if (size === "sm") {
        return 280;
    }

    return 360;
}

function getRadialChartOuterRadius(size: ResponsiveSizeClass | undefined, width: number) {
    if (width < 200) {
        return 85;
    }

    if (size === undefined) {
        return 95;
    }

    return 115;
}

interface RadialChartSize {
    outerRadius: number;
    height: number;
    ref: React.MutableRefObject<HTMLDivElement>;
}

function useRadialChartSize(): RadialChartSize {
    const size = useResponsiveSizeClass();

    // We also need the size cause in _very_ small containers the size class is not granular enough.
    const { ref, width: maybeWidth } = useResizeDetector();

    const width = maybeWidth ?? 0;
    const height = getRadialChartHeight(size, width);
    const outerRadius = getRadialChartOuterRadius(size, width);

    return {
        outerRadius,
        height,
        ref,
    };
}

export const WireRadialChart: WireRenderer<WireRadialChartComponent> = React.memo(p => {
    const { backend, graphTitle, searchBar, chartData, lineWeight, showLegend, showValuesInLegend, colorScheme } = p;

    const theme = useWireAppTheme();
    const isDark = isDarkAppTheme(theme);

    const getColor = React.useCallback(
        (i: number): string => {
            const color = chartData[i].color;
            if (color === "") {
                return theme.iconDisabled;
            }
            if (color !== undefined) {
                return color;
            }
            if (colorScheme === ChartingColorScheme.Bright) {
                return getGenerativeBrightColor(theme.accent, i, chartData.length, isDark);
            }
            if (colorScheme === ChartingColorScheme.Muted) {
                return getGenerativeMutedColor(theme.accent, i, chartData.length, isDark);
            }
            return getGenerativeColor(theme.accent, i, isDark);
        },
        [chartData, colorScheme, theme.accent, theme.iconDisabled, isDark]
    );

    const { outerRadius, height, ref } = useRadialChartSize();

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);

    return (
        <div tw="px-4 pb-4 rounded-xl border border-border-base bg-bg-front gp-sm:px-5 gp-md:px-6">
            <WireContainer isInMultipleColumnLayout={true} tw="bg-bg-front">
                <WireListContainer
                    ref={ref}
                    appKind={backend.appKind}
                    title={graphTitle ?? ""}
                    titleActions={[]}
                    {...filterArgs}
                    multipleFilterProps={multipleFilterProps}
                    styleVariant={UIStyleVariant.Default}
                    searchValue={searchValue}
                    onSearchChange={onSearchChange}
                    isFirstComponent={false}>
                    <React.Suspense fallback={<ChartLoadingFallback height={height} />}>
                        <RadialChartLazy
                            chartData={chartData}
                            lineWeight={lineWeight}
                            outerRadius={outerRadius}
                            height={height}
                            getColor={getColor}
                        />
                    </React.Suspense>
                    {showLegend && (
                        <Legend chartData={chartData} getColor={getColor} showValuesInLegend={showValuesInLegend} />
                    )}
                </WireListContainer>
            </WireContainer>
        </div>
    );
});

interface LegendProps {
    readonly chartData: WireRadialChartData[];
    readonly showValuesInLegend: boolean;
    readonly getColor: (index: number) => string;
}

export const Legend: React.FC<LegendProps> = p => {
    const { chartData, showValuesInLegend, getColor } = p;

    return (
        <div tw="flex flex-wrap gap-y-2 gap-x-4 justify-center mt-4 w-full">
            {chartData.map((item, idx) => {
                return (
                    <div key={`${item.label}-${idx}`} tw="flex items-baseline">
                        <div style={{ backgroundColor: getColor(idx) }} tw="mr-1 w-3 h-3 rounded translate-y-px" />
                        <span tw="text-xs text-text-pale">{item.label}</span>
                        {showValuesInLegend && <span tw="ml-1 text-xs font-medium text-text-pale">{item.display}</span>}
                    </div>
                );
            })}
        </div>
    );
};

interface ChartLoadingFallbackProps {
    height: number;
}

const ChartLoadingFallback: React.FC<ChartLoadingFallbackProps> = p => {
    const { height } = p;

    return <div style={{ height }} tw="w-full" />;
};
