import { assertNever, hasOwnProperty } from "@glideapps/ts-necessities";
import * as iots from "io-ts";
import { isLeft } from "fp-ts/lib/Either";
import type { NativeTableName } from "./table-name";
import {
    isTableNameForNativeTable,
    areTableNamesEqual,
    tableNameCodec,
    nativeTableNameCodec,
    type TableName,
    type UniversalTableName,
    makeTableName,
    isNativeTableName,
    isTableName,
} from "./table-name";
import { isArray, memoizeFunction } from "@glide/support";
import type { ExternalSource, NativeTableSourceMetadata, SourceMetadata } from "./source-metadata";
import {
    getSourceMetadataForNativeTable,
    isQueryableExternalTable,
    nativeTableSourceMetadata,
    getNativeTableSourceMetadata,
} from "./source-metadata";
import { isFavoritedColumnName, nativeTableRowIDColumnName, rowIndexColumnName } from "./column-names";

export interface Description {}

const stringGlideTypeKindRecord = {
    string: null,
    uri: null,
    "image-uri": null,
    "audio-uri": null,
    date: null,
    time: null,
    "date-time": null,
    markdown: null,
    "phone-number": null,
    "email-address": null,
    emoji: null,
    json: null,
};
export const stringGlideTypeKind = iots.keyof(stringGlideTypeKindRecord);
export type StringGlideTypeKind = iots.TypeOf<typeof stringGlideTypeKind>;

const nonStringPrimitiveGlideTypeKindRecord = {
    number: null,
    duration: null,
    boolean: null,
};
const primitiveGlideTypeKind = iots.union([stringGlideTypeKind, iots.keyof(nonStringPrimitiveGlideTypeKindRecord)]);
export type PrimitiveGlideTypeKind = iots.TypeOf<typeof primitiveGlideTypeKind>;

export const primitiveTypeKinds = [
    ...Object.keys(stringGlideTypeKindRecord),
    ...Object.keys(nonStringPrimitiveGlideTypeKindRecord),
] as readonly PrimitiveGlideTypeKind[];

type LinkTypeKind = "uri" | "image-uri" | "audio-uri";

export function isLinkTypeKind(kind: ColumnTypeKind | undefined): kind is LinkTypeKind {
    return kind !== undefined && ["uri", "image-uri", "audio-uri"].indexOf(kind) >= 0;
}

function isDateOrDateTimeTypeKindUntyped(kind: string): kind is "date" | "date-time" {
    return kind === "date" || kind === "date-time";
}

export function isDateOrDateTimeTypeKind(kind: ColumnTypeKind): kind is "date" | "date-time" {
    return isDateOrDateTimeTypeKindUntyped(kind);
}

export function isDateTimeTypeKindUntyped(kind: string): kind is "date" | "time" | "date-time" {
    return isDateOrDateTimeTypeKindUntyped(kind) || kind === "time";
}

export function isDateTimeTypeKind(kind: ColumnTypeKind): kind is "date" | "time" | "date-time" {
    return isDateTimeTypeKindUntyped(kind);
}

type StringyStringTypeKind = "string" | "markdown" | "phone-number" | "email-address" | "emoji" | LinkTypeKind;

// All "string types" except for date/time and JSON
export function isStringyStringTypeKind(kind: ColumnTypeKind): kind is StringyStringTypeKind {
    return ["string", "markdown", "phone-number", "email-address", "emoji"].indexOf(kind) >= 0 || isLinkTypeKind(kind);
}

export function isStringTypeKind(kind: ColumnTypeKind | undefined): kind is StringGlideTypeKind {
    if (kind === undefined) return false;
    if (isStringyStringTypeKind(kind) || isLinkTypeKind(kind)) return true;
    return ["date", "time", "date-time", "json"].indexOf(kind) >= 0;
}

export function isNumberTypeKind(t: ColumnTypeKind): t is "number" | "duration" {
    return t === "number" || t === "duration";
}

export function isPrimitiveTypeKind(kind: ColumnTypeKind): kind is PrimitiveGlideTypeKind {
    if (isStringTypeKind(kind)) return true;
    return ["number", "duration", "boolean"].indexOf(kind) >= 0;
}

export function isPrimitiveType(t: ColumnType | undefined): t is PrimitiveGlideType {
    if (t === undefined) return false;
    return isPrimitiveTypeKind(t.kind);
}

export function isPrimitiveArrayType(t: ColumnType): t is PrimitiveArrayColumnType {
    return t.kind === "array" && isPrimitiveType(t.items);
}

export function isPrimitiveOrPrimitiveArrayType(t: ColumnType): t is PrimitiveGlideType | PrimitiveArrayColumnType {
    return isPrimitiveType(t) || isPrimitiveArrayType(t);
}

export function isStringTypeOrStringTypeArray(t: ColumnType): boolean {
    return isStringTypeKind(t.kind) || (t.kind === "array" && isStringTypeKind(t.items.kind));
}

export function isSingleRelationType(t: ColumnType): t is UniversalTableRefGlideType {
    return t.kind === "table-ref";
}

export type MultiRelationType = ArrayColumnType & { items: TableRefGlideType };

export function isMultiRelationType(t: ColumnType): t is MultiRelationType {
    return t.kind === "array" && isSingleRelationType(t.items);
}

// This can't be a type assertion until TS fixes its bug.
// https://github.com/quicktype/glide/issues/7928
export function isRelationType(t: ColumnType): boolean {
    return isSingleRelationType(t) || isMultiRelationType(t);
}

export function decomposeRelationType(
    t: ColumnType
): { tableRef: UniversalTableRefGlideType; isMulti: boolean } | undefined {
    if (isSingleRelationType(t)) {
        return { tableRef: t, isMulti: false };
    } else if (isMultiRelationType(t)) {
        return { tableRef: t.items, isMulti: true };
    } else {
        return undefined;
    }
}

const primitiveGlideType = iots.type({
    kind: primitiveGlideTypeKind,
});
export type PrimitiveGlideType = iots.TypeOf<typeof primitiveGlideType>;

export const makePrimitiveType = memoizeFunction(
    "makePrimitiveType",
    (kind: PrimitiveGlideTypeKind): PrimitiveGlideType => ({
        kind,
    })
);

const tableRefGlideType = iots.intersection([
    iots.type({
        kind: iots.literal("table-ref"),
        tableName: iots.union([iots.string, tableNameCodec]),
    }),
    iots.partial({
        // FIXME: This is an odd place for the target column name in a reference.
        // It's not technically part of the type, and especially with the new
        // references we don't even need it anymore.  Once we get rid of the old
        // reference objects this should be removed.
        refColumnName: iots.string,
    }),
]);
export type TableRefGlideType = iots.TypeOf<typeof tableRefGlideType>;

const nativeTableRefGlideType = iots.intersection([
    iots.type({
        kind: iots.literal("table-ref"),
        tableName: nativeTableNameCodec,
    }),
    iots.partial({
        // For ease of use
        refColumnName: iots.undefined,
    }),
]);
type NativeTableRefGlideType = iots.TypeOf<typeof nativeTableRefGlideType>;

const universalTableRefGlideType = iots.union([tableRefGlideType, nativeTableRefGlideType]);
export type UniversalTableRefGlideType = iots.TypeOf<typeof universalTableRefGlideType>;

export function getTableRefTableName(nameOrRef: TableName | TableRefGlideType): TableName;
export function getTableRefTableName(nameOrRef: UniversalTableName | UniversalTableRefGlideType): UniversalTableName;
export function getTableRefTableName(nameOrRef: UniversalTableName | UniversalTableRefGlideType): UniversalTableName {
    if (hasOwnProperty(nameOrRef, "isSpecial")) {
        return nameOrRef;
    }

    const { tableName } = nameOrRef;
    if (typeof tableName === "string") {
        return makeTableName(tableName);
    }
    return tableName;
}

const arrayItemType = iots.union([primitiveGlideType, universalTableRefGlideType]);
export type ArrayItemType = iots.TypeOf<typeof arrayItemType>;

const arrayColumnType = iots.intersection([
    iots.type({
        kind: iots.literal("array"),
        items: arrayItemType,
    }),
    iots.partial({
        itemColumnNames: iots.readonlyArray(iots.string),
    }),
]);

export type ArrayColumnType = iots.TypeOf<typeof arrayColumnType>;

export type PrimitiveArrayColumnType = ArrayColumnType & { readonly items: PrimitiveGlideType };

export function makeArrayType(items: ArrayItemType, itemColumnNames?: readonly string[]): ArrayColumnType {
    const t = { kind: "array", items } as const;
    if (itemColumnNames === undefined) {
        return t;
    }
    return { ...t, itemColumnNames };
}

export type ColumnType = PrimitiveGlideType | ArrayColumnType | UniversalTableRefGlideType;

const columnType: iots.Type<ColumnType> = iots.union([primitiveGlideType, arrayColumnType, universalTableRefGlideType]);

export type ColumnTypeKind = PrimitiveGlideTypeKind | "array" | "table-ref";

export enum FormulaKind {
    GetTableRows = "get-table-rows",
    FilterRows = "filter-rows",
    MapRows = "map-rows",
    Reduce = "reduce",
    ReduceToMemberBy = "reduce-to-member-by",
    JoinStrings = "join-strings",
    SplitString = "split-string",
    FindRow = "find-row",
    IfThenElse = "if-then-else",
    CheckValue = "check-value",
    CompareValues = "compare-values",
    Constant = "constant",
    Empty = "empty",
    GetContext = "get-context",
    GetColumn = "get-column",
    GetUserProfileRow = "get-user-profile-row",
    GetActionNodeOutput = "get-action-node-output",
    ApplyColumnFormat = "apply-column-format",
    TextTemplate = "text-template",
    ArrayContains = "array-contains",
    ArraysOverlap = "arrays-overlap",
    MakeArray = "make-array",
    And = "and",
    Or = "or",
    Not = "not",
    SpecialValue = "special-value",
    ConvertToType = "convert-to-type",
    StartOfDay = "start-of-day",
    EndOfDay = "end-of-day",
    With = "with",
    GetNth = "get-nth",
    GetNthLast = "get-nth-last",
    RandomPick = "random-pick",
    IsInRange = "is-in-range",
    AssignVariables = "assign-variables",
    GetVariable = "get-variable",
    Random = "random",
    UnaryMath = "unary-math",
    BinaryMath = "binary-math",
    WithUserEnteredText = "with-user-entered-text",
    FormatNumberFixed = "format-number-fixed",
    FormatDateTime = "format-date-time",
    FormatDuration = "format-duration",
    CurrentLocation = "current-location",
    GeoDistance = "geo-distance",
    GeocodeAddress = "geocode-address",
    GenerateImage = "generate-image",
    UserAPIFetch = "user-api-fetch",
    ConstructURL = "construct-url",
    YesCode = "yes-code",
    PluginComputation = "plugin-computation",
    FilterSortLimit = "filter-sort-limit",
    FormatJSON = "format-json",
}

export interface Formula {
    readonly kind: FormulaKind;
}

// FIXME: implement validation
const formulaCodec = new iots.Type<Formula>(
    "Formula",
    (_u): _u is Formula => true,
    i => iots.success(i as Formula),
    a => a
);

const tableColumnIdentityCodec = iots.intersection([
    iots.type({ name: iots.string }),
    iots.partial({ displayName: iots.string }),
]);

export type TableColumnIdentity = iots.TypeOf<typeof tableColumnIdentityCodec>;

export interface TableColumnLike extends TableColumnIdentity {
    readonly type: ColumnType;
    readonly displayFormula?: Formula;
}

interface TableColumnBrand {
    readonly __brand: unique symbol;
}

export interface TableColumn extends TableColumnLike, TableColumnBrand {
    // Hidden columns are not shown in column selectors for component
    // properties. As of now, the row index is hidden, as well as the user
    // specific favorite flag.  We also mark protected columns as hidden, but
    // we still show them in the Data Editor.
    readonly hidden?: boolean;
    // Protected columns are not mirrored by Glide.  We also mark them as
    // hidden. Only sheet columns can be protected.
    readonly isProtected?: boolean;
    readonly isUserSpecific?: boolean;
    readonly formula?: Formula;
    // Columns that are copied from native tables.  Note that this might not
    // be set for data columns, but it must be set for copied computed
    // columns.
    readonly fromNativeTable?: boolean;
    // Columns that are copied from external sources.  Note that this might
    // not be set for data columns, but it must be set for copied computed
    // columns.
    readonly fromExternalSource?: boolean;
    // ##readOnlyData:
    // Defaults to `false`.  Computed columns are implicitly read-only and
    // might not have this flag set.  We use read-only columns when importing
    // from external sources that expose computed columns on their end.
    readonly isReadOnly?: boolean;
}

const tableColumnCodecWithoutBrand: iots.Type<Omit<TableColumn, "__brand">> = iots.intersection([
    tableColumnIdentityCodec,
    iots.type({
        type: columnType,
    }),
    iots.partial({
        hidden: iots.boolean,
        isProtected: iots.boolean,
        isUserSpecific: iots.boolean,
        formula: formulaCodec,
        displayFormula: formulaCodec,
        fromNativeTable: iots.boolean,
    }),
]);
export const tableColumnCodec = tableColumnCodecWithoutBrand as unknown as iots.Type<TableColumn>;

export function isComputedColumn(c: TableColumn): c is TableColumn & { readonly formula: Formula } {
    return c.formula !== undefined;
}

export function isUserAgnosticDataColumn(c: TableColumn): boolean {
    if (isComputedColumn(c)) return false;
    if (c.isUserSpecific === true) return false;
    return true;
}

export function isDataSourceColumn(c: TableColumn, includeUserSpecific: boolean): boolean {
    if (isComputedColumn(c)) return false;
    if (c.isUserSpecific === true && !includeUserSpecific) return false;
    return true;
}

export function getTableColumnDisplayName(c: TableColumnLike): string {
    return c.displayName ?? c.name;
}

export function canBeRowIDColumn(tableOrColumns: TableGlideType | readonly TableColumn[], columnName: string): boolean {
    const column = getTableColumn(tableOrColumns, columnName);
    if (column === undefined) return false;
    return isUserAgnosticDataColumn(column) && isPrimitiveType(column.type);
}

export interface TableGlideType {
    readonly name: string | TableName;
    readonly columns: readonly TableColumn[];

    readonly sheetID?: number;
    readonly sheetName?: string;
    // This might not be accurate.  It's mainly used on refresh to
    // determine whether to make a class screen vs array screen, when
    // there is exactly one data row.
    readonly numDataRows?: number;
    // This being an optional parameter was an underlying cause of a
    // security breach. As such, we can no longer afford it being optional.
    readonly emailOwnersColumn: string | readonly string[] | undefined;
    readonly rowIDColumn?: string;
    readonly sourceMetadata?: NativeTableSourceMetadata;
    // Defaults to `false`.  External sources with ##readOnlyData will have
    // this set.  It means that rows can't be added or deleted, and that all
    // columns are implicitly read-only.  Use `isTableWritable` to access.
    readonly isReadOnly?: boolean;
}

// FIXME: It completely sucks that this exists independent of TableGlideType.
// This needs to _be_ TableGlideType.
export interface TableGlideTypeWithUniversalName {
    readonly name: string | UniversalTableName;
    readonly columns: readonly TableColumn[];

    readonly sheetName?: string;
    readonly rowIDColumn?: string;
    readonly isReadOnly?: boolean;
    // FIXME: Add sourceMetadata at some point
}

const tableGlideTypeOptionalCodec = iots.partial({
    sheetID: iots.number,
    sheetName: iots.string,
    numDataRows: iots.number,
    emailOwnersColumn: iots.union([iots.string, iots.readonlyArray(iots.string), iots.undefined]),
    rowIDColumn: iots.string,
    sourceMetadata: nativeTableSourceMetadata,
    isReadOnly: iots.boolean,
});

// This nasty type trickery is necessitated by "emailOwnersColumn" being mandatory in the
// proper type. We can't do that in the codec, though, because this is used to decode JSON
// responses, which necessarily can't embed mandatory `undefined` values.
export const tableGlideTypeCodec: iots.Type<
    Omit<TableGlideType, "emailOwnersColumn"> & Partial<Pick<TableGlideType, "emailOwnersColumn">>
> = iots.intersection([
    iots.type({
        name: iots.union([iots.string, tableNameCodec]),
        columns: iots.readonlyArray(tableColumnCodec),
    }),
    tableGlideTypeOptionalCodec,
]);

// This pothole-fill is necessitated by "emailOwnersColumn" being non-optional
// in TableGlideType, but necessarily optional in tableGlideTypeCodec.
export function tableGlideTypeCodecTableToTableGlideType(i: iots.TypeOf<typeof tableGlideTypeCodec>): TableGlideType {
    return { emailOwnersColumn: undefined, ...i };
}

export function isTableWithRegularName(table: TableGlideTypeWithUniversalName): table is TableGlideType {
    return !isNativeTableName(table.name);
}

export interface TableOrColumnsAndColumn {
    readonly table: readonly TableColumn[] | TableGlideType;
    readonly column: TableColumn;
}

export interface TableAndColumn extends TableOrColumnsAndColumn {
    readonly table: TableGlideType;
}

export function isNativeTable(sourceMetadata: readonly SourceMetadata[] | undefined, table: TableGlideType): boolean {
    // We're trying to move to having the metadata on the `TableGlideType`, so
    // let's start there.  `sourceMetadata` in the app description will
    // eventually go away.
    if (table.sourceMetadata?.type === "Native table") {
        return true;
    }

    const tableName = getTableName(table);

    if (isTableNameForNativeTable(tableName)) {
        return true;
    }

    if (
        sourceMetadata !== undefined &&
        getNativeTableSourceMetadata(sourceMetadata, getTableName(table)) !== undefined
    ) {
        return true;
    }

    return false;
}

export function getEmailOwnersColumnNames(table: TableGlideType, expandArray: boolean = false): readonly string[] {
    const { emailOwnersColumn } = table;
    if (emailOwnersColumn === undefined) return [];
    let columnNames: readonly string[];
    if (typeof emailOwnersColumn === "string") {
        columnNames = [emailOwnersColumn];
    } else {
        columnNames = emailOwnersColumn;
    }
    if (!expandArray) return columnNames;
    const expanded: string[] = [];
    for (const n of columnNames) {
        const c = getTableColumn(table, n);
        if (c === undefined) continue;
        if (c.type.kind === "array" && c.type.itemColumnNames !== undefined) {
            expanded.push(...c.type.itemColumnNames);
        }
    }
    return [...columnNames, ...expanded];
}

export function tableHasRowOwners(table: TableGlideType): boolean {
    return getEmailOwnersColumnNames(table).length > 0;
}

export function sheetNameForTable(table: TableGlideType): string {
    return table.sheetName ?? getTableName(table).name;
}

// The following two items need to be kept in sync.
// We'd say
//   type TypeSchema = iots.TypeOf<typeof typeSchemaCodec>
// but then TypeSchema["tables"] would be mutable, both in terms of
// the property and the underlying array.
export interface TypeSchema {
    readonly tables: ReadonlyArray<TableGlideType>;
}

export const typeSchemaCodec = iots.type({
    tables: iots.readonlyArray(tableGlideTypeCodec),
});

export function makeTypeSchema(tables: readonly TableGlideType[]): TypeSchema {
    return { tables };
}

export function decodeTypeSchema(x: unknown): TypeSchema | undefined {
    const decoded = typeSchemaCodec.decode(x);
    return isLeft(decoded)
        ? undefined
        : makeTypeSchema(decoded.right.tables.map(tableGlideTypeCodecTableToTableGlideType));
}

export function isolateTypeSchema(schema: TypeSchema): TypeSchema {
    return makeTypeSchema(schema.tables);
}

export function getTableName(tableOrTableName: TableGlideType | TableName): TableName {
    if (hasOwnProperty(tableOrTableName, "columns")) {
        return makeTableName(tableOrTableName.name);
    } else {
        return tableOrTableName;
    }
}

// These belong here because computed columns can use them
export enum SpecialValueKind {
    Timestamp = "timestamp",
    VerifiedEmailAddress = "verified-email-address",
    RealEmailAddress = "real-email-address",
    // This is not exposed in Glide (yet)
    UserName = "user-name",
    UniqueIdentifier = "unique-identifier",
    ActionSource = "action-source",
    CurrentURL = "current-url",
    ClearColumn = "clear-column",
}

export const pluginSpecialValueDescriptionCodec = iots.type({
    pluginID: iots.string,
    computationID: iots.string,
    resultName: iots.string,
    // This duplicates the type information from the plugin metadata.  There
    // are cases where don't want to drag the whole plugin metadata in just to
    // determine what type a computed column is.  We do the same thing with
    // plugin computations.  It's not pretty, but plumbing the plugin metadata
    // through everywhere isn't either.
    type: primitiveGlideType,
});
export type PluginSpecialValueDescription = iots.TypeOf<typeof pluginSpecialValueDescriptionCodec>;

export type SpecialValueDescription = SpecialValueKind | PluginSpecialValueDescription;

export function areSpecialValuesEqual(sv1: SpecialValueDescription, sv2: SpecialValueDescription): boolean {
    if (sv1 === sv2) return true;
    if (typeof sv1 === "string" || typeof sv2 === "string") return false;
    return (
        sv1.pluginID === sv2.pluginID && sv1.computationID === sv2.computationID && sv1.resultName === sv2.resultName
    );
}

export function makeSpecialValueUniqueID(sv: SpecialValueDescription): string {
    if (typeof sv === "string") return sv;
    return `${sv.pluginID}:${sv.computationID}:${sv.resultName}`;
}

export const specialValueTypeKinds: {
    [k in SpecialValueKind]: PrimitiveGlideTypeKind;
} = {
    [SpecialValueKind.Timestamp]: "date-time",
    [SpecialValueKind.VerifiedEmailAddress]: "email-address",
    [SpecialValueKind.RealEmailAddress]: "email-address",
    [SpecialValueKind.UserName]: "string",
    [SpecialValueKind.UniqueIdentifier]: "string",
    [SpecialValueKind.ActionSource]: "string",
    [SpecialValueKind.CurrentURL]: "uri",
    [SpecialValueKind.ClearColumn]: "string",
};

export function makeTableRef(tableOrTableName: TableGlideType | TableName): TableRefGlideType {
    return { kind: "table-ref", tableName: getTableName(tableOrTableName) };
}

function makeNativeTableRef(tableName: NativeTableName): NativeTableRefGlideType {
    return { kind: "table-ref", tableName };
}

export function makeUniversalTableRef(tableName: UniversalTableName): UniversalTableRefGlideType {
    if (isTableName(tableName)) {
        return makeTableRef(tableName);
    } else if (isNativeTableName(tableName)) {
        return makeNativeTableRef(tableName);
    } else {
        return assertNever(tableName);
    }
}

export type TableOrColumns = TableGlideType | readonly TableColumn[];

function getColumns(tableOrColumns: TableOrColumns): readonly TableColumn[] {
    return isArray(tableOrColumns) ? tableOrColumns : tableOrColumns.columns;
}

// We've seen apps with >10k columns, for which this speeds up lookup
// dramatically.
const getColumnMapForTable = memoizeFunction("getColumnMapForTable", (t: TableOrColumns) => {
    const map = new Map<string, TableColumn>();
    for (const c of getColumns(t)) {
        map.set(c.name, c);
    }
    return map;
});

export function getTableColumn(tableOrColumns: TableOrColumns, name: string): TableColumn | undefined {
    return getColumnMapForTable(tableOrColumns).get(name);
}

export function maybeGetTableColumn(tableOrColumns: TableOrColumns, name: string | undefined): TableColumn | undefined {
    if (name === undefined) return undefined;
    return getTableColumn(tableOrColumns, name);
}

export function isColumnNonHidden(c: TableColumn, allowProtected: boolean = true): boolean {
    return c.hidden !== true && (allowProtected || c.isProtected !== true);
}

export function getNonHiddenColumns(
    tableOrColumns: TableOrColumns,
    allowProtected: boolean = true
): readonly TableColumn[] {
    return getColumns(tableOrColumns).filter(c => isColumnNonHidden(c, allowProtected));
}

interface IsColumnWritableOptions {
    readonly allowProtected?: boolean; // defaults to `true`
    readonly allowHidden?: boolean; // defaults to `false`
    // Only allow primitives, or also allow arrays of primitives?
    readonly allowArrays?: boolean; // default to `false`
}

function isExternalSourceWritable(externalSource: ExternalSource): boolean {
    return externalSource.type !== "bigquery";
}

export function isTableWritable(table: TableGlideType): boolean {
    if (table.isReadOnly === true) return false;
    if (table.sourceMetadata?.externalSource !== undefined) {
        // We have some BigQuery tables that are not marked as readonly for
        // some reason, maybe historical.
        // https://github.com/quicktype/glide/issues/18914
        if (!isExternalSourceWritable(table.sourceMetadata.externalSource)) return false;
    }
    return true;
}

export function isColumnWritable(
    c: TableColumn,
    table: TableGlideType,
    forAddingRow: boolean,
    { allowProtected = true, allowHidden = false, allowArrays = false }: IsColumnWritableOptions = {}
): boolean {
    const isNative = isNativeTable(undefined, table);
    // We can only add new rows with user-specific columns if we're writing to
    // a native table.  Technically that's too tight a restriction - all we
    // need is a row ID, and we could support it for Google Sheets, too.
    const allowUserSpecific = !forAddingRow || isNative;
    if (!isPrimitiveType(c.type)) {
        // The only non-primitive columns we allow are arrays of primitives.
        if (!isPrimitiveArrayType(c.type)) return false;
        if (!allowArrays) return false;
        // Sheet-data arrays can only ever be writable in native tables, not
        // in Google Sheets.
        if (!isNative && c.isUserSpecific !== true) return false;
    }

    const canWriteToRowID =
        isQueryableExternalTable(table) && forAddingRow && table.rowIDColumn !== nativeTableRowIDColumnName;

    return (
        isTableWritable(table) &&
        c.isReadOnly !== true &&
        (allowHidden || isColumnNonHidden(c)) &&
        (allowUserSpecific || c.isUserSpecific !== true) &&
        (allowProtected || c.isProtected !== true) &&
        !isComputedColumn(c) &&
        c.name !== rowIndexColumnName &&
        (canWriteToRowID || c.name !== table.rowIDColumn)
    );
}

export function getPrimitiveColumns(t: TableGlideTypeWithUniversalName): ReadonlyArray<TableColumn> {
    return t.columns.filter(c => isPrimitiveType(c.type));
}

export function getPrimitiveNonHiddenColumns(t: TableGlideTypeWithUniversalName): ReadonlyArray<TableColumn> {
    return getNonHiddenColumns(getPrimitiveColumns(t));
}

export function isPrimitiveNonComputedNonHiddenColumn(c: TableColumn): boolean {
    return !isComputedColumn(c) && isColumnNonHidden(c) && c.name !== isFavoritedColumnName;
}

export function getPrimitiveNonComputedNonHiddenColumns(t: TableGlideType): ReadonlyArray<TableColumn> {
    return getPrimitiveNonHiddenColumns(t).filter(isPrimitiveNonComputedNonHiddenColumn);
}

export function getCompoundColumns(t: TableGlideType): readonly TableColumn[] {
    return t.columns.filter(c => !isPrimitiveType(c.type));
}

// If `forWriting` is `true` then only tables with writable primitives
// columns are returned.
export function getTablesWithPrimitiveColumns(
    tables: ReadonlyArray<TableGlideType>,
    forWriting: boolean,
    // We only care about this if `forWriting === true`
    forAddingRow: boolean
): readonly TableGlideType[] {
    return tables.filter(t => {
        if (getTableName(t).isSpecial) return false;
        if (forWriting && !isTableWritable(t)) return false;
        return t.columns.some(c => isPrimitiveType(c.type) && (forWriting || isColumnWritable(c, t, forAddingRow)));
    });
}

export function getStringTypeOrStringTypeArrayColumns(table: TableGlideType): readonly TableColumn[] {
    return table.columns.filter(c => isStringTypeOrStringTypeArray(c.type));
}

export function getPrimitiveOrPrimitiveArrayNonHiddenColumns(t: TableGlideType): readonly TableColumn[] {
    return t.columns.filter(c => c.hidden !== true && isPrimitiveOrPrimitiveArrayType(c.type));
}

export function getBackendCompatiblePrimitiveColumns(table: TableGlideType): readonly TableColumn[] {
    return getPrimitiveNonHiddenColumns(table).filter(c => !isComputedColumn(c));
}

export function getAllowedTablesForAddRow(schema: TypeSchema): ReadonlyArray<TableGlideType> {
    return getTablesWithPrimitiveColumns(schema.tables, true, true);
}

export function findTable(
    schemaOrTables: TypeSchema | readonly TableGlideType[],
    nameOrRef: TableName | TableRefGlideType | undefined
): TableGlideType | undefined;
export function findTable(
    schemaOrTables: TypeSchema | readonly TableGlideType[],
    nameOrRef: UniversalTableName | UniversalTableRefGlideType | undefined,
    sourceMetadata: readonly SourceMetadata[] | undefined
): TableGlideType | undefined;
export function findTable(
    schemaOrTables: TypeSchema | readonly TableGlideType[],
    nameOrRef: UniversalTableName | UniversalTableRefGlideType | undefined,
    sourceMetadata?: readonly SourceMetadata[] | undefined
): TableGlideType | undefined {
    if (nameOrRef === undefined) return undefined;
    let name = getTableRefTableName(nameOrRef);
    if (isNativeTableName(name)) {
        if (sourceMetadata === undefined) return undefined;
        const sm = getSourceMetadataForNativeTable(sourceMetadata, name.nativeTableID);
        if (sm === undefined) return undefined;
        name = sm.tableName;
    }
    const tables = isArray(schemaOrTables) ? schemaOrTables : schemaOrTables.tables;
    return tables.find(t => areTableNamesEqual(name, getTableName(t)));
}
