import { documentIDColumnName } from "@glide/common-core/dist/js/Database";
import { commentsTableName } from "@glide/common-core/dist/js/database-strings";
import { type TableGlideType, makeTableColumn } from "@glide/type-schema";

export const commentsTable: TableGlideType = {
    name: commentsTableName,
    columns: [
        makeTableColumn(documentIDColumnName, "string", { hidden: true, isProtected: false }),
        makeTableColumn("topic", "string", { isProtected: false }),
        makeTableColumn("email", "string", { isProtected: false }),
        makeTableColumn("username", "string", { isProtected: false }),
        makeTableColumn("dateTime", "date-time", { isProtected: false }),
        makeTableColumn("comment", "string", { isProtected: false }),
    ],
    emailOwnersColumn: undefined,
};
