import { getGlideIcon } from "@glide/common";
import { AppKind } from "@glide/location-common";
import { getLocalizedString } from "@glide/localization";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    ArrayScreenFormat,
    ArrayTransformKind,
    PropertyKind,
    getArrayProperty,
    getEnumProperty,
    getSourceColumnProperty,
    getStringProperty,
    getSwitchProperty,
    getTableProperty,
    makeActionProperty,
    makeArrayProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeIconProperty,
    makeNumberProperty,
    makeStringProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import {
    type Description,
    type PrimitiveArrayColumnType,
    type PrimitiveGlideType,
    getTableColumnDisplayName,
    getTableName,
    isColumnWritable,
    isDateOrDateTimeTypeKind,
    isNumberTypeKind,
    isPrimitiveArrayType,
    isPrimitiveType,
} from "@glide/type-schema";
import {
    type InputOutputTables,
    ContainerAlignment,
    ContainerLayout,
    makeEmptyComponentDescription,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import {
    type NewDataGridColumn,
    type NewDataGridColumnKind,
    type SuperTableColumn,
    type SuperTableColumnKind,
    CalendarDefaultDate,
    GroupingSupport,
    TextComponentStyle,
    moodCases,
} from "@glide/component-utils";
import {
    type AppDescriptionContext,
    type EnumPropertyCase,
    type InlineListComponentDescription,
    type InteractiveComponentConfiguratorContext,
    type PropertyTableGetter,
    PropertyConfiguratorKind,
    ArrayPropertyStyle,
    ColumnPropertyFlag,
    PropertySection,
    QueryableTableSupport,
    dynamsoftScannerNumUpdates,
    findTitleColumnForTable,
    getColumnForSourceColumn,
    getInlineListPropertyTable,
    getTableAndColumnForSourceColumn,
    hasCustomKeyForDynamsoftScanner,
    makeDynamicFilterColumnPropertyHandler,
    mustUseDynamsoftScanner,
    promoteAppDescriptionContext,
    resolveSourceColumn,
    titleProperties,
} from "@glide/function-utils";
import { assertNever, hasOwnProperty } from "@glideapps/ts-necessities";
import { audioProperties, isDefined, isEmptyOrUndefined } from "@glide/support";
import {
    PageScreenTarget,
    WireComponentKind,
    BarChartType,
    BarChartWeights,
    ButtonBarPrimaryLocation,
    ButtonBarSize,
    ButtonLabelsVisibility,
    CardStyle,
    ChartType,
    CustomComponentSource,
    CustomComponentSourceTransform,
    CustomComponentWidth,
    LinkStyle,
    RadialChartWeights,
    SeparatorSize,
    UIAlignment,
    UIAspect,
    UIBUttonStyle,
    UIBackgroundStyle,
    UIButtonAppearance,
    UIImageFill,
    UIImageSize,
    UIImageStyle,
    UIMapAspect,
    UIOrientation,
    UISize,
    UIStyleVariant,
    UITitleStyle,
    UIWireTitleStyle,
    UILayoutVariant,
    DataPlotType,
} from "@glide/wire";
import { definedMap } from "collection-utils";

import {
    type WireDataGridColumnKind,
    CommentsStyle,
    getAllowedChoiceTables,
    getColumnLinkTargetFromValueProperty,
    getTargetForLink,
} from "./base-components";
import { type EasyCRUDFlags, addConditionToActionWithTitleAndIcon } from "./easy-crud";
import { Container, FormContainer } from "./fluent-components-containers";
import {
    type DescriptionOfArrayContent,
    type DescriptionOfArrayContentInlineList,
    type DescriptionOfComponent,
    type WirePropertiesOfArrayContent,
    type WirePropertiesOfComponent,
    arrayContent,
    component,
    form,
    properties,
    propertiesWithCSS,
} from "./fluent-components-spec";
import type { DescriptionOfProperties } from "./fluent-properties-spec";
import { OwnerKind } from "@glide/common-core/dist/js/Database/owner-kind";
import isString from "lodash/isString";
import isBoolean from "lodash/isBoolean";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";

// ##actionWithTitlePropertyOrder
/**
 * Changing the order of these properties can break `makeCaseConfiguration` in
 * `make-property-config`
 */
function makeActionWithTitleProperties<TRoot>() {
    return properties
        .withRoot<TRoot>()
        .withCaption("title", "Go!", {
            emptyWarningText: "Leave empty only if the icon's meaning is obvious",
        })
        .withIcon("icon", { displayName: "Icon" })
        .withAction("action", { required: false, defaultAction: true, showIfCollectionEmpty: true });
}

function isPropertyBoundToAnArray(
    property: PropertyDescription | undefined,
    tables: InputOutputTables | undefined,
    adc: AppDescriptionContext
): boolean {
    const sc = getSourceColumnProperty(property);
    if (sc === undefined) {
        return false;
    }

    const tac = resolveSourceColumn(adc, sc, tables?.input, undefined, undefined)?.tableAndColumn;
    if (tac === undefined) {
        return false;
    }

    return isPrimitiveArrayType(tac.column.type);
}

export type ActionWithTitleDescription = DescriptionOfProperties<ReturnType<typeof makeActionWithTitleProperties>>;

export function makeActionWithTitle(
    title: string | PropertyDescription | undefined,
    action: ActionDescription | undefined,
    iconName?: string
): ActionWithTitleDescription {
    return {
        title: title === undefined ? undefined : typeof title === "string" ? makeStringProperty(title) : title,
        action: definedMap(action, makeActionProperty),
        icon: definedMap(iconName, i => makeIconProperty(i)),
    } as any;
}

// When there's no action we remove the title as well so we don't render a
// disabled button.
const removeIfMissingInActionWithTitle = { action: ["title"] } as const;

const primarySecondaryAddActionLabels = ["Add primary action", "Add secondary action", "Add dropdown action"];

const titleActionsOptions = {
    propertySection: PropertySection.TitleBarAction,
    style: ArrayPropertyStyle.ActionArray,
    specialItems: 2,
    allowEmpty: true,
    removeIfMissing: removeIfMissingInActionWithTitle,
    addItemLabels: primarySecondaryAddActionLabels,
    disableForEasyCRUD: true,
};

const itemActionsOptions = {
    propertySection: PropertySection.CollectionItemActions,
    style: ArrayPropertyStyle.ActionArray,
    allowEmpty: true,
    removeIfMissing: removeIfMissingInActionWithTitle,
    subcomponent: {
        important: true,
        name: "Actions",
    },
};

function makeQuickLookAction(
    iccc: InteractiveComponentConfiguratorContext,
    itemTables: InputOutputTables,
    mutatingScreenKind: MutatingScreenKind | undefined
): ActionDescription | undefined {
    const action = iccc.makeAction(
        ActionKind.PushDetailScreen,
        itemTables,
        // TODO: Does this even apply here, since it's an action in the inline list?
        mutatingScreenKind
    );
    if (action === undefined) return undefined;

    return {
        ...action,
        navigationTarget: makeEnumProperty(PageScreenTarget.LargeModal),
    } as ActionDescription;
}

function makeEditAction(
    iccc: InteractiveComponentConfiguratorContext,
    itemTables: InputOutputTables,
    mutatingScreenKind: MutatingScreenKind | undefined
): ActionDescription | undefined {
    const action = iccc.makeAction(
        ActionKind.PushEditScreen,
        itemTables,
        // TODO: Does this even apply here, since it's an action in the inline list?
        mutatingScreenKind
    );
    if (action === undefined) return undefined;

    return {
        ...action,
        navigationTarget: makeEnumProperty(PageScreenTarget.LargeModal),
    } as ActionDescription;
}

// In components where we implement the "Add" CRUD action via a title action,
// we have the problem that lowering alone can't do the job, because there's a
// form screen involved.  If we left it all to lowering, then that would have
// to create a new form screen every time the lowering happens, which would
// also mean that the screen would have no persistence, i.e. the user would
// change the form screen, but next time it would be back to its default.
//
// What we do to solve this is to set the title action to a form screen screen
// action, and when we're lowering we just use that action if "Add" is
// enabled.  All we need to do is add the condition and title from the CRUD
// flag to it.  The `updateSource` method in the CRUD handler will set this
// action when switching to CRUD, or when changing the source.
//
// That leaves two special cases to handle here:
//
// * If CRUD is disabled, we have to remove the action.
// * Except when we're running the ##screenGarbageCollection, because that
//   would leave us with a dangling screen.
function makeUpdateForEasyCRUDTitleAction<T extends { readonly titleActions?: PropertyDescription }>(
    desc: T,
    flags: EasyCRUDFlags,
    forGC: boolean
): { titleActions?: PropertyDescription | undefined } {
    const titleActionsArray = getArrayProperty<ActionWithTitleDescription>(desc.titleActions);
    const titleActionWithTitle = titleActionsArray?.length === 1 ? titleActionsArray[0] : undefined;
    const addAction = addConditionToActionWithTitleAndIcon(
        titleActionWithTitle,
        flags.add,
        getGlideIcon("st-plus-add")
    );
    if (addAction === undefined && !forGC) {
        // Remove the action because it's disabled.
        return { titleActions: undefined };
    } else if (addAction !== undefined) {
        return { titleActions: makeArrayProperty([addAction]) };
    } else {
        // The remaining case is that we're doing this for the GC, in
        // which case we just leave the existing action.
        return {};
    }
}

const Button = component(WireComponentKind.Button)
    .withIcon("mt-component-button")
    .withDisplayName("Button")
    .withPrompt("Button is for triggering actions (e.g. open edit form, navigate, other custom actions)")
    .withDocURL("pagesButton")
    .withLegacy()
    .withProperties(
        propertiesWithCSS
            .withText("title", {
                defaultValue: "Go!",
                useTemplate: "withLabel",
                description: "The text label displayed on the button.",
            })
            .withAction("action", {
                required: true,
                description: "The action executed when the button is clicked.",
            })
            .withEnum(
                "appearance",
                [
                    {
                        value: UIButtonAppearance.Filled,
                        label: "Accent",
                    },
                    {
                        value: UIButtonAppearance.Bordered,
                        label: "Default",
                    },
                ],
                {
                    propertySection: PropertySection.Design,
                    displayName: "Style",
                    defaultValue: UIButtonAppearance.Bordered,
                    description: "Visual style of the button (accented or default outline).",
                }
            )
    );
export type WireButtonComponent = WirePropertiesOfComponent<typeof Button>;
export type WireButtonDescription = DescriptionOfComponent<typeof Button>;

const Hint = component(WireComponentKind.Hint)
    .withIcon("mt-component-hint")
    .withDisplayName("Hint")
    .withDescription("A callout area to write important text")
    .withPrompt("Hint is for displaying contextual help (e.g. instructions, tips, additional information)")
    .withDocURL("pagesHint")
    .withProperties(
        propertiesWithCSS
            .withText("description", {
                defaultValue: "This is a hint.",
                useTemplate: "withoutLabel",
                propertySection: PropertySection.Content,
                description: "The main text content displayed within the hint.",
            })
            .withCaption("actionTitle", "Go!", {
                displayName: "Title",
                propertySection: PropertySection.ActionAction,
                useTemplate: "withLabel",
                description: "The text label for the optional action button within the hint.",
            })
            .withIcon("actionIcon", {
                displayName: "Icon",
                propertySection: PropertySection.ActionAction,
                description: "The icon displayed on the optional action button.",
            })
            .withAction("action", {
                description: "The action performed when the optional button in the hint is clicked.",
            })
            .withEnum("mood", moodCases, {
                description: "The visual style/color scheme of the hint (e.g., informational, warning).",
            })
            .withIcon("icon", {
                displayName: "Icon",
                propertySection: PropertySection.Options,
                description: "An optional icon displayed to the left of the hint text.",
            })
    );
export type WireHintComponent = WirePropertiesOfComponent<typeof Hint>;
export type WireHintDescription = DescriptionOfComponent<typeof Hint>;

const Text = component(WireComponentKind.Text)
    .withIcon("co-basic-text")
    .withDisplayName("Text")
    .withDescription("A basic text component")
    .withPrompt("Text is for displaying text (e.g. a message, a question, a title)")
    .withProperties(
        propertiesWithCSS
            .withText("text", {
                defaultValue: "This is a text.",
                description: "The text to display.",
                useTemplate: "withoutLabel",
                propertySection: PropertySection.Content,
            })
            .withEnum(
                "style",
                [
                    { value: TextComponentStyle.large, label: "Large" },
                    { value: TextComponentStyle.regular, label: "Regular" },
                    { value: TextComponentStyle.small, label: "Small" },
                    { value: TextComponentStyle.footnote, label: "Footnote" },
                    { value: TextComponentStyle.metaText, label: "Meta Text" },
                    { value: TextComponentStyle.headlineXSmall, label: "Headline XSmall" },
                    { value: TextComponentStyle.headlineSmall, label: "Headline Small" },
                    { value: TextComponentStyle.headlineMedium, label: "Headline Medium" },
                    { value: TextComponentStyle.headlineLarge, label: "Headline Large" },
                    { value: TextComponentStyle.headlineXLarge, label: "Headline XLarge" },
                ],
                {
                    propertySection: PropertySection.Design,
                    defaultValue: TextComponentStyle.regular,
                    description: "The font size and weight style for the text.",
                }
            )
            .withEnum(
                "align",
                [
                    { value: UIAlignment.Start, label: "left", icon: "mt-text-left" },
                    { value: UIAlignment.Center, label: "center", icon: "mt-text-center" },
                    { value: UIAlignment.End, label: "right", icon: "mt-text-right" },
                ],
                {
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                    description: "The horizontal alignment of the text (left, center, right).",
                }
            )
    )
    .withSpecialCases(true, [
        [
            {
                name: "Headline",
                description: "A basic headline component",
                group: "Text",
                appKinds: AppKind.Page,
            },
            desc => ({
                ...desc,
                text: makeStringProperty("Headline"),
                style: makeEnumProperty(TextComponentStyle.headlineMedium),
            }),
        ],
    ]);

export type WireTextComponent = WirePropertiesOfComponent<typeof Text>;
export type WireTextDescription = DescriptionOfComponent<typeof Text>;

// Make main title legacy
const Title = component(WireComponentKind.Hero)
    .withDisplayName("Title")
    .withIcon("mt-component-title")
    .withDescription(
        "Best component to place at the top of a detail screen. Prominently displays the record title, subtitle, and image."
    )
    .withPrompt(
        "Title is for displaying the most important information of a screen, optionally adding image and actions (e.g. record title, editable user profile, welcome message)"
    )
    .withDocURL("pagesTitle")
    .withProperties(
        propertiesWithCSS
            .withEnum(
                "titleStyle",
                [
                    { value: UIWireTitleStyle.Simple, label: "Simple", icon: "mt-page-title-simple" },
                    { value: UIWireTitleStyle.Image, label: "Image", icon: "mt-page-title-image" },
                    { value: UIWireTitleStyle.Cover, label: "Cover", icon: "mt-page-title-cover" },
                    { value: UIWireTitleStyle.Profile, label: "Profile", icon: "mt-page-title-profile" },
                ],
                {
                    defaultValue: UIWireTitleStyle.Simple,
                    displayName: "Style",
                    enumVisual: "large-images",
                    propertySection: PropertySection.Style,
                    description:
                        "The overall visual layout style of the title area (e.g., Simple, Image, Cover, Profile). Use Profile for user profile screens.",
                }
            )

            .withText("title", {
                useTemplate: "withLabel",
                subcomponent: { important: true, name: "Content" },
                description:
                    "The main title text displayed prominently. This should be the name or uniquely identifying information of the record.",
            })

            .withText("titleEmphasized", {
                displayName: "Emphasis",
                emptyByDefault: true,
                useTemplate: "withLabel",
                preferredNames: titleProperties,
                subcomponent: { important: true, name: "Content" },
                description:
                    "Additional text displayed with emphasis, usually above the main title (not available in Profile/Cover styles).",
                when: desc => {
                    const titleStyle = getEnumProperty(desc.titleStyle);

                    return UIWireTitleStyle.Profile !== titleStyle && UIWireTitleStyle.Cover !== titleStyle;
                },
            })
            .withText("subtitle", {
                useTemplate: "withLabel",
                subcomponent: { important: true, name: "Content" },
                description: "Secondary text displayed below the title/emphasis (e.g. a subtitle or description).",
            })
            .withImage("image", {
                subcomponent: { important: true, name: "Image" },
                useTemplate: "withLabel",
                description: "The main image displayed in Image, Cover, or Profile styles.",
                when: desc => {
                    return getEnumProperty(desc.titleStyle) !== UIWireTitleStyle.Image;
                },
            })
            .withImage("image", {
                required: true,
                subcomponent: { important: true, name: "Image" },
                useTemplate: "withLabel",
                description: "The main image displayed in the Image style (required for this style).",
                when: desc => {
                    return getEnumProperty(desc.titleStyle) === UIWireTitleStyle.Image;
                },
            })
            .withImage("headerImage", {
                displayName: "Header Image",
                emptyByDefault: true,
                useTemplate: "withLabel",
                description: "The background image used in the Cover style.",
                when: desc => getEnumProperty(desc.titleStyle) === UIWireTitleStyle.Cover,
            })
            .withImage("headerImage", {
                displayName: "Header Image",
                useTemplate: "withLabel",
                description: "The background image used in the Profile style.",
                when: desc => getEnumProperty(desc.titleStyle) === UIWireTitleStyle.Profile,
            })
            .withEnum(
                "imageSize",
                [
                    { label: "Default", value: UIImageSize.Default },
                    { label: "Compact", value: UIImageSize.Compact },
                ],
                {
                    displayName: "Size",
                    enumVisual: "text",
                    propertySection: PropertySection.Design,
                    defaultValue: UIImageSize.Default,
                    description: "Controls the size of the image element within the component (Default or Compact).",
                }
            )
            .withArray("buttons", makeActionWithTitleProperties(), {
                propertySection: { name: "Actions", order: 4 },
                style: ArrayPropertyStyle.ActionArray,
                specialItems: 2,
                allowEmpty: true,
                removeIfMissing: removeIfMissingInActionWithTitle,
                subcomponent: { important: true, name: "Actions" },
                description: "An array of action buttons displayed in the title area.",
            })
            .withEnum(
                "imageFill",
                [
                    {
                        label: "Fill",
                        value: UIImageFill.Cover,
                    },
                    {
                        label: "Fit",
                        value: UIImageFill.Contain,
                    },
                ],
                {
                    enumVisual: "text",
                    defaultValue: UIImageFill.Cover,
                    propertySection: PropertySection.Design,
                    description:
                        "Determines if the image should fill (Cover) its container or fit within (Contain) it.",
                }
            )
    )
    .withSpecialCases(false, [
        [
            {
                name: "Simple",
                description: "A simple Title",
                group: "Title",
                appKinds: AppKind.Page,
                img: "mt-page-title-simple",
                setAsCurrent: desc => {
                    return { ...desc, titleStyle: makeEnumProperty(UIWireTitleStyle.Simple) };
                },
            },
            desc => ({
                ...desc,
                titleStyle: makeEnumProperty(UIWireTitleStyle.Simple),
            }),
        ],
        [
            {
                name: "Image",
                description: "An image Title",
                img: "mt-page-title-image",
                group: "Title",
                appKinds: AppKind.Page,
                setAsCurrent: desc => {
                    return { ...desc, titleStyle: makeEnumProperty(UIWireTitleStyle.Image) };
                },
            },
            desc => {
                return {
                    ...desc,
                    titleStyle: makeEnumProperty(UIWireTitleStyle.Image),
                };
            },
        ],
        [
            {
                name: "Profile",
                description: "A profile Title",
                group: "Title",
                img: "mt-component-title-profile",
                appKinds: AppKind.Page,
                setAsCurrent: desc => {
                    return { ...desc, titleStyle: makeEnumProperty(UIWireTitleStyle.Profile) };
                },
            },
            desc => ({
                ...desc,
                titleStyle: makeEnumProperty(UIWireTitleStyle.Profile),
            }),
        ],
        [
            {
                name: "Cover",
                description: "A Cover Title",
                img: "mt-page-title-cover",
                group: "Title",
                appKinds: AppKind.Page,
                setAsCurrent: desc => {
                    return { ...desc, titleStyle: makeEnumProperty(UIWireTitleStyle.Cover) };
                },
            },
            desc => ({
                ...desc,
                titleStyle: makeEnumProperty(UIWireTitleStyle.Cover),
            }),
        ],
    ]);
export type WireTitleComponent = WirePropertiesOfComponent<typeof Title>;
export type WireTitleDescription = DescriptionOfComponent<typeof Title>;

export type HeroSectionDescription = DescriptionOfComponent<typeof Title> & {
    // This is just a marker that we use to tag the Glide-generated Title
    // component in the user profile screen.
    readonly isDefaultUserProfileComponent?: PropertyDescription;
};

const Buttons = component(WireComponentKind.DynamicButtonBar)
    .withIcon("mt-component-button-bar")
    .withDisplayName("Button Block")
    .withDescription("A small block of buttons")
    .withDocURL("pagesButtons")
    .withDescription("A block of buttons with title/subtitle")
    .withProperties(
        propertiesWithCSS
            .withArray("buttons", makeActionWithTitleProperties(), {
                propertySection: PropertySection.Action,
                style: ArrayPropertyStyle.ActionArray,
                removeIfMissing: removeIfMissingInActionWithTitle,
                defaultValue: [
                    {
                        title: makeStringProperty("Primary"),
                        action: makeActionProperty({
                            kind: ActionKind.OpenLinkWithArgs,
                            link: makeStringProperty("https://www.glideapps.com"),
                        } as ActionDescription),
                    },
                    {
                        title: makeStringProperty("Secondary"),
                        action: makeActionProperty({
                            kind: ActionKind.ShowToast,
                        }),
                    },
                ] as unknown as readonly Description[],
            })
            .withEnum("alignment", [UIAlignment.Start, UIAlignment.Center, UIAlignment.End] as const, {
                defaultValue: UIAlignment.Center,
            })
            .withText("title", { emptyByDefault: true, useTemplate: "withLabel" })
            .withText("description", { emptyByDefault: true, useTemplate: "withLabel" })
            .withEnum("size", [ButtonBarSize.Auto, ButtonBarSize.Wide] as const, { defaultValue: ButtonBarSize.Wide })
    )
    .withSpecialCases(true, [
        [
            {
                name: "Button (legacy)",
                description: "A button",
                img: "mt-component-button",
                group: "Actions",
                appKinds: AppKind.Page,
                getIsHidden: () => true,
            },
            desc => ({
                ...desc,
                builderDisplayName: "Button (legacy)",
                buttons: makeArrayProperty([
                    {
                        title: makeStringProperty("Go!"),
                        action: makeActionProperty({
                            kind: ActionKind.ShowToast,
                        }),
                        alignment: makeEnumProperty(UIAlignment.Start),
                        size: makeEnumProperty(ButtonBarSize.Auto),
                    },
                ]),
            }),
        ],
    ])
    .withLegacy();
export type WireButtonsComponent = WirePropertiesOfComponent<typeof Buttons>;
export type WireButtonsDescription = DescriptionOfComponent<typeof Buttons>;

const ButtonsBlock = component(WireComponentKind.ButtonsBlock)
    .withIcon("mt-component-button-bar")
    .withDisplayName("Button Block")
    .withDescription("A small block of buttons")
    .withDocURL("pagesButtons")
    .withDescription("A block of buttons")
    .withProperties(
        propertiesWithCSS
            .withArray("buttons", makeActionWithTitleProperties(), {
                propertySection: PropertySection.Action,
                style: ArrayPropertyStyle.ActionArray,
                removeIfMissing: removeIfMissingInActionWithTitle,
                defaultValue: [
                    {
                        title: makeStringProperty("Primary"),
                        action: makeActionProperty({
                            kind: ActionKind.OpenLinkWithArgs,
                            link: makeStringProperty("https://www.glideapps.com"),
                        } as ActionDescription),
                    },
                    {
                        title: makeStringProperty("Secondary"),
                        action: makeActionProperty({
                            kind: ActionKind.ShowToast,
                        }),
                    },
                ] as unknown as readonly Description[],
            })
            .withEnum(
                "buttonStyle",
                [
                    { value: UIBUttonStyle.Basic, label: "Basic", icon: "mt-buttons-basic" },
                    { value: UIBUttonStyle.Minimal, label: "Minimal", icon: "mt-buttons-minimal" },
                    { value: UIBUttonStyle.Tiles, label: "Tiles", icon: "mt-buttons-tiles" },
                ],
                {
                    defaultValue: UIBUttonStyle.Basic,
                    displayName: "Style",
                    enumVisual: "large-images",
                    propertySection: PropertySection.Style,
                }
            )
            .withEnum("size", [ButtonBarSize.Auto, ButtonBarSize.Wide] as const, {
                defaultValue: ButtonBarSize.Wide,
                displayName: "Width",
                enumVisual: "text",
            })
            // We have two `accentLocation` properties, cause one is for multiple buttons (Left / None / Right)
            // And the other is for single button (On / Off)
            .withEnum(
                "accentLocation",
                [ButtonBarPrimaryLocation.Left, ButtonBarPrimaryLocation.None, ButtonBarPrimaryLocation.Right] as const,
                {
                    defaultValue: ButtonBarPrimaryLocation.Left,
                    displayName: "Accent",
                    enumVisual: "text",
                    when: d => {
                        const supportsAccent = getEnumProperty(d.buttonStyle) !== UIBUttonStyle.Tiles;
                        const buttonsCount = getArrayProperty(d.buttons)?.length ?? 0;
                        const hasMultipleActions = buttonsCount > 1;
                        return supportsAccent && hasMultipleActions;
                    },
                }
            )
            .withEnum(
                "accentLocation",
                [
                    {
                        value: ButtonBarPrimaryLocation.Left,
                        label: "On",
                    },
                    {
                        value: ButtonBarPrimaryLocation.None,
                        label: "Off",
                    },
                ],
                {
                    defaultValue: ButtonBarPrimaryLocation.Left,
                    displayName: "Accent",
                    enumVisual: "text",
                    when: d => {
                        const supportsAccent = getEnumProperty(d.buttonStyle) !== UIBUttonStyle.Tiles;
                        const buttonsCount = getArrayProperty(d.buttons)?.length ?? 0;
                        return supportsAccent && buttonsCount === 1;
                    },
                }
            )
            .withEnum("labelsVisibility", [ButtonLabelsVisibility.Show, ButtonLabelsVisibility.Hide] as const, {
                defaultValue: ButtonLabelsVisibility.Show,
                displayName: "Labels",
                enumVisual: "text",
            })
            .withEnum(
                "alignment",
                [
                    { value: UIAlignment.Start, label: "left", icon: "mt-text-left" },
                    { value: UIAlignment.Center, label: "center", icon: "mt-text-center" },
                    { value: UIAlignment.End, label: "right", icon: "mt-text-right" },
                ],
                {
                    enumVisual: "small-images",
                    defaultValue: UIAlignment.Start,

                    when: d => getEnumProperty(d.size) !== ButtonBarSize.Wide,
                }
            )
            .withSwitch("showDisabled", false, {
                displayName: "Show when disabled",
            })
    )
    .withSpecialCases(true, [
        [
            {
                name: "Button",
                description: "A button",
                img: "mt-component-button",
                group: "Actions",
                appKinds: AppKind.Page,
            },
            desc => ({
                ...desc,
                builderDisplayName: "Button",
                buttons: makeArrayProperty([
                    {
                        title: makeStringProperty("Go!"),
                        action: makeActionProperty({
                            kind: ActionKind.ShowToast,
                        }),
                        alignment: makeEnumProperty(UIAlignment.Start),
                        size: makeEnumProperty(ButtonBarSize.Auto),
                    },
                ]),
            }),
        ],
        [
            {
                name: "Voice Transcription",
                description: "A voice transcription button",
                img: "mt-microphone",
                appKinds: AppKind.Page,
                group: "Actions",
                isBeta: true,
                getIsHidden: userFeatures => !isExperimentEnabled("audioEntryAction", userFeatures),
            },
            (desc, tables, iccc, mutatingScreenKind) => {
                const action = iccc.makeAction(ActionKind.PushVoiceEntry, tables, mutatingScreenKind);
                const actionWithTitle = definedMap(action, a => ({
                    title: makeStringProperty("Record"),
                    action: makeActionProperty(a),
                }));

                return {
                    ...desc,
                    buttons: makeArrayProperty([actionWithTitle]),
                };
            },
        ],
    ]);

export type WireButtonsBlockComponent = WirePropertiesOfComponent<typeof ButtonsBlock>;
export type WireButtonsBlockDescription = DescriptionOfComponent<typeof ButtonsBlock>;

export const Breadcrumbs = component(WireComponentKind.Breadcrumbs)
    .withIcon("mt-component-breadcrumbs")
    .withDisplayName("Breadcrumbs")
    .withDescription("Help your user know where they are in your site")
    .withScreenTitle();

const ContactForm = form(WireComponentKind.ContactForm)
    .withDisplayName("Contact Form")
    .withDescription("Collect contact information")
    .withDocURL("pagesContactForm")
    .withProperties(
        propertiesWithCSS
            .withText("title", {
                defaultValue: "Contact us",
                useTemplate: "withLabel",
            })
            .withText("description", {
                emptyByDefault: true,
                useTemplate: "withLabel",
            })
    )
    .withFormProperties(
        properties
            .withText("name", {
                useTemplate: "withLabel",
            })
            .withText("email", {
                preferredType: "email-address",
                useTemplate: "withLabel",
            })
            .withText("phone", {
                preferredType: "phone-number",
                emptyByDefault: true,
                useTemplate: "withLabel",
            })
            .withText("message", {
                useTemplate: "withLabel",
            })
    );
export type WireContactFormComponent = WirePropertiesOfComponent<typeof ContactForm>;
export type WireContactFormDescription = DescriptionOfComponent<typeof ContactForm>;

const Fields = component(WireComponentKind.Fields)
    .withDisplayName("Fields")
    .withDescription("Display a collection of data in columns")
    .withPrompt(
        "Fields is for displaying a series of key-value pairs from your data (e.g. product details, contact information, record summaries)"
    )
    .withIcon("mt-component-fields")
    .withDocURL("pagesFields")
    .withProperties(
        propertiesWithCSS
            .withText("title", {
                useTemplate: "withLabel",
                emptyByDefault: true,
                description: "An optional title displayed above the fields.",
            })
            .withArray(
                "fields",
                properties.withCaption("name", undefined).withPrimitive("value", { isDefaultCaption: true }),
                {
                    style: ArrayPropertyStyle.KeyValue,
                    description:
                        "An array of key-value pairs to display. Each item requires a 'name' (label) and 'value' (content).",
                }
            )
    );

export type WireFieldsComponent = WirePropertiesOfComponent<typeof Fields>;
export type WireFieldsDescription = DescriptionOfComponent<typeof Fields>;

const Location = component(WireComponentKind.Location)
    .withDisplayName("Location")
    .withDescription("Enter the current location")
    .withPrompt(
        "Location is for storing geographic location data (e.g. latitude/longitude coordinates, addresses, GPS positions)"
    )
    .withIcon("co-location")
    .withProperties(
        propertiesWithCSS
            .withEditableValue(
                "value",
                "string",
                {
                    displayName: "Save to",
                    description: "The column where the selected location data (address or coordinates) will be saved.",
                },
                {}
            )
            .withText("label", {
                placeholder: "Use current location",
                emptyByDefault: true,
                useTemplate: "withLabel",
                description: "The placeholder text displayed on the component before a location is entered.",
            })
    )
    .withShowRegardlessOfEmptyProperties(true);
export type WireLocationComponent = WirePropertiesOfComponent<typeof Location>;
export type WireLocationDescription = DescriptionOfComponent<typeof Location>;

const BigNumbers = component(WireComponentKind.BigNumbers)
    .withIcon("mt-component-big-numbers")
    .withDisplayName("Big Numbers")
    .withDescription("Numbers but bigger than most")
    .withPrompt("BigNumbers is for highlighting numerical data (e.g. statistics, metrics, scores)")
    .withDocURL("pagesBigNumbers")
    .withProperties(
        propertiesWithCSS
            .withText("title", {
                useTemplate: "withLabel",
                emptyByDefault: true,
                description: "An optional title displayed above the big numbers cards (e.g. a main label or heading).",
            })
            .withArray(
                "data",
                propertiesWithCSS
                    .withText("name", {
                        useTemplate: "withLabel",
                        emptyByDefault: true,
                        description:
                            "The label or name associated with the data point (e.g. a category or subheading).",
                    })
                    .withText("value", {
                        isDefaultCaption: true,
                        useTemplate: "withLabel",
                        description: "The numerical value to display (e.g. a count, score, or measurement).",
                    })
                    .withText("description", {
                        emptyByDefault: true,
                        useTemplate: "withLabel",
                        description:
                            "An optional description or subtext that provides additional context or information about the data point.",
                    })
                    .withAction("action"),
                {
                    style: ArrayPropertyStyle.Card,
                    description:
                        "An array of data points to display as big numbers cards. Each requires a 'value', and can optionally have a 'name' (label) and 'description'.",
                }
            )
    );
export type WireBigNumbersComponent = WirePropertiesOfComponent<typeof BigNumbers>;
export type WireBigNumbersDescription = DescriptionOfComponent<typeof BigNumbers>;

const RichText = component(WireComponentKind.RichText)
    .withIcon("mt-component-rich-text")
    .withDisplayName("Rich Text")
    .withDescription("Display text with formatting and images using markdown")
    .withPrompt(
        "RichText is for displaying markdown-formatted content with styling like headers, bold text, links, lists, images and more"
    )
    .withDocURL("pagesRichText")
    .withProperties(
        propertiesWithCSS
            .withText("text", {
                defaultValue:
                    "# Header\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis enim vitae quam curabitur id.\n\n- One\n- Two\n- Three",
                useTemplate: "withoutLabel",
                propertySection: PropertySection.Content,
                description: "The Markdown-formatted text content to display.",
            })

            .withAction("action", {
                description: "An optional action triggered when the component is tapped.",
            })
    );
export type WireRichTextComponent = WirePropertiesOfComponent<typeof RichText>;
export type WireRichTextDescription = DescriptionOfComponent<typeof RichText>;

const Note = component(WireComponentKind.Note)
    .withIcon("co-notes")
    .withDisplayName("Notes")
    .withDescription("Display an editable note with formatting options")
    .withPrompt("Note is for editing and format rich text content with headings, lists, and text styling")
    .withShowRegardlessOfEmptyProperties(true)
    .withProperties(
        propertiesWithCSS
            .withEditableValue(
                "text",
                "string",
                {
                    required: true,
                    description: "The column where the formatted markdown content entered by the user will be saved.",
                },
                { isDisplayed: true }
            )
            .withText("label", {
                isComponentTitle: true,
                emptyByDefault: true,
                defaultValue: "Note",
                useTemplate: "withLabel",
                description: "A label displayed above the note editor area.",
            })
            .withSwitch("withVoiceEntry", false, {
                displayName: "Voice Transcription",
                description: "Enables a voice-to-text transcription button within the editor.",
                when: (_d, _r, _t, adc) => {
                    return isExperimentEnabled("inlineAudioTranscript", adc.userFeatures);
                },
            })
            .withArray("noteActions", makeActionWithTitleProperties(), {
                propertySection: PropertySection.Action,
                style: ArrayPropertyStyle.ActionArray,
                removeIfMissing: removeIfMissingInActionWithTitle,
                defaultValue: [
                    {
                        title: makeStringProperty(getLocalizedString("copyGeneral", AppKind.Page)),
                        action: makeActionProperty({
                            kind: ActionKind.CopyToClipboardWithArgs,
                        } as ActionDescription),
                        icon: makeIconProperty(getGlideIcon("st-copy")),
                    },
                ] as unknown as readonly Description[],
                description: "Actions available within the note component, like a 'Copy' button.",
            })
    );
export type WireNoteComponent = WirePropertiesOfComponent<typeof Note>;
export type WireNoteDescription = DescriptionOfComponent<typeof Note>;

const Image = component(WireComponentKind.Image)
    .withIcon("mt-component-image")
    .withDisplayName("Image Block")
    .withDescription("An image with optional title and caption")
    .withDocURL("pagesImage")
    .withProperties(
        propertiesWithCSS
            .withText("title", {
                emptyByDefault: true,
                useTemplate: "withLabel",
            })

            .withImage("image", {
                subcomponent: {
                    important: true,
                    name: "Image",
                },
                useTemplate: "withLabel",
            })
            .withText("caption", {
                emptyByDefault: true,
                subcomponent: {
                    important: true,
                    name: "Text",
                },
                useTemplate: "withLabel",
            })
            .withText("alt", {
                subcomponent: {
                    important: true,
                    name: "Alternate Text",
                },
                useTemplate: "withLabel",
            })

            .withEnum(
                "aspectRatio",
                [
                    {
                        label: "Auto",
                        value: UIAspect.Auto,
                        icon: "borderOriginal",
                    },
                    {
                        label: "Square",
                        value: UIAspect.Square,
                        icon: "borderSquare",
                    },
                    {
                        label: "4:3",
                        value: UIAspect.FourByThree,
                        icon: "border4by3",
                    },
                    {
                        label: "16:9",
                        value: UIAspect.SixteenByNine,
                        icon: "border3by2", //FIXME
                    },
                    {
                        label: "3:1",
                        value: UIAspect.ThreeByOne,
                        icon: "border3by1",
                    },
                ],
                {
                    enumVisual: "small-images",
                    subcomponent: {
                        important: true,
                        name: "Image",
                    },
                }
            )
            .withEnum(
                "size",
                [
                    { label: "Small", value: UISize.Small },
                    { label: "Medium", value: UISize.Medium },
                    { label: "Large", value: UISize.Large },
                    { label: "Full", value: UISize.Full },
                ],
                {
                    when: d =>
                        getSourceColumnProperty(d.title) !== undefined ||
                        !isEmptyOrUndefined(getStringProperty(d.title)) ||
                        getSourceColumnProperty(d.caption) !== undefined ||
                        !isEmptyOrUndefined(getStringProperty(d.caption)),
                    subcomponent: {
                        important: true,
                        name: "Image",
                    },
                }
            )
            .withText("alt", {
                displayName: "Alt Text",
                propertySection: PropertySection.Options,
                useTemplate: "withLabel",
            })
    )
    .withLegacy();
export type WireImageComponent = WirePropertiesOfComponent<typeof Image>;
export type WireImageDescription = DescriptionOfComponent<typeof Image>;

const SimpleImage = component(WireComponentKind.SimpleImage)
    .withIcon("mt-component-image")
    .withDisplayName("Image")
    .withDescription("A simple image component")
    .withPrompt("Image is for displaying visual content or documents (e.g. photos, PDFs, logos, diagrams)")
    .withKeywords("photo", "picture", "img")
    .withDocURL("pagesImage")
    .withProperties(
        propertiesWithCSS
            .withImage("image", {
                subcomponent: {
                    important: true,
                    name: "Image",
                },
                description: "The source column for the image URI or file.",
            })
            .withEnum(
                "style",
                [
                    {
                        label: "Square",
                        value: UIImageStyle.Rectilinear,
                        icon: "borderSquare",
                    },
                    {
                        label: "Circle",
                        value: UIImageStyle.Circle,
                        icon: "borderCircle",
                    },
                ],
                {
                    enumVisual: "small-images",
                    defaultValue: UIImageStyle.Rectilinear,
                    propertySection: PropertySection.Design,
                    description:
                        "The shape of the image (Square or Circle). Not applicable if image source is an array.",
                    when: (desc, _, tables, adc) => {
                        return !isPropertyBoundToAnArray(desc.image, tables, adc);
                    },
                }
            )
            .withEnum(
                "aspectRatio",
                [
                    {
                        label: "Auto",
                        value: UIAspect.Auto,
                        icon: "mt-aspect-ratio-auto",
                    },
                    {
                        label: "Square",
                        value: UIAspect.Square,
                        icon: "borderSquare",
                    },
                    {
                        label: "4:3",
                        value: UIAspect.FourByThree,
                        icon: "border4by3",
                    },
                    {
                        label: "16:9",
                        value: UIAspect.SixteenByNine,
                        icon: "border3by2",
                    },
                    {
                        label: "3:1",
                        value: UIAspect.ThreeByOne,
                        icon: "border3by1",
                    },
                ],
                {
                    enumVisual: "small-images",
                    subcomponent: { important: true, name: "Image" },
                    propertySection: PropertySection.Design,
                    description: "The display aspect ratio for the image.",
                    when: (d, _, tables, adc) => {
                        // the when clause above prevents UIImageStyle from having a style attribute when the config is initially built.
                        return (
                            getEnumProperty(d.style) === UIImageStyle.Rectilinear ||
                            isPropertyBoundToAnArray(d.image, tables, adc)
                        );
                    },
                }
            )
            .withEnum(
                "size",
                [
                    { label: "S", value: UISize.Small },
                    { label: "M", value: UISize.Medium },
                    { label: "Full", value: UISize.Full },
                ],
                {
                    defaultValue: UISize.Full,
                    propertySection: PropertySection.Design,
                    enumVisual: "text",
                    description: "The display size of the image (Small or Full width).",
                }
            )
            .withSwitch("fullWidthOnMobile", false, {
                displayName: "Full width on mobile",
                propertySection: PropertySection.Design,
                description: "Whether the image should take the full width on mobile devices.",
                when: d => getEnumProperty(d.size) !== UISize.Full,
            })
            .withEnum(
                "align",
                [
                    { value: UIAlignment.Start, label: "left", icon: "mt-text-left" },
                    { value: UIAlignment.Center, label: "center", icon: "mt-text-center" },
                    { value: UIAlignment.End, label: "right", icon: "mt-text-right" },
                ],
                {
                    defaultValue: UIAlignment.Center,
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                    description: "The horizontal alignment of the image within its container.",
                }
            )
            .withAction("action", {
                description: "An action triggered when the image is tapped.",
            })
            .withSwitch("zoomable", false, {
                displayName: "Open on click",
                propertySection: PropertySection.Options,
                description: "Whether to open the image when tapped.",
            })
            .withText("alt", {
                displayName: "Alt Text",
                propertySection: PropertySection.Options,
                useTemplate: "withLabel",
                description: "Alternative text for the image, used for accessibility.",
            })
    );
export type WireSimpleImageComponent = WirePropertiesOfComponent<typeof SimpleImage>;
export type WireSimpleImageDescription = DescriptionOfComponent<typeof SimpleImage>;

const Video = component(WireComponentKind.Video)
    .withIcon("mt-component-video")
    .withDisplayName("Video Block")
    .withDescription("Display a video with optional caption and title")
    .withDocURL("pagesVideo")
    .withProperties(
        propertiesWithCSS
            .withText("title", {
                emptyByDefault: true,
                useTemplate: "withLabel",
            })
            .withVideo("video", { defaultValue: "https://www.youtube.com/watch?v=eqxP0DnJlBI" })
            .withText("caption", { emptyByDefault: true, useTemplate: "withLabel" })
            .withEnum(
                "aspectRatio",
                [
                    {
                        label: "Square",
                        value: UIAspect.Square,
                        icon: "borderSquare",
                    },
                    {
                        label: "4:3",
                        value: UIAspect.FourByThree,
                        icon: "border4by3",
                    },
                    {
                        label: "16:9",
                        value: UIAspect.SixteenByNine,
                        icon: "border3by2", //FIXME
                    },
                    {
                        label: "3:1",
                        value: UIAspect.ThreeByOne,
                        icon: "border3by1",
                    },
                ],
                {
                    enumVisual: "small-images",
                    defaultValue: UIAspect.SixteenByNine,
                }
            )
            .withEnum(
                "size",
                [
                    { label: "Small", value: UISize.Small },
                    { label: "Medium", value: UISize.Medium },
                    { label: "Large", value: UISize.Large },
                    { label: "Full", value: UISize.Full },
                ],
                {
                    when: d =>
                        getSourceColumnProperty(d.title) !== undefined ||
                        !isEmptyOrUndefined(getStringProperty(d.title)) ||
                        getSourceColumnProperty(d.caption) !== undefined ||
                        !isEmptyOrUndefined(getStringProperty(d.caption)),
                }
            )
    )
    .withLegacy();
export type WireVideoComponent = WirePropertiesOfComponent<typeof Video>;
export type WireVideoDescription = DescriptionOfComponent<typeof Video>;

const SimpleVideo = component(WireComponentKind.SimpleVideo)
    .withIcon("mt-component-video")
    .withDisplayName("Video")
    .withDescription("Display a video")
    .withPrompt("Video is for playing video content (e.g. tutorials, presentations, recordings)")
    .withDocURL("pagesVideo")
    .withProperties(
        propertiesWithCSS
            .withVideo("video", {
                defaultValue: "https://www.youtube.com/watch?v=eqxP0DnJlBI",
                description: "The source column for the video URI or file.",
            })
            .withEnum(
                "aspectRatio",
                [
                    {
                        label: "Square",
                        value: UIAspect.Square,
                        icon: "borderSquare",
                    },
                    {
                        label: "4:3",
                        value: UIAspect.FourByThree,
                        icon: "border4by3",
                    },
                    {
                        label: "16:9",
                        value: UIAspect.SixteenByNine,
                        icon: "border3by2",
                    },
                    {
                        label: "3:1",
                        value: UIAspect.ThreeByOne,
                        icon: "border3by1",
                    },
                ],
                {
                    enumVisual: "small-images",
                    defaultValue: UIAspect.SixteenByNine,
                    description: "The display aspect ratio of the video player.",
                }
            )
            .withEditableValue(
                "viewedPercentage",
                "number",
                {
                    displayName: "Save Progress",
                    emptyByDefault: true,
                    propertySection: PropertySection.Options,
                    description: "Optional column to save the video playback progress (a number between 0 and 1).",
                },
                { isDisplayed: false }
            )
            .withSwitch("showControls", true, {
                propertySection: PropertySection.Options,
                description: "Whether to display video playback controls (play, pause, seek).",
            })
    );
export type WireSimpleVideoComponent = WirePropertiesOfComponent<typeof SimpleVideo>;
export type WireSimpleVideoDescription = DescriptionOfComponent<typeof SimpleVideo>;

const Separator = component(WireComponentKind.Separator)
    .withDisplayName("Separator")
    .withKeywords("divider", "line")
    .withDocURL("pagesSeparator")
    .withDescription("Add padding with optional line")
    .withPrompt(
        "Separator is for visually dividing content into sections, with an optional visible line to create clear boundaries between sections. Place separators between sections of content to help guide the user's eye and create a more organized layout."
    )
    .withIcon("mt-component-separator")
    .withProperties(
        propertiesWithCSS
            .withEnum("size", [SeparatorSize.Small, SeparatorSize.Medium, SeparatorSize.Large], {
                description: "The amount of vertical spacing created by the separator (Small, Medium, Large).",
            })
            .withSwitch("drawLine", true, {
                description: "Whether to draw a visible horizontal line within the separator space.",
            })
    )
    .withShowRegardlessOfEmptyProperties(true)
    .withSpecialCases(true, [
        [
            {
                name: "Spacer",
                description: "An empty element to add padding between components",
                img: "mt-spacer-bar",
                group: "Layout",
                appKinds: AppKind.Page,
            },
            desc => ({
                ...desc,
                builderDisplayName: "Spacer",
                size: makeEnumProperty(SeparatorSize.Medium),
                drawLine: makeSwitchProperty(false),
            }),
        ],
    ]);
export type WireSeparatorComponent = WirePropertiesOfComponent<typeof Separator>;
export type WireSeparatorDescription = DescriptionOfComponent<typeof Separator>;

const Spinner = component(WireComponentKind.Spinner)
    .withDisplayName("Spinner")
    .withKeywords("loader", "spinner")
    .withDescription("A spinner component to show while waiting or loading")
    .withPrompt(
        "Spinner is an advanced component for showing loading states (e.g. data fetching, processing, waiting). It expects to be visually hidden after a short delay."
    )
    .withIcon("mt-component-spinner")
    .withProperties(
        propertiesWithCSS.withEnum(
            "size",
            [
                { value: UISize.Small, label: "Small" },
                { value: UISize.Medium, label: "Medium" },
                { value: UISize.Large, label: "Large" },
            ],
            {
                enumVisual: "text",
                description: "The visual size of the spinner animation (Small, Medium, Large).",
            }
        )
    )
    .withShowRegardlessOfEmptyProperties(true);

export type WireSpinnerComponent = WirePropertiesOfComponent<typeof Spinner>;
export type WireSpinnerDescription = DescriptionOfComponent<typeof Spinner>;

export const InternalSeparator = component(WireComponentKind.InternalSeparator)
    .withLegacy()
    .withDisplayName("Internal Separator");

// TODO delete this in favor of new custom component
const Custom = component(WireComponentKind.Custom)
    .withDisplayName("Custom")
    .withIcon("mt-component-custom")
    .withDescription("Create a custom HTML component. This is an experimental feature.")
    .withFeatureSetting("customCodeComponent")
    .withProperties(
        propertiesWithCSS
            .withArray("fields", properties.withText("name", { useTemplate: "withLabel" }).withPrimitive("value"))
            .withEnum(
                "source",
                [
                    {
                        label: "URL",
                        value: CustomComponentSource.URL as CustomComponentSource,
                    },
                    {
                        label: "Code",
                        value: CustomComponentSource.InlineCode,
                    },
                ],
                {
                    enumVisual: "dropdown",
                    defaultValue: CustomComponentSource.URL,
                    propertySection: PropertySection.Source,
                }
            )
            .withEnum(
                "sourceTransform",
                [
                    {
                        label: "HTML",
                        value: CustomComponentSourceTransform.HTML as CustomComponentSourceTransform,
                    },
                    {
                        label: "React",
                        value: CustomComponentSourceTransform.React,
                    },
                ],
                {
                    enumVisual: "dropdown",
                    defaultValue: CustomComponentSourceTransform.HTML,
                    propertySection: PropertySection.Source,
                    when: d => getEnumProperty(d.source) === CustomComponentSource.InlineCode,
                    displayName: "Language",
                }
            )
            .withEnum(
                "width",
                [
                    {
                        label: "Automatic",
                        value: CustomComponentWidth.Container as CustomComponentWidth,
                        icon: "paddingLoose",
                    },
                    {
                        label: "Original",
                        value: CustomComponentWidth.Full,
                        icon: "borderOriginal",
                    },
                ],
                {
                    enumVisual: "small-images",
                    defaultValue: CustomComponentWidth.Container,
                    propertySection: PropertySection.Design,
                    displayName: "Responsiveness",
                }
            )
            .withURI("url", {
                displayName: "URL",
                when: d => getEnumProperty(d.source) === CustomComponentSource.URL,
                propertySection: PropertySection.Source,
            })
            .withText("code", {
                syntaxMode: "html",
                allowColumn: false,
                useTemplate: "withLabel",
                when: d => getEnumProperty(d.source) === CustomComponentSource.InlineCode,
                propertySection: PropertySection.Source,
            })
    );

export type WireCustomComponent = WirePropertiesOfComponent<typeof Custom>;
export type WireCustomDescription = DescriptionOfComponent<typeof Custom>;

export const WEBVIEW_DEFAULT_URL = "/media/webembed/web-embed-tutorial.html";

const Webview = component(WireComponentKind.WebView)
    .withIcon("mt-www")
    .withPluginTier("starter")
    .withDisplayName("Web Embed")
    .withDescription("Embeddable web content")
    .withPrompt("Webview is for embedding web content (e.g. external pages, forms, widgets)")
    .withProperties(
        propertiesWithCSS
            .withURI("url", {
                displayName: "URL",
                defaultValue: WEBVIEW_DEFAULT_URL,
                propertySection: PropertySection.Source,
                description: "The URL of the web page to embed.",
            })

            .withEnum(
                "size",
                [
                    { value: UISize.Small, label: "Small", icon: "mt-height-sm" },
                    { value: UISize.Medium, label: "Medium", icon: "mt-height-md" },
                    { value: UISize.Large, label: "Large", icon: "mt-height-lg" },
                    { value: UISize.Full, label: "Full", icon: "mt-height-full" },
                ],
                {
                    defaultValue: UISize.Full,
                    enumVisual: "small-images",
                    description: "The height of the embedded web view container.",
                }
            )
            .withSwitch("scrollable", true, {
                displayName: "Allow scrolling",
                addAtBottom: true,
                description: "Allows the user to scroll the content within the embedded web view.",
            })
    );
export type WireWebViewComponent = WirePropertiesOfComponent<typeof Webview>;
export type WireWebViewDescription = DescriptionOfComponent<typeof Webview>;

const makeAICustomComponentFieldsProperties = () =>
    properties.withCaption("name", undefined).withEditablePrimitiveValue(
        "value",
        {
            displayName: "Value",
            required: true,
        },
        { isDisplayed: true, allowLiteral: true, isDefaultCaption: true }
    );
export type AICustomComponentFieldsDescription = DescriptionOfProperties<
    ReturnType<typeof makeAICustomComponentFieldsProperties>
>;

// TODO: Rename to `CodeComponent`
export const AICustomComponent = component(WireComponentKind.AICustomComponent)
    .withLegacy()
    .withIcon("mt-component-chat")
    .withDisplayName("Code")
    .withDescription("Custom code component using HTML, CSS, and JavaScript")
    .withIcon("mt-component-custom")
    .withPluginTier("starter")
    .withExperimentFlag("customAIComponent")
    .withProperties(
        propertiesWithCSS
            .withEnum(
                "generator",
                [
                    {
                        label: "HTML",
                        value: "html-tailwind-alpine",
                    },
                ],
                {
                    enumVisual: "small-images",
                    displayName: "Framework",
                    defaultValue: "html-tailwind-alpine",
                    propertySection: PropertySection.Options,
                }
            )
            .withText("prompt", {
                displayName: "Prompt",
                required: true,
                isMultiLine: true,
                allowColumn: false,
                propertySection: PropertySection.Content,
                when: d => getEnumProperty(d.generator) !== "html-tailwind-alpine",
            })
            .withText("html", {
                displayName: "HTML",
                required: true,
                isMultiLine: true,
                syntaxMode: "html",
                allowColumn: false,
                propertySection: PropertySection.Content,
                when: d => getEnumProperty(d.generator) === "html-tailwind-alpine",
            })

            .withArray("fields", makeAICustomComponentFieldsProperties(), {
                style: ArrayPropertyStyle.KeyValue,
                propertySection: PropertySection.Data,
                allowEmpty: true,
            })

            .withArray("actions", makeActionWithTitleProperties(), {
                propertySection: PropertySection.Action,
                style: ArrayPropertyStyle.ActionArray,
                removeIfMissing: removeIfMissingInActionWithTitle,
                allowEmpty: true,
            })
    );
export type WireAICustomComponent = WirePropertiesOfComponent<typeof AICustomComponent>;
export type WireAICustomComponentDescription = DescriptionOfComponent<typeof AICustomComponent>;

const isAIEnabled = (adc: AppDescriptionContext): boolean => {
    const owner = promoteAppDescriptionContext(adc)?.owner;
    // If you're in the player the owner is blank. In the player you're allowed to use AI
    // We only need the owner check when building up the component.
    // Be aware - the owner may be blank in builder in some corner cases.
    if (owner === undefined) return true;
    return owner?.kind === OwnerKind.Organization ? owner.isAIEnabled === true : false;
};

export enum AICustomComponentCommitType {
    text = "text",
    fieldAndActionChange = "fieldAndActionChange",
    fieldChange = "fieldChange",
    actionChange = "actionChange",
}

const commitType = [
    AICustomComponentCommitType.text,
    AICustomComponentCommitType.fieldAndActionChange,
    AICustomComponentCommitType.fieldChange,
    AICustomComponentCommitType.actionChange,
];

const makeAICustomComponentHistoryItem = () =>
    properties
        .withText("uuid", {
            required: true,
            isMultiLine: false,
            allowColumn: false,
        })
        .withText("prompt", {
            required: true,
            isMultiLine: true,
            allowColumn: false,
        })
        .withEnum<"type", AICustomComponentCommitType>("type", commitType, {
            required: true,
        })
        .withArray("fields", makeAICustomComponentFieldsProperties(), {
            allowEmpty: true,
        })
        .withArray("actions", makeActionWithTitleProperties(), {
            allowEmpty: true,
        });

export type AICustomComponentHistoryItem = DescriptionOfProperties<ReturnType<typeof makeAICustomComponentHistoryItem>>;

// TODO: Rename to `AIComponent`
export const AICustomChatComponent = component(WireComponentKind.AICustomChatComponent)
    .withIcon("mt-component-chat")
    .withDisplayName("Custom")
    .withDescription("Chat with an AI that can build and edit components")
    .withPrompt(
        "AICustomComponent allows to generate UI components that are not available natively in Glide (e.g. stepped progress bars, dense details overview, collapsible blocks)"
    )
    .withIcon("mt-ai")
    .withBeta(true)
    .withFeatureSetting("aiFeaturesAndTraining")
    .withCustomConfigurator({
        kind: PropertyConfiguratorKind.CustomAIComponentPrompt,
        section: { name: "", collapsed: false, order: 0 },
    })
    .withProperties(
        propertiesWithCSS
            .withText("prompt", {
                displayName: "Prompt",
                required: true,
                isMultiLine: true,
                allowColumn: false,
                propertySection: PropertySection.Hidden,
                builderOnly: true,
                description: "The user's prompt to the AI (primarily used within the custom configurator UI).",
            })
            .withText("uuid", {
                displayName: "ID",
                emptyByDefault: true,
                propertySection: PropertySection.Hidden,
            })
            .withEnum<"type", AICustomComponentCommitType>("type", commitType, {
                emptyByDefault: true,
                propertySection: PropertySection.Hidden,
            })
            .withSwitch("useAppDescHistory", true, {
                propertySection: PropertySection.Hidden,
            })
            .withArray("fields", makeAICustomComponentFieldsProperties(), {
                style: ArrayPropertyStyle.KeyValue,
                propertySection: PropertySection.Data,
                allowEmpty: true,
                description:
                    "Data fields provided to the AI-generated component. Can be bound to data, or entered statically by the builder.",
                when: (_d, _r, _t, adc) => isAIEnabled(adc),
            })
            .withArray("actions", makeActionWithTitleProperties(), {
                propertySection: PropertySection.Action,
                style: ArrayPropertyStyle.ActionArray,
                removeIfMissing: removeIfMissingInActionWithTitle,
                allowEmpty: true,
                description:
                    "Actions that can be configured and potentially triggered from within the AI-generated component.",
                when: (_d, _r, _t, adc) => isAIEnabled(adc),
            })
            .withArray("history", makeAICustomComponentHistoryItem(), {
                propertySection: PropertySection.Hidden,
                allowEmpty: true,
                builderOnly: true,
            })
            .withEnum(
                "generator",
                [
                    {
                        label: "AI v2",
                        value: "ai-gpt-4o-v2",
                    },
                    {
                        label: "Claude",
                        value: "ai-claude-v1",
                    },
                    {
                        label: "AI v1",
                        value: "ai-gpt-4o" as string,
                    },
                    {
                        label: "HTML",
                        value: "html-tailwind-alpine",
                    },
                ],
                {
                    enumVisual: "small-images",
                    displayName: "Generator",
                    defaultValue: "ai-claude-v1",
                    propertySection: PropertySection.Hidden,
                }
            )
    );
export type WireAICustomChatComponent = WirePropertiesOfComponent<typeof AICustomChatComponent>;
export type WireAICustomChatComponentDescription = DescriptionOfComponent<typeof AICustomChatComponent>;

export const InlineScanner = component(WireComponentKind.InlineScanner)
    .withIcon("mt-barcode-scanner")
    .withPluginTier("business")
    .withBillablesConsumed(adc => {
        if (hasCustomKeyForDynamsoftScanner(adc.userFeatures)) {
            return undefined;
        } else if (mustUseDynamsoftScanner(AppKind.Page)) {
            return dynamsoftScannerNumUpdates;
        } else {
            return undefined;
        }
    })
    .withDisplayName("Scanner")
    .withDescription("Scan barcodes and QR codes")
    .withPrompt(
        "Scanner is for scanning barcodes and QR codes (e.g. inventory tracking, check-in systems, product lookup). It's only available on Glide's Business plan."
    )
    .withProperties(
        propertiesWithCSS
            .withEditableValue(
                "writeTo",
                "string",
                {
                    displayName: "Write to column",
                    required: true,
                    description: "The column where the scanned barcode or QR code data will be saved.",
                },
                { isDisplayed: false }
            )
            .withAction("action", {
                propertySection: PropertySection.ScanningOptions,
                description: "An optional action triggered after a successful scan.",
            })
    )
    .withUserFeature(
        userFeatures => mustUseDynamsoftScanner(AppKind.Page) || hasCustomKeyForDynamsoftScanner(userFeatures)
    );

export const SignatureField = component(WireComponentKind.SignatureField)
    .withIcon("co-signature")
    .withDisplayName("Signature")
    .withDescription("Lets the app user collect signatures")
    .withPrompt("SignatureField is for capturing signatures (e.g. approvals, agreements, forms)")
    .withProperties(
        propertiesWithCSS
            .withEditableValue(
                "signature",
                "image-uri",
                {
                    displayName: "Signature",
                    required: true,
                    description: "The column where the captured signature image will be stored.",
                },
                { isDisplayed: false }
            )
            .withText("title", {
                defaultValue: "Signature",
                propertySection: PropertySection.Design,
                useTemplate: "withLabel",
                description: "A label displayed above the signature pad.",
            })
            .withSwitch("isRequired", false, {
                propertySection: PropertySection.Options,
                displayName: "Required",
                description: "Makes capturing a signature mandatory before proceeding (e.g., in a form).",
            })
    );
export type WireSignatureFieldComponent = WirePropertiesOfComponent<typeof SignatureField>;
export type WireSignatureFieldDescription = DescriptionOfComponent<typeof SignatureField>;

export const MapCollection = arrayContent(ArrayScreenFormat.PagesSimpleMap)
    .withPaging(1000)
    .withDisplayName("Map")
    .withComponentProperties(
        propertiesWithCSS
            .withText("componentTitle", {
                displayName: "Title",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                emptyByDefault: true,
                useTemplate: "withLabel",
                description: "An optional title displayed above the map.",
            })
            .withArray("titleActions", makeActionWithTitleProperties(), {
                ...titleActionsOptions,
                description: "Actions displayed in the title bar area of the map component.",
            })
            .withEnum(
                "aspectRatio",
                [
                    // map aspects changed from fixed ratios to values that can adjust to larger screens
                    {
                        label: "Large",
                        value: UIMapAspect.Large,
                        icon: "borderSquare",
                    },
                    {
                        label: "Medium",
                        value: UIMapAspect.Medium,
                        icon: "border4by3",
                    },
                    {
                        label: "Small",
                        value: UIMapAspect.Small,
                        // (ben): not sure what needs fixing, but there is a fixme here.
                        icon: "border3by2", //FIXME
                    },
                ],
                {
                    enumVisual: "small-images",
                    displayName: "Size",
                    defaultValue: UIMapAspect.Medium,
                    propertySection: PropertySection.Design,
                    description: "Controls the height of the map display area.",
                }
            )
            .withEnum(
                "style",
                [
                    {
                        label: "Minimal",
                        value: "minimal",
                    },
                    {
                        label: "Standard",
                        value: "standard",
                    },
                    {
                        label: "Satellite",
                        value: "satellite",
                    },
                ],
                {
                    propertySection: PropertySection.Design,
                    defaultValue: "minimal",
                    description: "The visual style of the map tiles (Minimal, Standard, Satellite).",
                }
            )
            .withEnum(
                "tooltip",
                [
                    {
                        label: "Bottom",
                        value: "bottom",
                    },
                    {
                        label: "Floating",
                        value: "near",
                    },
                ],
                {
                    propertySection: PropertySection.Design,
                    defaultValue: "near",
                    description:
                        "Determines how the tooltip for a map pin is displayed (Attached to bottom or Floating near pin).",
                }
            )
            .withEnum(
                "mobileView",
                [
                    {
                        label: "Inline",
                        value: "withMargins",
                    },
                    {
                        label: "Full Width",
                        value: "fullWidth",
                    },
                ],
                {
                    propertySection: PropertySection.Design,
                    defaultValue: "withMargins",
                    displayName: "Mobile View",
                    enumVisual: "text",
                    description: "Controls whether the map takes full width or has margins on mobile devices.",
                }
            )
            .withSwitch("showNavigationControls", false, {
                propertySection: PropertySection.Design,
                displayName: "Show navigation controls",
                description: "Displays zoom and compass controls on the map.",
            })
            .withSwitch("enableScrollWheelZoom", true, {
                propertySection: PropertySection.Design,
                displayName: "Allow zoom with scroll-wheel",
                description: "Allows zooming the map using the mouse scroll wheel or trackpad.",
            })
    )
    .withItemProperties(p =>
        p
            .withText("title", {
                displayName: "Title",
                propertySection: PropertySection.CollectionItems,
                emptyByDefault: true,
                useTemplate: "withLabel",
                description: "The primary text displayed in the map pin's tooltip or info window.",
            })
            .withText("subtitle", {
                displayName: "Description",
                propertySection: PropertySection.CollectionItems,
                emptyByDefault: true,
                useTemplate: "withLabel",
                description: "Secondary text displayed in the map pin's tooltip/info window.",
            })
            .withText("location", {
                displayName: "Address",
                propertySection: PropertySection.CollectionItems,
                useTemplate: "withLabel",
                description: "The column containing the address or coordinates for placing the pin.",
            })
            .withImage("image", {
                propertySection: PropertySection.CollectionItems,
                subcomponent: {
                    important: true,
                    name: "Image",
                },
                useTemplate: "withLabel",
                description: "An optional image displayed in the map pin's tooltip/info window.",
            })
            .withAction("action", {
                propertySection: PropertySection.ItemClick,
                emptyByDefault: false,
                subcomponent: {
                    important: true,
                    name: "Actions",
                },
                description: "The action executed when a map pin or its tooltip/info window is clicked.",
            })
    )
    .withSpecialCases([
        {
            name: "Map",
            analyticsName: "map",
            description: "An interactive map",
            img: "mt-component-map",
            group: "Content",
            appKinds: AppKind.Page,
        },
    ])
    .withQuotaKey();

export type MapCollectionComponentDescription = DescriptionOfArrayContentInlineList<typeof MapCollection>;
export type WireListMapCollection = WirePropertiesOfArrayContent<typeof MapCollection>;

export const CalendarCollection = arrayContent(ArrayScreenFormat.CalendarCollection)
    .withGrouping(GroupingSupport.InMemory)
    .withDisplayName("Collection")
    .withQueryableTables(QueryableTableSupport.LoadAll, true)
    .withComponentProperties(
        propertiesWithCSS
            .withEnum(
                "titleStyle",
                [
                    {
                        label: "Simple",
                        value: UITitleStyle.Simple,
                    },
                    {
                        label: "Bold",
                        value: UITitleStyle.Bold,
                    },
                ],
                {
                    defaultValue: UITitleStyle.Bold,
                    propertySection: PropertySection.Design,
                    when: (_a, _b, _c, _d, isFirstComponent) => isFirstComponent !== false,
                }
            )
            .withImage("titleImage", {
                propertySection: PropertySection.Design,
                useTemplate: "withLabel",
                when: (c, _d, _e, _f, isFirstComponent) =>
                    isFirstComponent !== false && getEnumProperty(c?.titleStyle) === UITitleStyle.Hero,
            })
            .withText("componentTitle", {
                displayName: "Title",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                emptyByDefault: true,
                isComponentTitle: true,
                useTemplate: "withLabel",
            })
            .withArray("titleActions", makeActionWithTitleProperties(), titleActionsOptions)
            .withSwitch("allowAdd", true, {
                displayName: "Allow adding events",
                propertySection: PropertySection.InlineActions,
                disableForEasyCRUD: true,
                withCondition: true,
                icon: "st-plus-stroke",
            })
            .withEnum(
                "defaultMode",
                [
                    {
                        label: "Month",
                        value: "month",
                    },
                    {
                        label: "Week",
                        value: "week",
                    },
                    {
                        label: "Day",
                        value: "day",
                    },
                ],
                {
                    propertySection: PropertySection.Design,
                }
            )
            .withDateTime("defaultDate", {
                propertySection: PropertySection.Design,
                emptyByDefault: true,
                enumCase: {
                    kind: PropertyKind.Enum,
                    menuLabel: "Default Date",
                    cases: [
                        {
                            value: CalendarDefaultDate.Today,
                            label: "Today",
                        },
                        {
                            value: CalendarDefaultDate.Earliest,
                            label: "Earliest",
                        },
                        {
                            value: CalendarDefaultDate.Latest,
                            label: "Latest",
                        },
                    ],
                    visual: "dropdown",
                },
            })
    )
    .withItemProperties(p =>
        p
            .withText("title", {
                propertySection: PropertySection.CollectionItems,
                easyTabConfiguration: { propertySection: PropertySection.Content },
                useTemplate: "withLabel",
            })
            .withDateTime("startDate", {
                propertySection: PropertySection.CollectionItems,
                displayName: "Start Time",
                required: true,
                willBeQueried: true,
                preferredNames: ["start", "begin", "date"],
                easyTabConfiguration: { propertySection: PropertySection.Content },
            })
            .withDateTime("endDate", {
                propertySection: PropertySection.CollectionItems,
                displayName: "End Time",
                willBeQueried: true,
                preferredNames: ["end", "stop"],
                easyTabConfiguration: { propertySection: PropertySection.Content },
            })
            .withSwitch("allowEdit", true, {
                displayName: "Allow changing the time of events",
                propertySection: PropertySection.InlineActions,
                disableForEasyCRUD: true,
                withCondition: true,
                icon: "st-edit-pencil",
            })
            .withAction("action", {
                propertySection: PropertySection.ItemClick,
                defaultAction: true,
                disableForEasyCRUD: true,
            })
    )
    .withSpecialCases([
        {
            name: "Calendar",
            analyticsName: "collection-calendar",
            description: "A way to visualize events",
            img: "mt-component-calendar",
            group: "Collections",
            appKinds: AppKind.Page,
            // This is a very hacky way to set this property to an enum
            // default.  We should really have a way for the property
            // descriptor to give its default value, vs each descriptor case
            // having a default value.
            setAsCurrent: desc => {
                return {
                    ...desc,
                    defaultDate: makeEnumProperty(CalendarDefaultDate.Today),
                    format: makeEnumProperty(ArrayScreenFormat.CalendarCollection),
                };
            },
        },
    ])
    .withEasyCRUD({
        withDelete: false,
        withConditions: true,
        withAddTitle: false,
        withEditTitle: false,
        updateSource() {
            return { action: undefined };
        },
        lower(desc, flags) {
            let updates: Partial<typeof desc> = {};

            updates = {
                ...updates,
                allowAdd: makeSwitchProperty(flags.add !== undefined, flags.add?.condition),
                allowEdit: makeSwitchProperty(flags.edit !== undefined, flags.edit?.condition),
                titleActions: undefined,
            };

            // Ideally, we would like to open the edit screen when the edit
            // condition is true.  The problem is that our action condition
            // system cannot express "if condition is true, push an edit
            // screen, otherwise push a detail screen".  We can express that
            // with custom actions, but getting those in here would require a
            // lot more infrastructure, because they're not just local to the
            // app description but live in their own documents in Firestore.
            // The compromise here is to open the edit screen when editing is
            // enabled without a condition.  If a condition is set on editing,
            // we open the detail screen, like we do when editing is turned
            // off.
            if (flags.edit !== undefined && flags.edit.condition === undefined) {
                updates = {
                    ...updates,
                    action: makeActionProperty({
                        kind: ActionKind.PushEditScreen,
                        navigationTarget: makeEnumProperty(PageScreenTarget.SmallModal),
                    } as ActionDescription),
                };
            } else {
                updates = {
                    ...updates,
                    action: makeActionProperty({
                        kind: ActionKind.PushDetailScreen,
                        navigationTarget: makeEnumProperty(PageScreenTarget.SmallModal),
                    } as ActionDescription),
                };
            }

            return updates;
        },
    });

export type CalendarCollectionComponentDescription = DescriptionOfArrayContentInlineList<typeof CalendarCollection>;

function makeDefaultEasyCrudProperties() {
    return {
        easyCRUDAdd: makeSwitchProperty(true),
        easyCRUDEdit: makeSwitchProperty(true),
        easyCRUDDelete: makeSwitchProperty(false),
    };
}

export function isCardStyle(style: CardStyle | undefined): style is CardStyle.Card | CardStyle.Cover {
    return style === CardStyle.Card || style === CardStyle.Cover;
}

export const CardCollection = arrayContent(ArrayScreenFormat.CardCollection)
    .withGrouping(GroupingSupport.Regular)
    .withPaging("custom")
    .withDisplayName("Collection")
    .withComponentProperties(
        propertiesWithCSS
            .withEnum(
                "cardStyle",
                [
                    { value: CardStyle.Cover, label: "Grid", icon: "mt-component-collection-grid" },
                    { value: CardStyle.List, label: "List", icon: "mt-component-collection-list" },
                    { value: CardStyle.Table, label: "Table", icon: "mt-component-collection-table" },
                    { value: CardStyle.Checklist, label: "Checklist", icon: "mt-component-checklist" },
                ],
                {
                    // We still need this property but is controlled by inline-list now.
                    // We let you also change to Kanban, Calendar, Map, New Table...
                    propertySection: PropertySection.Hidden,
                    enumVisual: "large-images",
                }
            )
            .withEnum(
                "cardCollectionComponentStyle",
                [
                    { value: UIStyleVariant.Minimal, label: "Minimal" },
                    { value: UIStyleVariant.Default, label: "Card" },
                ],
                {
                    displayName: "Style",
                    enumVisual: "text",
                    defaultValue: UIStyleVariant.Minimal,
                    propertySection: PropertySection.Design,

                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        return style === CardStyle.Cover;
                    },
                }
            )
            .withEnum(
                "size",
                [
                    { value: UISize.XSmall, label: "XS" },
                    { value: UISize.Small, label: "S" },
                    { value: UISize.Medium, label: "M" },
                    { value: UISize.Large, label: "L" },
                    { value: UISize.XLarge, label: "XL" },
                ],
                {
                    defaultValue: UISize.Medium,
                    enumVisual: "text",
                    propertySection: PropertySection.Design,
                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        return isCardStyle(style);
                    },
                }
            )
            .withEnum(
                "style",
                [
                    { value: UIStyleVariant.Default, label: "Default" },
                    { value: UIStyleVariant.Minimal, label: "Minimal" },
                ],
                {
                    enumVisual: "text",
                    defaultValue: UIStyleVariant.Default,
                    propertySection: PropertySection.Design,

                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        return style === CardStyle.Table;
                    },
                }
            )
            .withEnum(
                "layoutVariant",
                [
                    { value: UILayoutVariant.Default, label: "Default" },
                    { value: UILayoutVariant.Compact, label: "Compact" },
                ],
                {
                    enumVisual: "text",
                    defaultValue: UILayoutVariant.Default,
                    propertySection: PropertySection.Design,
                    displayName: "Size",

                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        return style === CardStyle.List;
                    },
                }
            )
            // We're changing this only on list as part of the new styles.
            // If this gets growing to ohter colletions, we should consider also rename UIStyleVariant
            .withEnum(
                "style",
                [
                    { value: UIStyleVariant.Minimal, label: "Default" },
                    { value: UIStyleVariant.Default, label: "Card" },
                ],
                {
                    enumVisual: "text",
                    defaultValue: UIStyleVariant.Minimal,
                    propertySection: PropertySection.Design,

                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        return style === CardStyle.List;
                    },
                }
            )
            .withEnum(
                "imageStyle",
                [
                    {
                        label: "Square",
                        value: UIImageStyle.Rectilinear,
                        icon: "borderSquare",
                    },
                    {
                        label: "Circle",
                        value: UIImageStyle.Circle,
                        icon: "borderCircle",
                    },
                ],
                {
                    enumVisual: "text",
                    defaultValue: UIImageStyle.Rectilinear,
                    propertySection: PropertySection.Design,
                    when: d => getEnumProperty(d.cardStyle) !== CardStyle.Checklist,
                }
            )
            .withEnum(
                "imageSize",
                [
                    { value: UISize.XSmall, label: "XSmall" },
                    { value: UISize.Small, label: "Small" },
                    { value: UISize.Medium, label: "Medium" },
                    { value: UISize.Large, label: "Large" },
                ],
                {
                    defaultValue: UISize.Medium,
                    propertySection: PropertySection.Design,

                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        const imageStyle = getEnumProperty<UIImageStyle>(d.imageStyle);
                        return isCardStyle(style) && imageStyle === UIImageStyle.Circle;
                    },
                }
            )
            .withEnum(
                "alignment",
                [
                    { value: UIAlignment.Start, label: "Left" },
                    { value: UIAlignment.Center, label: "Center" },
                ],
                {
                    defaultValue: UIAlignment.Center,
                    propertySection: PropertySection.Design,

                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        const imageStyle = getEnumProperty<UIImageStyle>(d.imageStyle);
                        return isCardStyle(style) && imageStyle === UIImageStyle.Circle;
                    },
                }
            )
            .withEnum(
                "imageFill",
                [
                    {
                        label: "Fill",
                        value: UIImageFill.Cover,
                    },
                    {
                        label: "Fit",
                        value: UIImageFill.Contain,
                    },
                ],
                {
                    enumVisual: "text",
                    defaultValue: UIImageFill.Cover,
                    propertySection: PropertySection.Design,
                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        const cardCollectionComponentStyle = getEnumProperty<UIStyleVariant>(
                            d.cardCollectionComponentStyle
                        );
                        return style === CardStyle.Card || cardCollectionComponentStyle === UIStyleVariant.Default;
                    },
                }
            )
            .withEnum(
                "aspectRatio",
                [
                    {
                        label: "Square",
                        value: UIAspect.Square,
                        icon: "borderSquare",
                    },
                    {
                        label: "4:3",
                        value: UIAspect.FourByThree,
                        icon: "border4by3",
                    },
                    {
                        label: "3:2",
                        value: UIAspect.ThreeByTwo,
                        icon: "border3by2",
                    },
                    {
                        label: "16:9",
                        value: UIAspect.SixteenByNine,
                        icon: "border3by1", //FIXME
                    },
                ],
                {
                    enumVisual: "small-images",
                    defaultValue: UIAspect.FourByThree,
                    propertySection: PropertySection.Design,
                    when: d => {
                        const style = getEnumProperty<CardStyle>(d.cardStyle);
                        const imageStyle = getEnumProperty<UIImageStyle>(d.imageStyle);
                        return isCardStyle(style) && imageStyle !== UIImageStyle.Circle;
                    },
                }
            )
            .withEnum("orientation", [UIOrientation.Vertical, UIOrientation.Horizontal] as const, {
                defaultValue: UIOrientation.Vertical,
                enumVisual: "text",
                propertySection: PropertySection.Design,
                when: d => {
                    const style = getEnumProperty<CardStyle>(d.cardStyle);
                    const supportsHorizontal = isCardStyle(style);
                    return supportsHorizontal && getFeatureSetting("horizontalCollection");
                },
            })
            .withSwitch("showSeeAll", true, {
                propertySection: PropertySection.Options,
                when: d => {
                    const style = getEnumProperty<CardStyle>(d.cardStyle);
                    const supportsHorizontal = isCardStyle(style);
                    return supportsHorizontal && getEnumProperty(d.orientation) === UIOrientation.Horizontal;
                },
            })
            .withEnum(
                "titleStyle",
                [
                    {
                        label: "Simple",
                        value: UITitleStyle.Simple,
                    },
                    {
                        label: "Bold",
                        value: UITitleStyle.Bold,
                    },
                ],
                {
                    enumVisual: "text",
                    defaultValue: UITitleStyle.Bold,
                    propertySection: PropertySection.Design,
                    when: (_a, _b, _c, adc, isFirstComponent) => {
                        const hasDesktopSidebar: boolean = adc.appDescription?.theme.showDesktopSideBar ?? false;
                        return isFirstComponent !== false && !hasDesktopSidebar;
                    },
                }
            )
            .withImage("titleImage", {
                propertySection: PropertySection.Design,
                useTemplate: "withLabel",
                when: (c, _d, _e, _f, isFirstComponent) => {
                    const titleImage: string | undefined = getStringProperty((c as any)?.titleImage);
                    const hasImage = isDefined(titleImage) && titleImage !== "";
                    return (
                        isFirstComponent !== false && hasImage && getEnumProperty(c?.titleStyle) === UITitleStyle.Hero
                    );
                },
            })
            .withText("componentTitle", {
                displayName: "Title",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                emptyByDefault: true,
                isComponentTitle: true,
                useTemplate: "withLabel",
            })
            .withArray("titleActions", makeActionWithTitleProperties(), titleActionsOptions)
            .withText("headerTitle", {
                displayName: "Title",
                useTemplate: "withLabel",
                propertySection: PropertySection.ColumnHeaders,
                emptyByDefault: true,
                when: c => getEnumProperty(c?.cardStyle) === CardStyle.Table,
            })
            .withText("headerSubtitle", {
                displayName: "Description",
                propertySection: PropertySection.ColumnHeaders,
                emptyByDefault: true,
                useTemplate: "withLabel",
                when: c => getEnumProperty(c?.cardStyle) === CardStyle.Table,
            })
            .withText("headerEmphasis", {
                displayName: "Meta",
                propertySection: PropertySection.ColumnHeaders,
                emptyByDefault: true,
                useTemplate: "withLabel",
                when: c => getEnumProperty(c?.cardStyle) === CardStyle.Table,
            })
    )
    .withItemProperties(p =>
        p
            .withText("title", {
                propertySection: PropertySection.CollectionItems,
                useTemplate: "withLabel",
                subcomponent: {
                    important: true,
                    name: "Content",
                },
                easyTabConfiguration: { propertySection: PropertySection.Content },
            })
            .withText("subtitle", {
                displayName: "Description",
                useTemplate: "withLabel",
                propertySection: PropertySection.CollectionItems,
                subcomponent: {
                    important: true,
                    name: "Content",
                },
                easyTabConfiguration: { propertySection: PropertySection.Content },
            })
            .withText("emphasis", {
                preferredNames: titleProperties,
                useTemplate: "withLabel",
                propertySection: PropertySection.CollectionItems,
                displayName: "Meta",
                emptyByDefault: true,
                subcomponent: {
                    important: true,
                    name: "Content",
                },
                easyTabConfiguration: { propertySection: PropertySection.Content },
                when: (_, rootDesc) => getEnumProperty(rootDesc?.cardStyle) !== CardStyle.Checklist,
            })
            .withImage("image", {
                propertySection: PropertySection.CollectionItems,
                useTemplate: "withLabel",
                subcomponent: {
                    important: true,
                    name: "Image",
                },
                easyTabConfiguration: { propertySection: PropertySection.Content },
                when: (_, rootDesc) => getEnumProperty(rootDesc?.cardStyle) !== CardStyle.Checklist,
            })
            .withEditableValue(
                "checked",
                "boolean",
                {
                    displayName: "Checked",
                    propertySection: PropertySection.CollectionItems,
                    easyTabConfiguration: { propertySection: PropertySection.Content },
                    when: (_, rootDesc) => getEnumProperty(rootDesc?.cardStyle) === CardStyle.Checklist,
                },
                {}
            )
            .withAction("action", {
                propertySection: PropertySection.ItemClick,
                defaultAction: true,
                subcomponent: {
                    important: true,
                    name: "Actions",
                },
                disableForEasyCRUD: true,
            })
            .withArray("itemActions", makeActionWithTitleProperties(), {
                ...itemActionsOptions,
                specialItems: 2,
                addItemLabels: primarySecondaryAddActionLabels,
                disableForEasyCRUD: true,
            })
    )
    .withSpecialCases([
        {
            name: "Card",
            description: "A collection of cards",
            analyticsName: "collection-card-legacy",
            img: "mt-component-collection-cards",
            group: "Collections",
            appKinds: AppKind.Page,
            getIsCurrent: d => getEnumProperty((d as any).cardStyle) === CardStyle.Card,
            setAsCurrent: desc => ({
                ...desc,
                ...makeDefaultEasyCrudProperties(),
                cardStyle: makeEnumProperty(CardStyle.Cover),
            }),
            getIsHidden: () => true,
        },
        {
            name: "Card",
            description: "A collection of cards",
            analyticsName: "collection-card",
            img: "mt-component-collection-grid",
            group: "Collections",
            appKinds: AppKind.Page,
            getIsCurrent: d => getEnumProperty((d as any).cardStyle) === CardStyle.Cover,
            setAsCurrent: desc => ({
                ...desc,
                cardStyle: makeEnumProperty(CardStyle.Cover),
                format: makeEnumProperty(ArrayScreenFormat.CardCollection),
            }),
        },
        {
            name: "List",
            description: "A list",
            analyticsName: "collection-list",
            img: "mt-component-collection-list",
            group: "Collections",
            appKinds: AppKind.Page,
            getIsCurrent: d => getEnumProperty((d as any).cardStyle) === CardStyle.List,
            setAsCurrent: desc => ({
                ...desc,
                cardStyle: makeEnumProperty(CardStyle.List),
                format: makeEnumProperty(ArrayScreenFormat.CardCollection),
            }),
        },
        {
            name: "Table ",
            description: "A table",
            analyticsName: "collection-table",
            img: "mt-component-collection-table",
            group: "Collections",
            getIsHidden: () => true,
            appKinds: AppKind.Page,
            getIsCurrent: d => getEnumProperty((d as any).cardStyle) === CardStyle.Table,
            setAsCurrent: (desc, tables, itemTable, adc, mutatingScreenKind) => {
                const iccc = promoteAppDescriptionContext(adc);
                const titleActions: Description[] = [];
                const itemActions: Description[] = [];
                if (tables !== undefined && itemTable !== undefined && iccc !== undefined) {
                    const addAction = iccc.makeFormActionForTables(makeInputOutputTables(tables.input, itemTable));
                    if (addAction !== undefined) {
                        titleActions.push(makeActionWithTitle("Add", addAction, getGlideIcon("st-plus-add")));
                    }

                    const itemTables = makeInputOutputTables(itemTable);
                    const editAction = makeEditAction(iccc, itemTables, mutatingScreenKind);
                    if (editAction !== undefined) {
                        itemActions.push(makeActionWithTitle("Edit", editAction));
                    }

                    const showAction = makeQuickLookAction(iccc, itemTables, mutatingScreenKind);
                    if (showAction !== undefined) {
                        itemActions.push(makeActionWithTitle("Quick look", showAction));
                    }
                }

                return {
                    ...desc,
                    ...makeDefaultEasyCrudProperties(),
                    cardStyle: makeEnumProperty(CardStyle.Table),
                    allowSearch: makeSwitchProperty(true),
                    titleActions: makeArrayProperty(titleActions),
                    itemActions: makeArrayProperty(itemActions),
                };
            },
        },
        {
            name: "Checklist",
            description: "A checklist",
            analyticsName: "collection-checklist",
            img: "mt-component-checklist",
            group: "Collections",
            appKinds: AppKind.Page,
            getIsCurrent: d => getEnumProperty((d as any).cardStyle) === CardStyle.Checklist,
            setAsCurrent: desc => {
                return {
                    ...desc,
                    cardStyle: makeEnumProperty(CardStyle.Checklist),
                    format: makeEnumProperty(ArrayScreenFormat.CardCollection),
                    itemsActions: undefined,
                    easyCRUDAdd: makeSwitchProperty(true),
                    easyCRUDEdit: makeSwitchProperty(false),
                    easyCRUDDelete: makeSwitchProperty(false),
                };
            },
        },
    ])
    .withEasyCRUD({
        withDelete: true,
        withConditions: true,
        updateSource(_desc, screenTables, itemTable, iccc) {
            const addAction = iccc.makeFormActionForTables(makeInputOutputTables(screenTables.input, itemTable));
            return {
                titleActions: definedMap(addAction, a =>
                    makeArrayProperty([makeActionWithTitle("Add", a, getGlideIcon("st-plus-add"))])
                ),
                itemActions: undefined,
                action: undefined,
            };
        },
        lower(desc, flags, forGC) {
            let updates: Partial<typeof desc> = {};

            updates = { ...updates, ...makeUpdateForEasyCRUDTitleAction(desc, flags, forGC) };

            const editAction = addConditionToActionWithTitleAndIcon(
                makeActionWithTitle("Edit", { kind: ActionKind.PushEditScreen }),
                flags.edit,
                getGlideIcon("st-edit-pencil")
            );
            const deleteAction = addConditionToActionWithTitleAndIcon(
                makeActionWithTitle("Delete", { kind: ActionKind.DeleteRow }),
                flags.delete,
                getGlideIcon("st-trash")
            );
            if (editAction !== undefined || deleteAction !== undefined) {
                const itemActions: Description[] = [{}, {}];
                if (editAction !== undefined) {
                    itemActions.push(editAction);
                }
                if (deleteAction !== undefined) {
                    itemActions.push(deleteAction);
                }
                updates = { ...updates, itemActions: makeArrayProperty(itemActions) };
            } else {
                updates = { ...updates, itemActions: undefined };
            }

            updates = { ...updates, action: makeActionProperty({ kind: ActionKind.PushDetailScreen }) };

            return updates;
        },
    });
export type CardCollectionContentDescription = DescriptionOfArrayContent<typeof CardCollection>;
export type CardCollectionComponentDescription = DescriptionOfArrayContentInlineList<typeof CardCollection>;
export type WireListCardCollection = WirePropertiesOfArrayContent<typeof CardCollection>;

const kanbanCategoryProperties = properties
    .withText("value", { emptyByDefault: true, placeholder: "Value" })
    .withText("title", { emptyByDefault: true, placeholder: "Title" });
export type KanbanCategoryDescription = DescriptionOfProperties<typeof kanbanCategoryProperties>;

export const Kanban = arrayContent(ArrayScreenFormat.Kanban)
    .withDisplayName("Kanban")
    .withHandlesSorting()
    .withCustomFiltering()
    .withCanDeleteRows()
    .withQueryableTables(QueryableTableSupport.LoadAll, true)
    .withComponentProperties(
        propertiesWithCSS
            .withText("componentTitle", {
                displayName: "Title",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                emptyByDefault: true,
                isComponentTitle: true,
                useTemplate: "withLabel",
            })
            .withArray("categories", kanbanCategoryProperties, {
                style: ArrayPropertyStyle.KeyValue,
                preferredNames: ["state", "status", "category", "progress"],
                allowEmpty: true,
                propertySection: {
                    name: "Custom groups",
                    order: -20,
                },
                addItem: arr => {
                    if (arr.length === 0) {
                        return [{ title: makeStringProperty(getLocalizedString("uncategorized", AppKind.Page)) }, {}];
                    } else {
                        return [{}];
                    }
                },
            })
            .withArray("titleActions", makeActionWithTitleProperties(), titleActionsOptions)
            .withEnum(
                "titleStyle",
                [
                    {
                        label: "Simple",
                        value: UITitleStyle.Simple,
                    },
                    {
                        label: "Bold",
                        value: UITitleStyle.Bold,
                    },
                ],
                {
                    defaultValue: UITitleStyle.Bold,
                    propertySection: PropertySection.Design,
                    when: (_a, _b, _c, _d, isFirstComponent) => isFirstComponent !== false,
                }
            )
            .withImage("titleImage", {
                useTemplate: "withLabel",
                propertySection: PropertySection.Design,
                when: (c, _d, _e, _f, isFirstComponent) =>
                    isFirstComponent !== false && getEnumProperty(c?.titleStyle) === UITitleStyle.Hero,
            })
            .withSwitch("allowInlineAdding", true, {
                displayName: "Allow inline adding",
                propertySection: PropertySection.InlineActions,
                addAtBottom: true,
                disableForEasyCRUD: true,
                withCondition: true,
                icon: "st-plus-stroke",
            })
            .withSwitch("allowInlineEditing", true, {
                displayName: "Allow inline editing",
                propertySection: PropertySection.InlineActions,
                addAtBottom: true,
                disableForEasyCRUD: true,
                withCondition: true,
                icon: "st-edit-pencil",
            })
    )
    .withItemProperties(p =>
        p
            .withText("title", {
                propertySection: PropertySection.CollectionItems,
                preferredNames: titleProperties,
                // FIXME: all of these should only be reported as edited/added
                // if editing/adding is actually enabled.
                isEditedInApp: "if-writable",
                isAddedInApp: true,
                useTemplate: "withLabel",
                easyTabConfiguration: { propertySection: PropertySection.Content },
            })
            .withText("subtitle", {
                displayName: "Description",
                emptyByDefault: true,
                propertySection: PropertySection.CollectionItems,
                isEditedInApp: "if-writable",
                isAddedInApp: true,
                useTemplate: "withLabel",
                easyTabConfiguration: { propertySection: PropertySection.Content },
            })
            .withImage("image", {
                propertySection: PropertySection.CollectionItems,
                useTemplate: "withLabel",
                easyTabConfiguration: { propertySection: PropertySection.Content },
            })
            .withText("category", {
                displayName: "Group by",
                propertySection: PropertySection.Configuration,
                allowLiteral: false,
                isEditedInApp: "if-writable",
                isAddedInApp: true,
            })
            .withText("index", {
                displayName: "Save order",
                propertySection: PropertySection.Configuration,
                emptyByDefault: true,
                isEditedInApp: true,
                helpText: "Kanban uses this column to save the order of cards within each column.",
                emptyWarningText: "Assign this to save the card order for all users.",
                isAddedInApp: true,
            })
            .withAction("action", {
                propertySection: PropertySection.ItemClick,
                emptyByDefault: false,
                disableForEasyCRUD: true,
            })
            .withAction("moveAction", {
                propertySection: PropertySection.ItemMove,
                emptyByDefault: true,
                disableForEasyCRUD: true,
            })
            .withArray("itemActions", makeActionWithTitleProperties(), {
                ...itemActionsOptions,
                disableForEasyCRUD: true,
            })
    )
    .withExtraPropertyDescriptors(getPropertyTable => [
        makeDynamicFilterColumnPropertyHandler(getPropertyTable, "Tags", PropertySection.CollectionItems, [
            ColumnPropertyFlag.AddedInApp,
        ]),
    ])
    .withSpecialCases([
        {
            name: "Kanban",
            analyticsName: "collection-kanban",
            description: "A kanban issue tracking board",
            img: "mt-kanban",
            group: "Collections",
            appKinds: AppKind.Page,
            setAsCurrent: (desc, _tables, itemTable, adc, mutatingScreenKind) => {
                const iccc = promoteAppDescriptionContext(adc);
                const itemActions: ActionWithTitleDescription[] = [];
                let action: ActionDescription | undefined;

                if (itemTable !== undefined && iccc !== undefined) {
                    const itemTables = makeInputOutputTables(itemTable);
                    action = makeQuickLookAction(iccc, itemTables, mutatingScreenKind);

                    const editAction = makeEditAction(iccc, itemTables, mutatingScreenKind);
                    if (editAction !== undefined) {
                        itemActions.push({
                            title: makeStringProperty("Edit"),
                            icon: undefined,
                            action: makeActionProperty(editAction),
                        });
                    }

                    const deleteAction = iccc.makeAction(ActionKind.DeleteRow, itemTables, mutatingScreenKind);
                    if (deleteAction !== undefined) {
                        itemActions.push({
                            title: makeStringProperty("Delete"),
                            action: makeActionProperty(deleteAction),
                            icon: undefined,
                        });
                    }
                }

                return {
                    ...desc,
                    action: definedMap(action, makeActionProperty) ?? (desc as any).action,
                    itemActions: definedMap(itemActions, makeArrayProperty) ?? (desc as any).itemActions,
                    transforms: [{ kind: ArrayTransformKind.Limit, numRows: makeNumberProperty(250) }],
                    format: makeEnumProperty(ArrayScreenFormat.Kanban),
                };
            },
        },
    ])
    .withEasyCRUD({
        withDelete: true,
        withConditions: true,
        withAddTitle: false,
        withEditTitle: false,
        updateSource() {
            return {
                // This is filled in when lowering.
                action: undefined,
                // Easy CRUD Kanban isn't using title actions, so we have to
                // remove them if they're present.
                titleActions: undefined,
            };
        },
        lower(desc, flags) {
            let updates: Partial<typeof desc> = {};

            updates = {
                ...updates,
                allowInlineAdding: makeSwitchProperty(flags.add !== undefined, flags.add?.condition),
                allowInlineEditing: makeSwitchProperty(flags.edit !== undefined, flags.edit?.condition),
                action: makeActionProperty({ kind: ActionKind.PushDetailScreen }),
            };

            const deleteAction = addConditionToActionWithTitleAndIcon(
                makeActionWithTitle("Delete", { kind: ActionKind.DeleteRow }),
                flags.delete,
                getGlideIcon("st-trash")
            );
            if (deleteAction !== undefined) {
                updates = { ...updates, itemActions: makeArrayProperty([deleteAction]) };
            } else {
                updates = { ...updates, itemActions: undefined };
            }

            return updates;
        },
    });

const DATA_GRID_COLUMNS_WITH_DISPLAY_VALUE_SUPPORT: WireDataGridColumnKind[] = ["string", "number"];

export function dataGridColumnSupportsDisplayValue(kind: WireDataGridColumnKind): boolean {
    return DATA_GRID_COLUMNS_WITH_DISPLAY_VALUE_SUPPORT.includes(kind);
}

const DATA_GRID_COLUMNS_WITH_EDIT_SUPPORT: WireDataGridColumnKind[] = ["string", "number", "boolean"];

export function dataGridColumnSupportsEdit(kind: WireDataGridColumnKind): boolean {
    return DATA_GRID_COLUMNS_WITH_EDIT_SUPPORT.includes(kind);
}

export function extractDataGridType(glideType: PrimitiveGlideType): WireDataGridColumnKind {
    switch (glideType.kind) {
        case "boolean":
            return "boolean";

        case "image-uri":
            return "image-uri";

        case "number":
            return "number";

        default:
            return "string";
    }
}

export function glideTypeToNewDataGridColumnMapping(
    glideType: PrimitiveGlideType | PrimitiveArrayColumnType,
    stKind: ConfigurableNewDataGridColumnKindWithAuto | undefined
): ConfigurableNewDataGridColumnKind | undefined {
    if (stKind !== "auto") {
        return stKind;
    }
    const glideKind = glideType.kind;
    switch (glideKind) {
        case "string":
        case "markdown":
        case "date":
        case "date-time":
        case "time":
        case "json":
        case "phone-number":
        case "emoji":
        case "email-address":
        case "duration":
            return "text";
        case "number":
            return "number";
        case "boolean":
            return "boolean";
        case "image-uri":
            return "image";
        case "audio-uri":
        case "uri":
            return "link";
        case "array":
            const item = glideType.items;
            return glideTypeToNewDataGridColumnMapping(item, stKind);
        default:
            assertNever(glideKind);
    }
}

const choiceTableGetter: PropertyTableGetter = (tables, _rootDesc, desc, schemaInspector) => {
    const linkTarget = getColumnLinkTargetFromValueProperty(schemaInspector, tables, (desc as any).value);

    const tableName =
        linkTarget !== undefined ? getTableName(linkTarget.targetTable) : getTableProperty((desc as any).choiceSource);
    const choiceTable = schemaInspector.findTable(tableName);

    if (choiceTable === undefined) {
        return undefined;
    }

    return { table: choiceTable, inScreenContext: false };
};

function isItemPropertyBoundToARelation(
    property: PropertyDescription | undefined,
    tables: InputOutputTables | undefined,
    rootDesc: Description,
    adc: AppDescriptionContext
): boolean {
    const sc = getSourceColumnProperty(property);
    if (sc === undefined) return false;

    const table = getInlineListPropertyTable(
        tables?.input,
        (rootDesc as unknown as InlineListComponentDescription).propertyName,
        adc
    )?.table;
    const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
    if (tac === undefined) return false;

    return getTargetForLink(tac.table, tac.column, adc, true) !== undefined;
}

export type ConfigurableNewDataGridColumnKind = Exclude<NewDataGridColumnKind, "row-header" | "extra-actions">;
export type ConfigurableNewDataGridColumnKindWithAuto = ConfigurableNewDataGridColumnKind | "auto";
function makeNewDataGridColumnProperties<TRoot>() {
    return (
        properties
            .withRoot<TRoot>()
            .withPrimitive("value", {
                propertySection: PropertySection.Top,
                isDefaultCaption: true,
                withRelation: true,
                withArray: true,
                easyTabConfiguration: {
                    propertySection: PropertySection.Columns,
                },
            })
            .withCaption("title", "Header", {
                propertySection: PropertySection.Top,
                displayName: "Header",
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                propertyTableGetter: tables => {
                    return definedMap(tables, t => ({
                        table: t.input,
                        inScreenContext: true,
                    }));
                },
            })
            .withEnum<"kind", ConfigurableNewDataGridColumnKindWithAuto>(
                "kind",
                [
                    { value: "auto", label: "Auto", icon: "st-sync-auto" },
                    { value: "text", label: "Text", icon: "co-basic-text" },
                    { value: "number", label: "Number", icon: "mt-column-number" },
                    { value: "boolean", label: "Boolean", icon: "co-switch" },
                    { value: "image", label: "Image", icon: "co-image" },
                    { value: "link", label: "Link", icon: "co-link" },
                    { value: "button", label: "Button", icon: "co-button" },
                    { value: "choice", label: "Choice", icon: "co-choice" },
                    { value: "tag", label: "Tag", icon: "co-tag" },
                ],
                {
                    displayName: "Type",
                    defaultValue: "auto",
                    enumVisual: "dropdown",
                    propertySection: PropertySection.Top,
                    easyTabConfiguration: { propertySection: PropertySection.Columns },
                }
            )
            .withEnum<"sizeKind", NewDataGridColumn["sizeOptions"]["sizeKind"]>("sizeKind", ["auto", "fixed"], {
                defaultValue: "auto",
                enumVisual: "text",
                displayName: "Size",
                propertySection: { name: PropertySection.Design, collapsed: true, order: 0 },
                easyTabConfiguration: { propertySection: PropertySection.Columns },
            })
            // TODO: Make this a slider somehow
            .withFormattedNumber("widthRatio", {
                defaultValue: 1,
                when: desc => {
                    const sizeKind = getEnumProperty(desc.sizeKind);
                    return sizeKind === "auto";
                },
                propertySection: { name: PropertySection.Design, collapsed: true, order: 0 },
                easyTabConfiguration: { propertySection: PropertySection.Columns },
            })
            // TODO: Make this a slider somehow
            .withFormattedNumber("widthSize", {
                defaultValue: 200,
                displayName: "Fixed size",
                when: desc => {
                    const sizeKind = getEnumProperty(desc.sizeKind);
                    return sizeKind === "fixed";
                },
                propertySection: { name: PropertySection.Design, collapsed: true, order: 0 },
                easyTabConfiguration: { propertySection: PropertySection.Columns },
            })
            .withText("displayValue", {
                displayName: "Display as",
                when: (desc, rootDesc, tables, adc) => {
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);

                    const sc = getSourceColumnProperty(desc.value);
                    if (sc === undefined) return false;

                    const table = getInlineListPropertyTable(
                        tables?.input,
                        (rootDesc as unknown as InlineListComponentDescription).propertyName,
                        adc
                    )?.table;
                    const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                    if (tac === undefined) return false;

                    if (!isPrimitiveType(tac.column.type)) return false;

                    const actualKind = glideTypeToNewDataGridColumnMapping(tac.column.type, kind);

                    return actualKind === "text" || actualKind === "number" || actualKind === "link";
                },
                propertySection: { name: PropertySection.Options, collapsed: true, order: 1 },
                emptyByDefault: true,
                useTemplate: "withLabel",
                easyTabConfiguration: { propertySection: PropertySection.Columns },
            })

            .withEnum<"editOrView", "View" | "Edit">("editOrView", ["View", "Edit"], {
                propertySection: PropertySection.Top,
                defaultValue: "Edit",
                displayName: "Users can",
                enumVisual: "text",
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                when: (desc, rootDesc, tables, adc) => {
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);

                    const sc = getSourceColumnProperty(desc.value);
                    if (sc === undefined) return false;

                    const table = getInlineListPropertyTable(
                        tables?.input,
                        (rootDesc as unknown as InlineListComponentDescription).propertyName,
                        adc
                    )?.table;
                    const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                    if (tac === undefined) return false;

                    const isRelation = isItemPropertyBoundToARelation(desc.value, tables, rootDesc, adc);

                    // The only editable non-primitive is tag, because of relations
                    if (isRelation) {
                        return kind === "tag";
                    }

                    if (!isPrimitiveType(tac.column.type)) {
                        return false;
                    }

                    const actualKind = glideTypeToNewDataGridColumnMapping(tac.column.type, kind);

                    const typeSupportsEdit =
                        actualKind === "text" ||
                        actualKind === "number" ||
                        actualKind === "boolean" ||
                        actualKind === "tag";

                    return typeSupportsEdit && isColumnWritable(tac.column, tac.table, false);
                },
            })
            .withAction("onClick", {
                when: desc => {
                    return getEnumProperty(desc.kind) === "button";
                },
                easyTabConfiguration: { propertySection: PropertySection.Columns },
            })
            .withTable("choiceSource", getAllowedChoiceTables, {
                displayName: "Source",
                propertySection: PropertySection.Data,
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                allowRelation: true,
                when: (desc, rootDesc, tables, adc) => {
                    const isRelation = isItemPropertyBoundToARelation(desc.value, tables, rootDesc, adc);
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);
                    const isChoiceKind: boolean = kind === "choice" || kind === "tag";
                    return isChoiceKind && !isRelation;
                },
            })
            .withText("choiceValues", {
                allowLiteral: false,
                allowUserProfileColumns: false,
                displayName: "Values",
                propertySection: PropertySection.Data,
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                required: true,
                searchable: false,
                propertyTableGetter: choiceTableGetter,
                when: (desc, rootDesc, tables, adc) => {
                    const isRelation = isItemPropertyBoundToARelation(desc.value, tables, rootDesc, adc);
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);
                    const isChoiceKind: boolean = kind === "choice" || kind === "tag";
                    return isChoiceKind && !isRelation;
                },
            })
            .withText("choiceDisplay", {
                allowLiteral: false,
                allowUserProfileColumns: false,
                propertyTableGetter: choiceTableGetter,
                displayName: "Display as",
                propertySection: PropertySection.Data,
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                searchable: false,
                when: desc => {
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);
                    return kind === "choice" || kind === "tag";
                },
            })
            .withEnum<"tagColor", "Auto" | "Manual">("tagColor", ["Auto", "Manual"], {
                defaultValue: "Auto",
                displayName: "Tag color",
                enumVisual: "text",
                propertySection: PropertySection.Data,
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                when: desc => {
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);
                    return kind === "tag";
                },
            })
            .withSwitch("isMultiChoice", false, {
                displayName: "Allow selecting multiple",
                propertySection: PropertySection.Data,
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                when: (desc, rootDesc, tables, adc) => {
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);

                    const sc = getSourceColumnProperty(desc.value);
                    if (sc === undefined) return false;

                    const table = getInlineListPropertyTable(
                        tables?.input,
                        (rootDesc as unknown as InlineListComponentDescription).propertyName,
                        adc
                    )?.table;
                    const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                    if (tac === undefined) return false;

                    const isPrimitiveColumn = isPrimitiveType(tac.column.type);

                    const viewOrEdit = getEnumProperty<"View" | "Edit">(desc.editOrView) ?? "View";
                    const isEditableTag = kind === "tag" && viewOrEdit === "Edit";
                    const isChoice = kind === "choice";
                    const isEditable = isEditableTag || isChoice;

                    return isPrimitiveColumn && isEditable;
                },
            })
            .withText("choiceColor", {
                propertyTableGetter: choiceTableGetter,
                displayName: "Color",
                propertySection: PropertySection.Data,
                easyTabConfiguration: { propertySection: PropertySection.Columns },
                searchable: false,
                when: desc => {
                    const kind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(desc.kind);
                    const tagColor = getEnumProperty<"Auto" | "Manual">(desc.tagColor);
                    return kind === "tag" && tagColor === "Manual";
                },
            })
            .withSwitch("isVisible", true, {
                displayName: "Visible",
                withCondition: true,
                specPrefix: "Show column when",
                propertySection: { name: PropertySection.Options, collapsed: true, order: 1 },
                propertyTableGetter: tables => {
                    return definedMap(tables, t => ({
                        table: t.input,
                        inScreenContext: true,
                    }));
                },
                when: () => getFeatureSetting("dataGridConditionalColumns"),
            })
    );
}

export type NewDataGridColumnDescription = DescriptionOfProperties<ReturnType<typeof makeNewDataGridColumnProperties>>;

export const NewDataGrid = arrayContent(ArrayScreenFormat.NewDataGrid)
    .withPaging("custom")
    .withComponentProperties(
        propertiesWithCSS
            .withText("title", {
                useTemplate: "withLabel",
                displayName: "Title",
                propertySection: PropertySection.DataTop,
                emptyByDefault: true,
                isComponentTitle: true,
            })
            .withArray("titleActions", makeActionWithTitleProperties(), titleActionsOptions)
    )
    .withItemProperties(p =>
        p
            .withArray("columns", makeNewDataGridColumnProperties(), {
                style: ArrayPropertyStyle.Popup,
                propertySection: PropertySection.Columns,
                numDefaultItems: 7,
                getHeaderDisplay: (desc, rootDesc, tables, adc) => {
                    const header = getStringProperty(desc.title);

                    if (!isEmptyOrUndefined(header)) {
                        return header;
                    }

                    let maybeColumn = definedMap(getSourceColumnProperty(desc.value), sc =>
                        getTableAndColumnForSourceColumn(adc, sc, tables?.input, undefined)
                    )?.column;

                    if (maybeColumn?.displayName === "") {
                        const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);
                        const sc = getSourceColumnProperty(desc.value);
                        if (sc === undefined) return "Text";

                        const table = getInlineListPropertyTable(
                            tables?.input,
                            (rootDesc as unknown as InlineListComponentDescription).propertyName,
                            adc
                        )?.table;
                        const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                        if (tac === undefined) return "Text";

                        // The only editable non-primitive is tag, because of relations
                        if (!isPrimitiveType(tac.column.type)) {
                            return "Text";
                        }

                        const actualKind = glideTypeToSuperTableColumnMapping(tac.column.type, kind);

                        const label = superTableKindEnumPropertyCase.find(c => c.value === actualKind);

                        return label?.label ?? "Text";
                    }

                    if (maybeColumn === undefined) {
                        maybeColumn = definedMap(getSourceColumnProperty(desc.displayValue), sc =>
                            getTableAndColumnForSourceColumn(adc, sc, tables?.input, undefined)
                        )?.column;
                    }

                    if (maybeColumn !== undefined) {
                        return getTableColumnDisplayName(maybeColumn);
                    }

                    return "-";
                },
                dataSelectors: ["Value"],
                easyTabConfiguration: {
                    propertySection: PropertySection.Columns,
                },
            })
            .withSwitch("allowDeletingRows", false, {
                displayName: "Allow deleting rows",
                propertySection: PropertySection.InlineActions,
                disableForEasyCRUD: true,
            })
            .withAction("action", {
                propertySection: PropertySection.ItemClick,
                defaultAction: true,
                subcomponent: {
                    important: true,
                    name: "Actions",
                },
            })
            .withArray("itemActions", makeActionWithTitleProperties(), {
                ...itemActionsOptions,
                defaultValue: [makeActionWithTitle("View Details", { kind: ActionKind.PushDetailScreen })],
                disableForEasyCRUD: true,
            })
    )
    .withSpecialCases([
        {
            name: "Data Grid",
            analyticsName: "data-grid",
            description: "A grid of data",
            img: "mt-component-data-grid",
            group: "Collections",
            appKinds: AppKind.Page,
            setAsCurrent: desc => {
                const columns = hasOwnProperty(desc, "columns")
                    ? getArrayProperty(desc.columns as PropertyDescription) ?? []
                    : [];
                const normalizeColumns = columns.map(({ title, header, ...column }: any) => ({
                    ...column,
                    title: title ?? header,
                }));
                return {
                    ...desc,
                    columns: normalizeColumns.length > 0 ? makeArrayProperty(normalizeColumns) : undefined,
                    format: makeEnumProperty(ArrayScreenFormat.NewDataGrid),
                };
            },
        },
    ])
    .withEasyCRUD({
        withDelete: true,
        withEdit: false,
        withConditions: false,
        withEditTitle: false,
        withDeleteTitle: false,
        updateSource(_desc, screenTables, itemTable, iccc) {
            const addAction = iccc.makeFormActionForTables(makeInputOutputTables(screenTables.input, itemTable));
            return {
                titleActions: definedMap(addAction, a =>
                    makeArrayProperty([makeActionWithTitle("Add", a, getGlideIcon("st-plus-add"))])
                ),
            };
        },
        lower(desc, flags, forGC) {
            let updates: Partial<typeof desc> = {};

            updates = { ...updates, ...makeUpdateForEasyCRUDTitleAction(desc, flags, forGC) };

            updates = {
                ...updates,
                allowDeletingRows: makeSwitchProperty(flags.delete !== undefined),
            };

            return updates;
        },
    });

export type NewDataGridContentDescription = DescriptionOfArrayContent<typeof NewDataGrid>;
export type NewDataGridComponentDescription = DescriptionOfArrayContentInlineList<typeof NewDataGrid>;
export type WireNewDataGridCollection = WirePropertiesOfArrayContent<typeof NewDataGrid>;

function makeDataGridColumnProperties<TRoot>() {
    return properties
        .withRoot<TRoot>()
        .withCaption("title", "Title", {
            emptyByDefault: true,
            allowColumn: false,
        })
        .withPrimitive("value", {
            isDefaultCaption: true,
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
        })
        .withSwitch("isEditable", false, {
            displayName: "Editable",
            disableForEasyCRUD: true,
            when: (desc, rootDesc, tables, adc) => {
                const sc = getSourceColumnProperty(desc.value);
                if (sc === undefined) return false;

                const table = getInlineListPropertyTable(
                    tables?.input,
                    (rootDesc as unknown as InlineListComponentDescription).propertyName,
                    adc
                )?.table;
                const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                if (tac === undefined) return false;

                if (!isPrimitiveType(tac.column.type)) return false;

                const supportsEdit = dataGridColumnSupportsEdit(extractDataGridType(tac.column.type));
                const isWritable = isColumnWritable(tac.column, tac.table, false);

                return supportsEdit && isWritable;
            },
        })
        .withText("displayValue", {
            displayName: "Display as",
            emptyByDefault: true,
            useTemplate: "withLabel",
            when: (desc, rootDesc, tables, adc) => {
                const sc = getSourceColumnProperty(desc.value);
                if (sc === undefined) return false;

                const table = getInlineListPropertyTable(
                    tables?.input,
                    (rootDesc as unknown as InlineListComponentDescription).propertyName,
                    adc
                );
                const column = getColumnForSourceColumn(adc, sc, table?.table, undefined, []);
                if (column === undefined) return false;

                if (!isPrimitiveType(column.type)) return false;

                const isEditable = getSwitchProperty(desc.isEditable) === true;

                return isEditable && dataGridColumnSupportsDisplayValue(extractDataGridType(column.type));
            },
        });
}

export type DataGridColumnDescription = DescriptionOfProperties<ReturnType<typeof makeDataGridColumnProperties>>;

export const DataGrid = arrayContent(ArrayScreenFormat.DataGrid)
    .withPaging("default")
    .withComponentProperties(
        propertiesWithCSS
            .withSwitch("rowMarkers", true, { displayName: "Row markers" })
            .withText("title", {
                displayName: "Title",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                emptyByDefault: true,
                isComponentTitle: true,
                useTemplate: "withLabel",
            })
            .withEnum(
                "titleStyle",
                [
                    {
                        label: "Simple",
                        value: UITitleStyle.Simple,
                    },
                    {
                        label: "Bold",
                        value: UITitleStyle.Bold,
                    },
                ],
                {
                    defaultValue: UITitleStyle.Bold,
                    propertySection: PropertySection.Design,
                    when: (_a, _b, _c, _d, isFirstComponent) => isFirstComponent !== false,
                }
            )
            .withImage("titleImage", {
                useTemplate: "withLabel",
                propertySection: PropertySection.Design,
                when: (c, _d, _e, _f, isFirstComponent) =>
                    isFirstComponent !== false && getEnumProperty(c?.titleStyle) === UITitleStyle.Hero,
            })
            .withArray("titleActions", makeActionWithTitleProperties(), titleActionsOptions)
    )
    .withItemProperties(p =>
        p
            .withArray("columns", makeDataGridColumnProperties(), {
                style: ArrayPropertyStyle.Card,
                propertySection: PropertySection.Columns,
                numDefaultItems: 3,
                getHeaderDisplay: desc => {
                    return getStringProperty(desc.title) ?? "-";
                },
                dataSelectors: ["Value"],
                easyTabConfiguration: {
                    propertySection: PropertySection.Columns,
                },
            })
            .withSwitch("allowDeletingRows", false, {
                displayName: "Allow deleting rows",
                propertySection: PropertySection.InlineActions,
                disableForEasyCRUD: true,
                icon: "st-trash",
            })
    )
    .withSpecialCases([
        {
            name: "Data Grid ",
            analyticsName: "collection-data-grid",
            description: "A grid of data",
            img: "mt-component-data-grid",
            group: "Collections",
            appKinds: AppKind.Page,
            getIsHidden: () => true,
        },
    ])
    .withEasyCRUD({
        withDelete: true,
        withConditions: false,
        withEditTitle: false,
        withDeleteTitle: false,
        updateSource(_desc, screenTables, itemTable, iccc) {
            const addAction = iccc.makeFormActionForTables(makeInputOutputTables(screenTables.input, itemTable));
            return {
                titleActions: definedMap(addAction, a =>
                    makeArrayProperty([makeActionWithTitle("Add", a, getGlideIcon("st-plus-add"))])
                ),
            };
        },
        lower(desc, flags, forGC) {
            let updates: Partial<typeof desc> = {};

            updates = { ...updates, ...makeUpdateForEasyCRUDTitleAction(desc, flags, forGC) };

            updates = {
                ...updates,
                columns: makeArrayProperty(
                    (getArrayProperty(desc.columns) ?? []).map(c => ({
                        ...(c as any),
                        isEditable: makeSwitchProperty(flags.edit !== undefined),
                    }))
                ),
                allowDeletingRows: makeSwitchProperty(flags.delete !== undefined),
            };

            return updates;
        },
    });

export type DataGridContentDescription = DescriptionOfArrayContent<typeof DataGrid>;
export type DataGridComponentDescription = DescriptionOfArrayContentInlineList<typeof DataGrid>;
export type WireDataGridCollection = WirePropertiesOfArrayContent<typeof DataGrid>;

export function glideTypeToSuperTableColumnMapping(
    glideType: PrimitiveGlideType | PrimitiveArrayColumnType | undefined,
    stKind: ConfigurableSuperTableColumnKindWithAuto | undefined
): ConfigurableSuperTableColumnKind | undefined {
    if (stKind !== "auto") {
        return stKind;
    }

    if (glideType === undefined) {
        return undefined;
    }

    const glideKind = glideType.kind;
    switch (glideKind) {
        case "string":
        case "markdown":
        case "date":
        case "date-time":
        case "time":
        case "json":
        case "phone-number":
        case "emoji":
        case "email-address":
        case "number":
        case "duration":
            return "text";
        case "boolean":
            return "boolean";
        case "image-uri":
            return "image";
        case "audio-uri":
        case "uri":
            return "link";
        case "array":
            const item = glideType.items;
            return glideTypeToSuperTableColumnMapping(item, stKind);
        default:
            assertNever(glideKind);
    }
}

const superTableKindEnumPropertyCase: EnumPropertyCase<ConfigurableSuperTableColumnKindWithAuto>[] = [
    { value: "auto", label: "Auto", icon: "st-sync-auto" },
    { value: "text", label: "Text", icon: "co-basic-text" },
    { value: "boolean", label: "Boolean", icon: "co-switch" },
    { value: "image", label: "Image", icon: "co-image" },
    { value: "link", label: "Link", icon: "co-link" },
    { value: "button", label: "Button", icon: "co-button" },
    { value: "choice", label: "Choice", icon: "co-choice" },
    { value: "tag", label: "Tag", icon: "co-tag" },
];

export type ConfigurableSuperTableColumnKind = Exclude<SuperTableColumnKind, "row-header" | "extra-actions">;
export type ConfigurableSuperTableColumnKindWithAuto = ConfigurableSuperTableColumnKind | "auto";
function makeSuperTableColumnProperties<TRoot>() {
    return properties
        .withRoot<TRoot>()
        .withPrimitive("value", {
            propertySection: PropertySection.Top,
            isDefaultCaption: true,
            withRelation: true,
            withArray: true,
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
        })
        .withCaption("header", "Header", {
            propertySection: PropertySection.Top,
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
            propertyTableGetter: tables => {
                return definedMap(tables, t => ({
                    table: t.input,
                    inScreenContext: true,
                }));
            },
        })
        .withEnum<"kind", ConfigurableSuperTableColumnKindWithAuto>("kind", superTableKindEnumPropertyCase, {
            displayName: "Type",
            defaultValue: "auto",
            enumVisual: "dropdown",
            propertySection: PropertySection.Top,
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
        })
        .withImage("accessoryImage", {
            displayName: "Image",
            useTemplate: "withLabel",
            emptyByDefault: true,
            propertySection: { name: PropertySection.Design, collapsed: true, order: 0 },
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
            when: (desc, rootDesc, tables, adc) => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);

                if (kind === "text") {
                    return true;
                }

                const sc = getSourceColumnProperty(desc.value);
                if (sc === undefined) return false;

                const table = getInlineListPropertyTable(
                    tables?.input,
                    (rootDesc as unknown as InlineListComponentDescription).propertyName,
                    adc
                )?.table;
                const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                if (tac === undefined) return false;

                if (!isPrimitiveType(tac.column.type)) return false;

                const actualKind = glideTypeToSuperTableColumnMapping(tac.column.type, kind);

                return actualKind === "text";
            },
        })
        .withEnum<"buttonStyle", "Default" | "Accent">("buttonStyle", ["Default", "Accent"], {
            displayName: "Style",
            defaultValue: "Default",
            enumVisual: "text",
            propertySection: { name: PropertySection.Design, collapsed: true, order: 0 },
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
            when: desc => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);

                return kind === "button";
            },
        })
        .withIcon("buttonIcon", {
            displayName: "Icon",
            propertySection: PropertySection.Top,
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
            when: desc => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);

                return kind === "button";
            },
        })
        .withEnum<"sizeKind", SuperTableColumn["sizeOptions"]["sizeKind"]>("sizeKind", ["auto", "fixed"], {
            defaultValue: "auto",
            enumVisual: "text",
            displayName: "Size",
            propertySection: { name: PropertySection.Design, collapsed: true, order: 0 },
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
        })
        .withEnum(
            "widthSize",
            [
                { value: UISize.XSmall, label: "XS" },
                { value: UISize.Small, label: "S" },
                { value: UISize.Medium, label: "M" },
                { value: UISize.Large, label: "L" },
                { value: UISize.XLarge, label: "XL" },
            ],
            {
                defaultValue: UISize.Medium,
                enumVisual: "text",
                displayName: "Fixed size",
                propertySection: { name: PropertySection.Design, collapsed: true, order: 0 },
                when: desc => {
                    const sizeKind = getEnumProperty(desc.sizeKind);
                    return sizeKind === "fixed";
                },
                easyTabConfiguration: {
                    propertySection: PropertySection.Columns,
                },
            }
        )
        .withText("displayValue", {
            displayName: "Display as",
            useTemplate: "withLabel",
            when: (desc, rootDesc, tables, adc) => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);

                const sc = getSourceColumnProperty(desc.value);
                if (sc === undefined) return false;

                const table = getInlineListPropertyTable(
                    tables?.input,
                    (rootDesc as unknown as InlineListComponentDescription).propertyName,
                    adc
                )?.table;
                const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                if (tac === undefined) return false;

                if (!isPrimitiveType(tac.column.type)) return false;

                const actualKind = glideTypeToSuperTableColumnMapping(tac.column.type, kind);

                return actualKind === "link";
            },
            propertySection: { name: PropertySection.Options, collapsed: true, order: 1 },
            emptyByDefault: true,
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
        })
        .withEnum<"viewOrEdit", "View" | "Edit">("viewOrEdit", ["View", "Edit"], {
            propertySection: PropertySection.Top,
            defaultValue: "View",
            displayName: "Users can",
            enumVisual: "text",
            when: (desc, rootDesc, tables, adc) => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);

                const sc = getSourceColumnProperty(desc.value);
                if (sc === undefined) return false;

                const table = getInlineListPropertyTable(
                    tables?.input,
                    (rootDesc as unknown as InlineListComponentDescription).propertyName,
                    adc
                )?.table;
                const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                if (tac === undefined) return false;

                const isRelation = isItemPropertyBoundToARelation(desc.value, tables, rootDesc, adc);

                // The only editable non-primitive is tag, because of relations
                if (isRelation) {
                    return kind === "tag";
                }

                if (!isPrimitiveType(tac.column.type)) {
                    return false;
                }
                const actualKind = glideTypeToSuperTableColumnMapping(tac.column.type, kind);

                const typeSupportsEdit = actualKind === "boolean" || actualKind === "tag";

                return typeSupportsEdit && isColumnWritable(tac.column, tac.table, false);
            },
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
        })
        .withAction("onClick", {
            required: true,
            defaultAction: () => ({ kind: ActionKind.ShowToast }),
            when: desc => {
                return getEnumProperty(desc.kind) === "button";
            },
            easyTabConfiguration: {
                propertySection: PropertySection.Columns,
            },
        })
        .withTable("choiceSource", getAllowedChoiceTables, {
            displayName: "Source",
            propertySection: PropertySection.Data,
            easyTabConfiguration: { propertySection: PropertySection.Columns },
            when: (desc, rootDesc, tables, adc) => {
                const isRelation = isItemPropertyBoundToARelation(desc.value, tables, rootDesc, adc);
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);
                const isChoiceKind: boolean = kind === "choice" || kind === "tag";
                return isChoiceKind && !isRelation;
            },
        })
        .withText("choiceValues", {
            allowLiteral: false,
            allowUserProfileColumns: false,
            displayName: "Values",
            propertySection: PropertySection.Data,
            easyTabConfiguration: { propertySection: PropertySection.Columns },
            required: true,
            searchable: false,
            propertyTableGetter: choiceTableGetter,
            when: (desc, rootDesc, tables, adc) => {
                const isRelation = isItemPropertyBoundToARelation(desc.value, tables, rootDesc, adc);
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);
                const isChoiceKind: boolean = kind === "choice" || kind === "tag";
                return isChoiceKind && !isRelation;
            },
        })
        .withText("choiceDisplay", {
            allowLiteral: false,
            allowUserProfileColumns: false,
            propertyTableGetter: choiceTableGetter,
            displayName: "Display as",
            propertySection: PropertySection.Data,
            easyTabConfiguration: { propertySection: PropertySection.Columns },
            searchable: false,
            when: desc => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);
                return kind === "choice" || kind === "tag";
            },
        })
        .withEnum<"tagColor", "Auto" | "Manual">("tagColor", ["Auto", "Manual"], {
            defaultValue: "Auto",
            displayName: "Tag color",
            enumVisual: "text",
            propertySection: PropertySection.Data,
            easyTabConfiguration: { propertySection: PropertySection.Columns },
            when: desc => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);
                return kind === "tag";
            },
        })
        .withSwitch("isMultiChoice", false, {
            displayName: "Allow selecting multiple",
            propertySection: PropertySection.Data,
            easyTabConfiguration: { propertySection: PropertySection.Columns },
            when: (desc, rootDesc, tables, adc) => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);

                const sc = getSourceColumnProperty(desc.value);
                if (sc === undefined) return false;

                const table = getInlineListPropertyTable(
                    tables?.input,
                    (rootDesc as unknown as InlineListComponentDescription).propertyName,
                    adc
                )?.table;
                const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                if (tac === undefined) return false;

                const isPrimitiveColumn = isPrimitiveType(tac.column.type);

                const viewOrEdit = getEnumProperty<"View" | "Edit">(desc.viewOrEdit) ?? "View";
                const isEditableTag = kind === "tag" && viewOrEdit === "Edit";
                const isChoice = kind === "choice";
                const isEditable = isEditableTag || isChoice;

                return isPrimitiveColumn && isEditable;
            },
        })
        .withText("choiceColor", {
            propertyTableGetter: choiceTableGetter,
            displayName: "Color",
            propertySection: PropertySection.Data,
            easyTabConfiguration: { propertySection: PropertySection.Columns },
            searchable: false,
            when: desc => {
                const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);
                const tagColor = getEnumProperty<"Auto" | "Manual">(desc.tagColor);
                return kind === "tag" && tagColor === "Manual";
            },
        });
}

export type SuperTableColumnDescription = DescriptionOfProperties<ReturnType<typeof makeSuperTableColumnProperties>>;

export const SuperTable = arrayContent(ArrayScreenFormat.SuperTable)
    .withPaging("custom")
    .withHandlesLimit()
    .withGrouping(GroupingSupport.Regular)
    .withComponentProperties(
        propertiesWithCSS
            .withText("title", {
                displayName: "Title",
                useTemplate: "withLabel",
                propertySection: PropertySection.DataTop,
                emptyByDefault: true,
                isComponentTitle: true,
            })
            .withArray("titleActions", makeActionWithTitleProperties(), titleActionsOptions)
            .withEnum(
                "style",
                [
                    {
                        label: "Minimal",
                        value: "minimal",
                    },
                    {
                        label: "Striped",
                        value: "striped",
                    },
                ],
                {
                    propertySection: {
                        name: PropertySection.Design,
                        order: -10,
                    },
                    defaultValue: "minimal",
                    enumVisual: "text",
                }
            )
    )
    .withItemProperties(p =>
        p
            .withArray("columns", makeSuperTableColumnProperties(), {
                style: ArrayPropertyStyle.Popup,
                propertySection: {
                    name: PropertySection.Columns,
                    order: -20,
                },
                numDefaultItems: 4,
                getHeaderDisplay: (desc, rootDesc, tables, adc) => {
                    const headerDisplay = getStringProperty(desc.header);
                    if (!isEmptyOrUndefined(headerDisplay)) {
                        return headerDisplay;
                    }

                    const kind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(desc.kind);

                    const sc = getSourceColumnProperty(desc.value);
                    if (sc === undefined) return "Text";

                    const table = getInlineListPropertyTable(
                        tables?.input,
                        (rootDesc as unknown as InlineListComponentDescription).propertyName,
                        adc
                    )?.table;
                    const tac = resolveSourceColumn(adc, sc, table, undefined, undefined)?.tableAndColumn;
                    if (tac === undefined) return "Text";

                    // The only editable non-primitive is tag, because of relations
                    if (!isPrimitiveType(tac.column.type)) {
                        return "Text";
                    }

                    const actualKind = glideTypeToSuperTableColumnMapping(tac.column.type, kind);

                    const label = superTableKindEnumPropertyCase.find(c => c.value === actualKind);

                    return label?.label ?? "Text";
                },
                dataSelectors: ["Value"],
                easyTabConfiguration: {
                    propertySection: PropertySection.Columns,
                },
                maxItems: 50,
            })
            .withAction("action", {
                propertySection: PropertySection.ItemClick,
                defaultAction: () => ({
                    kind: ActionKind.PushDetailScreen,
                }),
                subcomponent: {
                    important: true,
                    name: "Actions",
                },
                disableForEasyCRUD: true,
            })
            .withArray("itemActions", makeActionWithTitleProperties(), {
                ...itemActionsOptions,
                disableForEasyCRUD: true,
            })
    )
    .withSpecialCases([
        {
            // TODO: I freestyled all of this to the dumbest beat
            name: "Table",
            analyticsName: "super-table",
            description: "A powerful table component",
            img: "mt-component-collection-table",
            group: "Collections",
            appKinds: AppKind.Page,
            setAsCurrent: desc => {
                const columns = hasOwnProperty(desc, "columns")
                    ? getArrayProperty(desc.columns as PropertyDescription) ?? []
                    : [];
                const normalizeColumns = columns.map(({ title, header, ...column }: any) => ({
                    ...column,
                    header: header ?? title,
                }));

                return {
                    ...desc,
                    columns: normalizeColumns.length > 0 ? makeArrayProperty(normalizeColumns) : undefined,
                    format: makeEnumProperty(ArrayScreenFormat.SuperTable),
                };
            },
        },
    ])
    .withEasyCRUD({
        withDelete: true,
        withConditions: true,
        updateSource(_desc, screenTables, itemTable, iccc) {
            const addAction = iccc.makeFormActionForTables(makeInputOutputTables(screenTables.input, itemTable));
            return {
                titleActions: definedMap(addAction, a =>
                    makeArrayProperty([makeActionWithTitle("Add", a, getGlideIcon("st-plus-add"))])
                ),
            };
        },
        lower(desc, flags, forGC) {
            let updates: Partial<typeof desc> = {};
            updates = {
                ...updates,
                ...makeUpdateForEasyCRUDTitleAction(desc, flags, forGC),
                action: makeActionProperty({
                    kind: ActionKind.PushDetailScreen,
                } as ActionDescription),
            };

            const editAction = addConditionToActionWithTitleAndIcon(
                makeActionWithTitle("Edit", { kind: ActionKind.PushEditScreen }),
                flags.edit,
                getGlideIcon("st-edit-pencil")
            );
            const deleteAction = addConditionToActionWithTitleAndIcon(
                makeActionWithTitle("Delete", { kind: ActionKind.DeleteRow }),
                flags.delete,
                getGlideIcon("st-trash")
            );
            if (editAction !== undefined || deleteAction !== undefined) {
                const itemActions: Description[] = [{}, {}];
                if (editAction !== undefined) {
                    itemActions.push(editAction);
                }
                if (deleteAction !== undefined) {
                    itemActions.push(deleteAction);
                }
                updates = { ...updates, itemActions: makeArrayProperty(itemActions) };
            }

            return updates;
        },
    })
    .withOnlyQueries();

function makeChartsColumnProperties<TRoot>() {
    return properties.withRoot<TRoot>().withCaption("caption", "Title", { allowColumn: false }).withText("value", {
        isDefaultCaption: true,
        willBeQueried: true,
    });
}

export type ChartsColumnDescription = DescriptionOfProperties<ReturnType<typeof makeChartsColumnProperties>>;

export const Charts = arrayContent(ArrayScreenFormat.Charts)
    .withPaging("default")
    .withDisplayName("Chart")
    .withIcon("mt-stacked-bar")
    .withSearchDefault(false)
    .withCanShowIfEmpty(false)
    .withQueryableTables(QueryableTableSupport.LoadAll, false)
    .withComponentProperties(
        propertiesWithCSS
            .withEnum(
                "chartType",
                [
                    { value: ChartType.Bar, label: "Bar", icon: "mt-pages-bar-chart" },
                    { value: ChartType.Line, label: "Line", icon: "mt-pages-line-chart-bg" },
                ],
                { propertySection: PropertySection.Style, enumVisual: "large-images" }
            )
            .withText("title", {
                displayName: "Title",
                useTemplate: "withLabel",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                defaultValue: "Chart title",
            })
            .withEnum(
                "barChartType",
                [
                    { value: BarChartType.Group, label: "Group", icon: "mt-group-bar" },
                    { value: BarChartType.Stack, label: "Stack", icon: "mt-stacked-bar" },
                ],
                {
                    propertySection: PropertySection.Content,
                    enumVisual: "small-images",
                    displayName: "Graph Type",
                    when: d => getEnumProperty(d.chartType) === ChartType.Bar,
                }
            )
            .withEnum(
                "barChartWeight",
                [
                    { value: BarChartWeights.Line, label: "Lines", icon: "mt-bar-line" },
                    { value: BarChartWeights.Block, label: "Blocks", icon: "mt-bar-block" },
                ],
                {
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                    displayName: "Graph style",
                    when: d => getEnumProperty(d.chartType) === ChartType.Bar,
                }
            )
            .withSwitch("showXLabels", true, {
                displayName: "Show x labels",
                propertySection: PropertySection.Design,
            })
            .withSwitch("showYLabels", false, {
                displayName: "Show y labels",
                propertySection: PropertySection.Design,
            })
            .withSwitch("showGridLines", true, {
                displayName: "Show grid lines",
                propertySection: PropertySection.Design,
            })
            .withSwitch("showLegend", true, {
                displayName: "Show legend",
                propertySection: PropertySection.Design,
            })
    )
    .withItemProperties(p =>
        p
            .withArray("graph", makeChartsColumnProperties(), {
                displayName: "Y Axis",
                style: ArrayPropertyStyle.Card,
                propertySection: PropertySection.Content,
            })
            .withText("xAxis", {
                displayName: "X axis",
                allowColumn: true,
                isDefaultCaption: true,
                allowLiteral: false,
                required: true,
                willBeQueried: true,
                propertySection: PropertySection.Content,
            })
    )
    .withSpecialCases([
        {
            name: "Bar Chart",
            analyticsName: "pages-bar-charts",
            description: "Visualize your data",
            img: "mt-stacked-bar",
            group: "Content",
            appKinds: AppKind.Page,
            setAsCurrent: desc => ({ ...desc, chartType: makeEnumProperty(ChartType.Bar) }),
        },
        {
            name: "Line Chart",
            analyticsName: "pages-line-charts",
            description: "Visualize your data",
            img: "mt-pages-line-chart",
            group: "Content",
            appKinds: AppKind.Page,
            setAsCurrent: desc => ({ ...desc, chartType: makeEnumProperty(ChartType.Line) }),
        },
    ]);

export type ChartsComponentDescription = DescriptionOfArrayContentInlineList<typeof Charts>;

export enum DataPlotColorMode {
    Auto = "Auto",
    Manual = "Manual",
}

export enum DataPlotRollupKind {
    Sum = "sum",
    Average = "average",
    Minimum = "minimum",
    Maximum = "maximum",
    CountNonEmpty = "count",
}

function makeDataPlotDataPointProperties<TRoot>() {
    return properties
        .withRoot<TRoot>()
        .withPrimitive("value", {
            isDefaultCaption: true,
            preferredType: "number",
        })
        .withText("caption", {
            displayName: "Caption",
            emptyByDefault: true,
            useTemplate: "withLabel",
        })
        .withEnum("displayType", [
            { value: DataPlotType.Bar, label: "Bar", icon: "mt-pages-bar-chart" },
            { value: DataPlotType.Line, label: "Line", icon: "mt-pages-line-chart-bg" },
            { value: DataPlotType.Scatter, label: "Scatter", icon: "mt-pages-scatter-plot" },
            { value: DataPlotType.Area, label: "Area", icon: "mt-pages-area-chart" },
        ])
        .withEnum<"rollupKind", string>(
            "rollupKind",
            [
                {
                    value: DataPlotRollupKind.Sum,
                    label: "Sum",
                },
                {
                    value: DataPlotRollupKind.Average,
                    label: "Average",
                },
                {
                    value: DataPlotRollupKind.Maximum,
                    label: "Maximum",
                },
                {
                    value: DataPlotRollupKind.Minimum,
                    label: "Minimum",
                },
                {
                    value: DataPlotRollupKind.CountNonEmpty,
                    label: "Count (Not Empty)",
                },
            ],
            {
                displayName: "Aggregation",
                helpText: "Define how to aggregate numeric data points.",
                propertySection: PropertySection.DataTop,
                when: (d, root, _tables, adc) => {
                    const sc = getSourceColumnProperty(d.value);
                    const table = adc.findTable(
                        getTableProperty((root as unknown as InlineListComponentDescription).propertyName)
                    );
                    if (sc === undefined) return false;
                    const tableColumn = getTableAndColumnForSourceColumn(adc, sc, table, undefined);
                    if (tableColumn === undefined) return false;
                    return isNumberTypeKind(tableColumn.column.type.kind);
                },
            }
        )
        .withEnum<"colorMode", DataPlotColorMode>("colorMode", [DataPlotColorMode.Auto, DataPlotColorMode.Manual], {
            defaultValue: DataPlotColorMode.Auto,
            displayName: "Color mode",
            enumVisual: "text",
            propertySection: PropertySection.Design,
        })
        .withText("color", {
            displayName: "Color",
            emptyByDefault: true,
            isDefaultCaption: true,
            when: d => getEnumProperty(d.colorMode) === DataPlotColorMode.Manual,
        });
}

function makeGradientStops<TRoot>() {
    return properties.withRoot<TRoot>().withText("color", {
        displayName: "Color",
        emptyByDefault: true,
        allowColumn: false,
        placeholder: "(hex, or RGB)",
    });
}

export type DataPlotDataPointDescription = DescriptionOfProperties<ReturnType<typeof makeDataPlotDataPointProperties>>;

export enum ChartingColorScheme {
    Default = "default",
    Bright = "bright",
    Muted = "muted",
    Gradient = "gradient",
}

export enum ChartingGradientMapping {
    Data = "value",
    Index = "index",
}

export enum TimeSeriesInterval {
    Hour = "hour",
    Day = "day",
    Week = "week",
    Month = "month",
    Quarter = "quarter",
    Year = "year",
}

export enum TimeSeriesRelativeDuration {
    Last24Hours = "last-24-hours",
    Last7Days = "last-7-days",
    Last30Days = "last-30-days",
    Last90Days = "last-90-days",
    Last12Months = "last-12-months",
}

export const DataPlot = arrayContent(ArrayScreenFormat.DataPlot)
    .withSearchDefault(false)
    .withCanShowIfEmpty(false)
    // disable sorting, we now always aggregate for now
    .withQueryableTables(QueryableTableSupport.LoadAll, false)
    .withComponentProperties(
        propertiesWithCSS.withText("title", {
            displayName: "Title",
            useTemplate: "withLabel",
            propertySection: PropertySection.DataTop,
            preferredNames: titleProperties,
        })
    )
    .withItemProperties(p =>
        p
            .withArray("points", makeDataPlotDataPointProperties(), {
                displayName: "Data points",
                style: ArrayPropertyStyle.Card,
                propertySection: PropertySection.Data,
            })
            .withText("label", {
                // label value column on the main axis (x default)
                displayName: "X Axis",
                allowColumn: true,
                isDefaultCaption: true,
                allowLiteral: false,
                required: true,
                willBeQueried: true,
                propertySection: PropertySection.DataTop,
                preferredType: "date-time",
            })
            .withEnum<"timeSeriesRelativeDuration", TimeSeriesRelativeDuration | "All">(
                "timeSeriesRelativeDuration",
                [
                    { value: TimeSeriesRelativeDuration.Last24Hours, label: "Last 24 hours" },
                    { value: TimeSeriesRelativeDuration.Last7Days, label: "Last 7 days" },
                    { value: TimeSeriesRelativeDuration.Last30Days, label: "Last 30 days" },
                    {
                        value: TimeSeriesRelativeDuration.Last90Days,
                        label: "Last 90 days",
                    },
                    {
                        value: TimeSeriesRelativeDuration.Last12Months,
                        label: "Last 12 months",
                    },
                    { value: "All", label: "All" },
                ],
                {
                    displayName: "Time range",
                    propertySection: PropertySection.DataTop,
                    when: (d, _root, _tables, adc) => {
                        // we only show time series if the main axis is date time.
                        const sc = getSourceColumnProperty(d.label);
                        const table = adc.findTable(
                            getTableProperty((d as unknown as InlineListComponentDescription).propertyName)
                        );
                        if (sc === undefined) return false;
                        const tableColumn = getTableAndColumnForSourceColumn(adc, sc, table, undefined);
                        if (tableColumn === undefined) return false;
                        return isDateOrDateTimeTypeKind(tableColumn.column.type.kind);
                    },
                }
            )
            .withSwitch("enableTimeFilters", false, {
                displayName: "Show time range selector",
                propertySection: PropertySection.DataTop,
                when: (d, _root, _tables, adc) => {
                    // we only show time series if the main axis is date time.
                    const sc = getSourceColumnProperty(d.label);
                    const table = adc.findTable(
                        getTableProperty((d as unknown as InlineListComponentDescription).propertyName)
                    );
                    if (sc === undefined) return false;
                    const tableColumn = getTableAndColumnForSourceColumn(adc, sc, table, undefined);
                    if (tableColumn === undefined) return false;
                    return isDateOrDateTimeTypeKind(tableColumn.column.type.kind);
                },
            })
            .withEnum<"colorScheme", ChartingColorScheme>(
                "colorScheme",
                [
                    ChartingColorScheme.Default,
                    ChartingColorScheme.Bright,
                    ChartingColorScheme.Muted,
                    ChartingColorScheme.Gradient,
                ],
                {
                    displayName: "Colors",
                    propertySection: PropertySection.Design,
                }
            )
            .withArray("gradientColors", makeGradientStops(), {
                displayName: "Gradient colors", // this doesn't even show.
                addAtBottom: false,
                style: ArrayPropertyStyle.Card,
                propertySection: PropertySection.Design,
                addItemLabels: ["Add color"],
                // green and gold
                defaultValue: [{ color: "#008000" }, { color: "#FFD700" }],
                when: (_, rootDescription) => {
                    return (
                        hasOwnProperty(rootDescription, "colorScheme") &&
                        getEnumProperty<ChartingColorScheme>(rootDescription.colorScheme) ===
                            ChartingColorScheme.Gradient
                    );
                },
            })
            .withEnum<"gradientMapping", ChartingGradientMapping>(
                "gradientMapping",
                [ChartingGradientMapping.Index, ChartingGradientMapping.Data],
                {
                    displayName: "Policy",
                    propertySection: PropertySection.Design,
                    when: d =>
                        getEnumProperty(d.colorScheme) === ChartingColorScheme.Gradient &&
                        (getArrayProperty(d.points) ?? []).length < 2,
                }
            )
            .withSwitch("showYAxisLabels", true, {
                displayName: "Show y labels",
                propertySection: PropertySection.Design,
            })
            .withSwitch("showLegend", true, {
                displayName: "Show legend",
                propertySection: PropertySection.Design,
                when: d => {
                    if (
                        getEnumProperty<ChartingColorScheme>(d.colorScheme) === ChartingColorScheme.Gradient &&
                        getEnumProperty<ChartingGradientMapping>(d.gradientMapping) === ChartingGradientMapping.Data
                    ) {
                        return false;
                    }
                    // don't show this if any of the data points have column
                    // bindings since we cannot guarantee that they have
                    // the same colors per row (dynamic colors)
                    const points = getArrayProperty(d.points);
                    for (const point of points ?? []) {
                        const hasColumnBinding =
                            hasOwnProperty(point, "color") && getSourceColumnProperty(point.color) !== undefined;
                        const isManualMode =
                            hasOwnProperty(point, "colorMode") &&
                            getEnumProperty<DataPlotColorMode>(point.colorMode) === DataPlotColorMode.Manual;
                        if (hasColumnBinding && isManualMode) return false;
                    }
                    return true;
                },
            })
    )
    .withSpecialCases([
        {
            name: "Advanced Chart",
            analyticsName: "pages-data-plot",
            description: "Visualize raw table data",
            img: "st-chart-up",
            group: "Content",
            appKinds: AppKind.Page,
        },
    ])
    .withOnlyQueries()
    .withExperimentFlag("dataPlotComponent");

export type DataPlotComponentDescription = DescriptionOfArrayContentInlineList<typeof DataPlot>;

export const ForEachContainer = arrayContent(ArrayScreenFormat.ForEachContainer)
    .withIsContainer(tables => {
        const titleColumn = findTitleColumnForTable(tables.input, false, undefined);
        if (titleColumn === undefined) return [];
        return [
            {
                ...makeEmptyComponentDescription(WireComponentKind.Text),
                text: makeColumnProperty(titleColumn),
                style: makeEnumProperty(TextComponentStyle.headlineXSmall),
            },
        ];
    })
    .withPaging("default")
    .withDisplayName("Custom Collection")
    .withComponentProperties(
        propertiesWithCSS
            .withEnum(
                "layout",
                [
                    { value: ContainerLayout.Grid, label: "Grid", icon: "mt-component-collection-grid" },
                    { value: ContainerLayout.Full, label: "Full", icon: "mt-container-columns-1" },
                    { value: ContainerLayout.OneToOne, label: "1:1", icon: "mt-container-columns-2" },
                    { value: ContainerLayout.OneToTwo, label: "1:2", icon: "mt-container-columns-1-2" },
                    { value: ContainerLayout.TwoToOne, label: "2:1", icon: "mt-container-columns-2-1" },
                    { value: ContainerLayout.OneToThree, label: "1:3", icon: "mt-container-columns-1-2" },
                    { value: ContainerLayout.ThreeToOne, label: "3:1", icon: "mt-container-columns-2-1" },
                    { value: ContainerLayout.ThreeColumns, label: "3 columns", icon: "mt-container-columns-3" },
                ],
                {
                    propertySection: PropertySection.Layout,
                    enumVisual: "large-images",
                    defaultValue: ContainerLayout.Grid,
                }
            )
            .withArray("titleActions", makeActionWithTitleProperties(), titleActionsOptions)
            .withEnum(
                "titleStyle",
                [
                    {
                        label: "Simple",
                        value: UITitleStyle.Simple,
                    },
                    {
                        label: "Bold",
                        value: UITitleStyle.Bold,
                    },
                ],
                {
                    defaultValue: UITitleStyle.Bold,
                    propertySection: PropertySection.Design,
                    when: (_a, _b, _c, _d, isFirstComponent) => isFirstComponent !== false,
                }
            )
            .withImage("titleImage", {
                useTemplate: "withLabel",
                propertySection: PropertySection.Design,
                when: (c, _d, _e, _f, isFirstComponent) =>
                    isFirstComponent !== false && getEnumProperty(c?.titleStyle) === UITitleStyle.Hero,
            })
            .withText("title", {
                displayName: "Title",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                emptyByDefault: true,
                isComponentTitle: true,
                useTemplate: "withLabel",
            })
            .withEnum<"containerStyle", UIBackgroundStyle>(
                "containerStyle",
                [UIBackgroundStyle.None, UIBackgroundStyle.Card],
                {
                    displayName: "Background",
                    propertySection: PropertySection.Design,
                    defaultValue: UIBackgroundStyle.Card,
                }
            )
            .withEnum(
                "alignment",
                [
                    { value: ContainerAlignment.center, label: "center", icon: "mt-container-align-center" },
                    { value: ContainerAlignment.top, label: "top", icon: "mt-container-align-top" },
                ],
                {
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                    when: d => {
                        const layout = getEnumProperty(d.layout);
                        return (
                            layout !== undefined && layout !== ContainerLayout.Full && layout !== ContainerLayout.Grid
                        );
                    },
                }
            )
            .withEnum(
                "gridSize",
                [
                    { value: UISize.XSmall, label: "XSmall" },
                    { value: UISize.Small, label: "Small" },
                    { value: UISize.Medium, label: "Medium" },
                    { value: UISize.Large, label: "Large" },
                    { value: UISize.XLarge, label: "XLarge" },
                ],
                {
                    defaultValue: UISize.Large,
                    propertySection: PropertySection.Design,
                    when: d => {
                        const layout = getEnumProperty<ContainerLayout>(d.layout);
                        return layout === ContainerLayout.Grid;
                    },
                }
            )
    )
    .withItemProperties(p =>
        p.withAction("action", {
            propertySection: PropertySection.ItemClick,
            defaultAction: true,
            disableForEasyCRUD: true,
        })
    )
    .withSpecialCases([
        {
            name: "Custom",
            analyticsName: "collection-custom",
            description: "Your favorite components, repeated",
            img: "mt-component-custom-collection",
            group: "Collections",
            appKinds: AppKind.Page,
        },
    ]);

/**
 * Radial chart is a component of its own cause it's fundamentally different from Bar/Line chart.
 * Also, its configuration is completely different, so it would be a mess to add it up there
 */
export const RadialChart = arrayContent(ArrayScreenFormat.RadialChart)
    .withPaging("default")
    .withDisplayName("Radial chart")
    .withIcon("mt-pages-donut-chart")
    .withHandlesSorting()
    .withSearchDefault(false)
    .withCanShowIfEmpty(false)
    .withQueryableTables(QueryableTableSupport.LoadAll, false)
    .withComponentProperties(
        propertiesWithCSS
            .withText("title", {
                displayName: "Title",
                useTemplate: "withLabel",
                propertySection: PropertySection.DataTop,
                preferredNames: titleProperties,
                defaultValue: "Chart title",
            })
            .withSwitch("showLegend", true, {
                displayName: "Show legend",
                propertySection: PropertySection.Design,
            })
            .withSwitch("showValuesInLegend", true, {
                displayName: "Show values in legend",
                propertySection: PropertySection.Design,
                when: d => getSwitchProperty(d.showLegend) ?? false,
            })
            .withEnum(
                "lineWeight",
                [
                    { value: RadialChartWeights.Small, label: "Small", icon: "mt-bar-line" },
                    { value: RadialChartWeights.Medium, label: "Medium", icon: "mt-bar-md" },
                    { value: RadialChartWeights.Chonk, label: "Large", icon: "mt-bar-block" },
                ],
                {
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                    displayName: "Line weight",
                }
            )
    )
    .withItemProperties(p =>
        p
            .withText("labels", {
                displayName: "Labels",
                propertySection: PropertySection.Content,
                required: true,
                willBeQueried: true,
            })
            .withText("values", {
                displayName: "Values",
                propertySection: PropertySection.Content,
                emptyByDefault: true,
                allowLiteral: false,
                willBeQueried: true,
            })
            .withEnum<"colorMode", DataPlotColorMode>("colorMode", [DataPlotColorMode.Auto, DataPlotColorMode.Manual], {
                defaultValue: DataPlotColorMode.Auto,
                displayName: "Color mode",
                enumVisual: "text",
                propertySection: PropertySection.Design,
            })
            .withText("color", {
                displayName: "Colors",
                isDefaultCaption: true,
                allowLiteral: false,
                emptyByDefault: false,
                when: d => getEnumProperty(d.colorMode) === DataPlotColorMode.Manual,
                propertySection: PropertySection.Design,
            })
            .withEnum<"colorScheme", ChartingColorScheme>(
                "colorScheme",
                [ChartingColorScheme.Default, ChartingColorScheme.Bright, ChartingColorScheme.Muted],
                {
                    displayName: "Colors",
                    propertySection: PropertySection.Design,
                    when: d => {
                        const colorMode = getEnumProperty(d.colorMode) ?? DataPlotColorMode.Auto;
                        return colorMode === DataPlotColorMode.Auto;
                    },
                }
            )
    )
    .withSpecialCases([
        {
            name: "Radial chart",
            analyticsName: "radial chart",
            description: "Visualize your data",
            img: "mt-pages-donut-chart",
            group: "Content",
            appKinds: AppKind.Page,
        },
    ]);

// Naming this GlideLink cause `Link` is way too generic. I'm open to considering other names.
const GlideLink = component(WireComponentKind.Link)
    .withIcon("co-link")
    .withDisplayName("Link")
    .withDescription("A link that opens when you tap it")
    .withPrompt("Link is for navigation elements (e.g. page links, external URLs, actions)")
    .withProperties(
        propertiesWithCSS
            .withURI("linkTo", {
                displayName: "Link to",
                allowArrays: true,
            })
            .withEnum(
                "style",
                [
                    {
                        value: LinkStyle.Compact,
                        label: "Compact",
                    },
                    {
                        value: LinkStyle.Row,
                        label: "Row",
                    },
                ],
                {
                    propertySection: PropertySection.Design,
                    displayName: "Style",
                    defaultValue: LinkStyle.Compact,
                    enumVisual: "text",
                    description:
                        "Visual style of the link (Compact inline or full Row). Not applicable if link source is an array.",
                    when: (desc, _, tables, adc) => {
                        return !isPropertyBoundToAnArray(desc.linkTo, tables, adc);
                    },
                }
            )

            .withText("title", {
                displayName: "Title",
                useTemplate: "withLabel",
                emptyByDefault: false,
                propertySection: PropertySection.Design,
                description: "The display text for the link (required unless source is an array).",
                when: (desc, _, tables, adc) => {
                    return !isPropertyBoundToAnArray(desc.linkTo, tables, adc);
                },
            })
            .withText("caption", {
                propertySection: PropertySection.Design,
                displayName: "Caption",
                useTemplate: "withLabel",
                description: "Additional text displayed below the title in Row style.",
                when: (d, _, tables, adc) => {
                    const style = getEnumProperty(d.style);
                    const boundToAnArray = isPropertyBoundToAnArray(d.linkTo, tables, adc);

                    return style === LinkStyle.Row && !boundToAnArray;
                },
            })

            .withIcon("icon", {
                propertySection: PropertySection.Design,
                displayName: "Icon",
                defaultIcon: getGlideIcon("st-arrow-up-right"),
                description: "An icon displayed next to the link text.",
            })
    );

export type WireLinkComponent = WirePropertiesOfComponent<typeof GlideLink>;
export type WireLinkDescription = DescriptionOfComponent<typeof GlideLink>;

const ActionRow = component(WireComponentKind.ActionRow)
    .withIcon("co-action-row")
    .withDisplayName("Action Row")
    .withDescription("Combine information and actions")
    .withPrompt(
        "ActionRow combines text, images, and actions into a single interactive row. Use it to display information alongside related actions like copying values, incrementing counters, or downloading files."
    )
    .withProperties(
        propertiesWithCSS
            .withText("text", {
                displayName: "Text",
                useTemplate: "withLabel",
                propertySection: PropertySection.Content,
                isDefaultCaption: true,
                description: "The main text content displayed in the row.",
            })
            .withCaption("label", undefined, {
                displayName: "Label",
                propertySection: PropertySection.Content,
                useTemplate: "withLabel",
                description: "A secondary label, often displayed smaller or above the main text.",
            })
            .withImage("image", {
                displayName: "Image",
                useTemplate: "withLabel",
                propertySection: PropertySection.Content,
                emptyByDefault: true,
                description: "An optional image displayed on the left side of the row.",
            })
            .withEnum("textStyle", ["Title", "Field"] as const, {
                defaultValue: "Field",
                displayName: "Text Style",
                enumVisual: "text",
                description:
                    "Controls the visual prominence of the main text ('Title' is larger, 'Field' is standard).",
            })
            .withEnum(
                "imageStyle",
                [
                    { value: UIImageStyle.Rectilinear, label: "Square" },
                    { value: UIImageStyle.Circle, label: "Circle" },
                ],
                {
                    defaultValue: UIImageStyle.Rectilinear,
                    displayName: "Image Style",
                    enumVisual: "text",
                    description: "Sets the shape of the optional image ('Square' or 'Circle').",
                }
            )
            .withArray("actions", makeActionWithTitleProperties(), {
                propertySection: PropertySection.Action,
                style: ArrayPropertyStyle.ActionArray,
                removeIfMissing: removeIfMissingInActionWithTitle,
                defaultValue: [
                    {
                        title: makeStringProperty(getLocalizedString("copyGeneral", AppKind.Page)),
                        action: makeActionProperty({
                            kind: ActionKind.CopyToClipboardWithArgs,
                        } as ActionDescription),
                        icon: makeIconProperty(getGlideIcon("st-copy")),
                    },
                ] as unknown as readonly Description[],
                description: "An array of action buttons displayed on the right side of the row.",
            })
    )
    .withSpecialCases(true, [
        [
            {
                name: "Contact",
                description: "Show and act to contact information",
                img: "co-contact",
                group: "Actions",
                appKinds: AppKind.Page,
            },
            desc => {
                return {
                    ...desc,
                    builderDisplayName: "Contact",
                    imageStyle: makeEnumProperty(UIImageStyle.Circle),
                    textStyle: makeEnumProperty("Title"),
                    actions: makeArrayProperty([
                        {
                            title: makeStringProperty("Call"),
                            action: makeActionProperty({
                                kind: ActionKind.PhoneCallWithArgs,
                            }),
                            icon: makeIconProperty(getGlideIcon("st-telephone")),
                        },
                        {
                            title: makeStringProperty("SMS"),
                            action: makeActionProperty({
                                kind: ActionKind.TextMessageWithArgs,
                            }),
                            icon: makeIconProperty(getGlideIcon("st-message-circle")),
                        },
                        {
                            title: makeStringProperty("Email"),
                            action: makeActionProperty({
                                kind: ActionKind.SendEmailWithArgs,
                            }),
                            icon: makeIconProperty(getGlideIcon("st-mail")),
                        },
                    ]),
                };
            },
        ],
    ]);

export type WireActionRowComponent = WirePropertiesOfComponent<typeof ActionRow>;
export type WireActionRowDescription = DescriptionOfComponent<typeof ActionRow>;

const PageRating = component(WireComponentKind.PageRating)
    .withIcon("co-rating")
    .withDisplayName("Rating")
    .withDescription("set and display rating")
    .withPrompt(
        "Rating is for collecting feedback (e.g. stars, scores, reviews). It's useful for surveys, feedback, and rating systems."
    )
    .withProperties(
        propertiesWithCSS
            .withText("text", {
                displayName: "Text",
                useTemplate: "withLabel",
                propertySection: PropertySection.Content,
                description: "Optional main text displayed alongside the rating stars.",
            })
            .withText("label", {
                displayName: "Label",
                useTemplate: "withLabel",
                propertySection: PropertySection.Content,
                description: "Optional secondary label text.",
            })
            .withImage("image", {
                displayName: "Image",
                useTemplate: "withLabel",
                propertySection: PropertySection.Content,
                emptyByDefault: true,
                description: "Optional image displayed with the rating.",
            })
            .withEditableValue(
                "rate",
                "number",
                {
                    displayName: "Save to",
                    propertySection: PropertySection.Data,
                    emptyByDefault: false,
                    description: "The column where the user's selected rating value (number) will be saved.",
                },
                { isDisplayed: false }
            )
            .withEnum("maxRating", ["3", "4", "5"] as const, {
                defaultValue: "5",
                displayName: "Max Rating",
                enumVisual: "text",
                description: "The maximum number of stars available for rating (3, 4, or 5).",
            })
    );

export type WireRatingComponent = WirePropertiesOfComponent<typeof PageRating>;
export type WireRatingDescription = DescriptionOfComponent<typeof PageRating>;

export const PageProgress = component(WireComponentKind.PageProgress)
    .withIcon("co-progress-bar")
    .withDisplayName("Progress")
    .withDescription("A bar showing progress")
    .withPrompt("Progress is for showing completion status (e.g. task completion, project milestones, goal tracking)")
    .withProperties(
        propertiesWithCSS
            .withFormattedNumber("value", {
                isDefaultCaption: true,
                description: "The current value indicating the progress level.",
            })
            .withFormattedNumber("min", {
                defaultValue: 0,
                description: "The minimum value representing the start of the progress range.",
            })
            .withFormattedNumber("max", {
                defaultValue: 100,
                description: "The maximum value representing the completion of the progress range.",
            })
            .withCaption("title", undefined, {
                propertySection: PropertySection.Design,
                useTemplate: "withLabel",
                description: "Optional title text displayed above the progress bar.",
            })
            .withText("description", {
                propertySection: PropertySection.Design,
                emptyByDefault: true,
                useTemplate: "withLabel",
                description: "Optional description text displayed below the progress bar.",
            })
            .withText("color", {
                propertySection: PropertySection.Design,
                emptyByDefault: true,
                helpText:
                    "Use a single color or a comma separated list if you want it to be based on value. [Read more](https://www.glideapps.com/docs/essentials/components/progress-bar).",
                placeholder: "green or #00FF00",
            })
            .withSwitch("hideValue", false, {
                displayName: "Hide value",
                propertySection: PropertySection.Design,
                description: "Hides the value displayed on the progress bar.",
            })
            .withText("formattedValue", {
                displayName: "Formatted value",
                propertySection: PropertySection.Design,
                emptyByDefault: true,
                useTemplate: "withLabel",
                description: "Optional formatted value text displayed instead of the numeric value.",
                when: d => getSwitchProperty(d.hideValue) === false,
                placeholder: "ARR $1M/$10M",
            })
    );

export type WirePageProgressComponent = WirePropertiesOfComponent<typeof PageProgress>;
export type WirePageProgressDescription = DescriptionOfComponent<typeof PageProgress>;

const PageAudio = component(WireComponentKind.PageAudio)
    .withIcon("co-audio")
    .withDisplayName("Audio")
    .withDescription("play an audio file from a link")
    .withPrompt("Audio is for playing sound content (e.g. music, voice recordings, etc.)")
    .withProperties(
        propertiesWithCSS.withURI("audio", {
            propertySection: PropertySection.Data,
            description: "The source column containing the URL of the audio file to be played.",
        })
    );

export type WireAudioComponent = WirePropertiesOfComponent<typeof PageAudio>;
export type WireAudioDescription = DescriptionOfComponent<typeof PageAudio>;

const PageAudioRecorder = component(WireComponentKind.PageAudioRecorder)
    .withIcon("co-audio")
    .withDisplayName("Audio Recorder")
    .withDescription("record an audio file to a link")
    .withPrompt("AudioRecorder is for capturing audio (e.g. voice notes, recordings, messages)")
    .withProperties(
        propertiesWithCSS
            .withEditableValue(
                "saveTo",
                "string",
                {
                    displayName: "Save To",
                    propertySection: PropertySection.Data,
                    preferredNames: audioProperties,
                    description: "The column where the URL of the recorded audio file will be saved.",
                },
                {}
            )

            .withText("title", {
                propertySection: PropertySection.Data,
                defaultValue: "New Recording",
                useTemplate: "withLabel",
                description: "Default title/filename suggestion for the new recording before saving.",
            })
            .withText("recordText", {
                propertySection: PropertySection.Design,
                defaultValue: "Record",
                useTemplate: "withLabel",
                description: "The text label displayed on the record button.",
            })
            .withText("uploadingText", {
                propertySection: PropertySection.Design,
                defaultValue: "Saving",
                useTemplate: "withLabel",
                description: "The text displayed while the recording is being saved/uploaded.",
            })
            .withAction("onRecordingComplete", {
                propertySection: PropertySection.Action,
                defaultAction: () => ({ kind: ActionKind.ShowToast }),
                description: "Action executed after the audio recording is successfully saved.",
            })
    );

export type WireAudioRecorderComponent = WirePropertiesOfComponent<typeof PageAudioRecorder>;
export type WireAudioRecorderDescription = DescriptionOfComponent<typeof PageAudioRecorder>;

const RenderableContentComponent = component(WireComponentKind.RenderableContent)
    .withDisplayName("Renderable")
    .withIcon("mt-component-custom")
    .withDescription("Render Glide components from structured data")
    .withProperties(
        properties.withText("descriptions", {
            useTemplate: "withLabel",
        })
    )

    .withExperimentFlag("renderableContentComponent");

export type WireRenderableContentComponent = WirePropertiesOfComponent<typeof RenderableContentComponent>;
export type WireRenderableContentDescription = DescriptionOfComponent<typeof RenderableContentComponent>;

export const CommentsCollection = arrayContent(ArrayScreenFormat.Comments)
    .withIcon("co-comments")
    .withDisplayName("Comments")
    .withSearchDefault(false)
    .withComponentProperties(
        propertiesWithCSS
            .withText("title", {
                propertySection: PropertySection.DataTop,
                defaultValue: "Comments",
                useTemplate: "withLabel",
            })
            .withEnum(
                "componentStyle",
                [
                    {
                        value: CommentsStyle.Comments,
                        icon: "mt-comments-default",
                        label: "Comments",
                    },
                    {
                        value: CommentsStyle.Chat,
                        icon: "mt-comments-chat",
                        label: "Chat",
                    },
                ],
                {
                    defaultValue: CommentsStyle.Comments,
                    enumVisual: "large-images",
                    displayName: "Style",
                    propertySection: PropertySection.Style,
                }
            )
            .withFormattedNumber("pageSize", {
                displayName: "Page size",
                defaultValue: 16,
                propertySection: PropertySection.Options,
            })
            .withText("emptyMessage", {
                displayName: "Empty screen",
                propertySection: PropertySection.Options,
                defaultValue: "Nothing yet.",
                useTemplate: "withLabel",
            })
            .withText("buttonText", {
                displayName: "Button text",
                propertySection: PropertySection.Options,
                placeholder: "Comment",
                emptyByDefault: true,
                useTemplate: "withLabel",
                when: d => getEnumProperty(d.componentStyle) === CommentsStyle.Comments,
            })
            .withSwitch("allowAdd", true, {
                displayName: "Allow adding comments",
                propertySection: PropertySection.InlineActions,
                withCondition: true,
                icon: "st-plus-stroke",
            })
    )
    .withItemProperties(p =>
        p
            .withText("saveComment", {
                displayName: "Save Text",
                propertySection: PropertySection.DataTop,
                isAddedInApp: true,
                allowLiteral: false,
                allowUserProfileColumns: false,
                required: true,
                when: (_desc, rootDesc) => getSwitchProperty(rootDesc.allowAdd) === true,
            })
            .withText("saveComment", {
                displayName: "Save Text",
                propertySection: PropertySection.DataTop,
                isAddedInApp: false,
                allowLiteral: false,
                allowUserProfileColumns: false,
                required: true,
                when: (_desc, rootDesc) => getSwitchProperty(rootDesc.allowAdd) === false,
            })
            .withText("comment", {
                propertySection: PropertySection.Display,
                allowLiteral: false,
                allowUserProfileColumns: false,
                required: true,
                displayName: "Text",
                useTemplate: "withLabel",
            })
            .withDateTime("timestamp", {
                propertySection: PropertySection.Display,
                allowLiteral: false,
                allowUserProfileColumns: false,
                required: true,
            })
            .withImage("userPhoto", {
                displayName: "Author photo",
                propertySection: PropertySection.Display,
                allowLiteral: false,
                allowUserProfileColumns: false,
                required: true,
            })
            .withText("userName", {
                displayName: "Author name",
                propertySection: PropertySection.Display,
                allowLiteral: false,
                allowUserProfileColumns: false,
                required: true,
            })
            .withText("userEmail", {
                displayName: "Author email",
                propertySection: PropertySection.Display,
                allowLiteral: false,
                allowUserProfileColumns: false,
                required: false,
                emptyWarningText: "Bind the author email to distinguish message bubbles",
                when: (_d, desc) => getEnumProperty(desc.componentStyle) === CommentsStyle.Chat,
            })
            .withArray("itemActions", makeActionWithTitleProperties(), {
                ...itemActionsOptions,
                disableForEasyCRUD: true,
            })
    )
    .withColumnAssignments(["saveComment"])
    .withSpecialCases([
        {
            name: "Comments",
            analyticsName: "collection-comments",
            description: "Add comments to your app.",
            img: "co-comments",
            group: "Collections",
            appKinds: AppKind.Page,
            getIsCurrent: d => getEnumProperty((d as any).componentStyle) === CommentsStyle.Comments,
            setAsCurrent: desc => ({
                ...desc,
                title: makeStringProperty("Comments"),
                componentStyle: makeEnumProperty(CommentsStyle.Comments),
            }),
        },
        {
            name: "Chat",
            analyticsName: "collection-chat",
            description: "Add chat to your app.",
            img: "co-comments",
            group: "Collections",
            appKinds: AppKind.Page,
            getIsCurrent: d => getEnumProperty((d as any).componentStyle) === CommentsStyle.Chat,
            setAsCurrent: desc => ({
                ...desc,
                title: makeStringProperty("Chat"),
                componentStyle: makeEnumProperty(CommentsStyle.Chat),
            }),
        },
    ]);
export type WireCommentsDescription = DescriptionOfComponent<typeof CommentsCollection>;

const Stopwatch = component(WireComponentKind.Stopwatch)
    .withIcon("co-stopwatch")
    .withDisplayName("Stopwatch")
    .withDescription("Start and record times")
    .withShowRegardlessOfEmptyProperties(true)
    .withProperties(
        propertiesWithCSS
            .withSwitch("showReset", true, {
                displayName: "Show Reset Button",
                propertySection: PropertySection.Options,
            })
            .withEditableValue(
                "elapsedTime",
                "number",
                {
                    displayName: "Elapsed Time (Seconds)",
                    propertySection: PropertySection.Data,
                    emptyByDefault: true,
                },
                { isDisplayed: true }
            )
    )
    .withInternalState("internalLogs", {
        validator: isString,
        defaultValue: "",
        persist: true,
    })
    .withInternalState("isRunning", {
        validator: isBoolean,
        defaultValue: false,
        persist: true,
    })
    .withExperimentFlag("stopwatchComponent");

export type WireStopwatch = WirePropertiesOfComponent<typeof Stopwatch>;

// `group` helps us visually create named component groups in the definition
// of `components` below.
function group<T>(groupName: string, components: T): T {
    const withinGroup: any = { ...components };
    for (const [k, v] of Object.entries(withinGroup)) {
        withinGroup[k] = (v as any).withinGroup(groupName);
    }
    return withinGroup;
}

// The order of these components are rendered in the app is determined
// by `groupPositions` in `create-add-component-pad-menu.ts` in the app.
export const components = {
    ...group("AI", {
        AICustomChatComponent,
    }),
    ...group("Content", {
        Title,
        // Collection, // from Figma mockup
        // Table, // from Figma mockup
        Fields,
        Location,
        Image,
        SimpleImage,
        Video,
        SimpleVideo,
        BigNumbers,
        // Map, // from Figma mockup,
        Webview,
        PageProgress,
        PageAudio,
        PageAudioRecorder,
    }),
    ...group("Layout", {
        Container,
        // Columns, // from Figma mockup
        Separator,
        InternalSeparator, // this is marked as legacy, so it won't show up
    }),
    ...group("Text", {
        Text,
        Note,
        RichText,
        Hint,
    }),
    ...group("Actions", {
        ButtonsBlock,
        Buttons,
        // Chips, // from Figma mockup
        Button, // from Figma mockup
        GlideLink,
        ActionRow,
        PageRating,
    }),
    ...group("Forms", {
        ContactForm,
        FormContainer,
    }),
    ...group("Advanced", {
        Breadcrumb: Breadcrumbs,
        Webview,
        InlineScanner,
        SignatureField,
        Stopwatch,
        Spinner,
    }),
    ...group("Custom", {
        AICustomComponent,
        RenderableContentComponent,
    }),
};
