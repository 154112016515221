import type { ColumnType, PrimitiveGlideTypeKind, TableColumn } from "./description";
import { makePrimitiveType } from "./description";

export function makeTableColumn(
    name: string,
    type: ColumnType | PrimitiveGlideTypeKind,
    rest?: Partial<Omit<TableColumn, "name" | "type">>
): TableColumn {
    if (typeof type === "string") {
        type = makePrimitiveType(type);
    }
    return { ...rest, name, type } as TableColumn;
}
